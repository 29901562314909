import { db } from './Database';

import { parse } from '../pages/Main/Captures/Parser';

import Excel from 'exceljs/modern.browser';

import Papa from 'papaparse';

import Logger from './Logger';


const axios = require('axios');

const moment = require('moment');

const http = axios.create({
	//baseURL: 'http://192.168.15.19'
	baseURL: 'https://hanalytics.dcod.com.br'
	//baseURL: 'https://analytics.dcod.com.br'
});



const log = Logger('Captures');

function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

let monitorTableSize = (callback) => {
	log.i('TARTING TO MONITO METADATA/COUNTERS')
	callback(0);
	return () => {}
	return db.firestore()
		.collection('metadata')
//		.doc('couters')
		.onSnapshot(snap => {

			log.i('GIT A NEW SNAPSHOT', snap);
			log.i('snap.docs', snap.docs);
			snap.docs.forEach(d => {
				log.i('forEach d', d);
				log.i('forEach d.data()', d.data());

				log.i('forEach d.data().id', d.data().id);
			})
//			callback({captures: 15});
			let counterRef = snap.docs.find(d => d.id === 'counters');
			let counterData = counterRef && counterRef.data();
			let value = counterData || {'captures': 0};
			callback(value);
		});
}

let get = (id, callback) => {

//	console.log('Loading captures with params', params);
	http.get(`/cccaptures/${id}`, { withCredentials: true })
		.then(response => {
			console.log('got', response.data);
			callback(convertAnalyticsToFirebase(response.data))
		})
		.catch(err => Promise.reject(err));


	return () => {}

	return db.firestore().collection("captures").doc(id)
		.onSnapshot(docSnap => {
			let docData = docSnap.data();
			log.i('Got data', docData);
			callback(docData);
		});
}

let mapFirebaseToLocal2 = (doc) => {
	let { databaseId, captureTimestamp, captureAutomatedValue, ...rest } = doc.data();
	return { id: databaseId || doc.id, timestamp: captureTimestamp, value: captureAutomatedValue, ...rest};
}

let getSomeCaptures = () => {
	return db.firestore().collection("captures")
		.orderBy("captureTimestamp", "desc")
		.limit(12)
		.get().then(snapshot => {
			log.i(`Loaded ${snapshot.size} entries`);
			let docs = snapshot.docs || [];
			log.d('Loaded from database', docs.map(d => d.data()));
			let data = docs.map(mapFirebaseToLocal2);
			return Promise.resolve({data, total: 24})
		});
	
}

/*
function convertCaptureToReportRow(data) {
	let itemId = data.id;
	let rowData = data.data();

	log.i('Loaded id', itemId, 'with data', rowData);
	let { routeItem, } = rowData;
	let { status, captureTimestamp, installationId, value, captureUrl } = rowData;

	if(!!routeItem) {
		installationId = routeItem.installationId;
	}

	let statusMessage = parse(status, value);

  captureTimestamp = new Date(captureTimestamp).toLocaleString();
  captureUrl = (captureUrl ? 'https://cloud.dcod.com.br/captures/' + itemId : 'envio pendente');

	let itemValues = [itemId, captureTimestamp, installationId, statusMessage, captureUrl];
	return itemValues.join(';');
}
*/

function saveTextToFile(text) {
    var d = new Date(); // for now

    let fileName = 'decode_' + d.getFullYear() + pad(d.getMonth() + 1, 2) + pad(d.getDate(), 2)
                    + '_' + pad(d.getHours(), 2) + pad(d.getMinutes(), 2) + pad(d.getSeconds(), 2)
                    + '.csv';
        
    log.d(fileName);

		var downloadLink = document.createElement("a");
		var blob = new Blob([text]);
		var url = URL.createObjectURL(blob);



		downloadLink.href = url;
		downloadLink.download = fileName;
		
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);

		/*
    // return;
    var hiddenElement = document.createElement('a');

    hiddenElement.href = 'data:text/csv,' + encodeURI(text);
    hiddenElement.target = '_blank';
    hiddenElement.download = fileName;
    hiddenElement.click();
		*/
	
}

function getFileName() {
    var d = new Date(); // for now

    return 'decode_' + d.getFullYear() + pad(d.getMonth() + 1, 2) + pad(d.getDate(), 2)
                    + '_' + pad(d.getHours(), 2) + pad(d.getMinutes(), 2) + pad(d.getSeconds(), 2);
}

const exportCaptures = ({ minDate, maxDate, filter, branchId, groupId, routeId }) => {
	return new Promise((resolve, reject) => {





	const params = { branchId, limit: 99999, offset: 0 };

	if(!!groupId) {
		params.groupId = groupId;
	}

	if(!!routeId) {
		params.routeId = routeId;
	}


	if(!!minDate) {
		log.i('Adding minDate with', minDate.getTime());
		//query = query.where('captureTimestamp', '>=', minDate.getTime());
		params.minDate = minDate.getTime();
	}

	if(!!maxDate) {
		log.i('Adding maxDate with', maxDate.getTime());
		//query = query.where('captureTimestamp', '<=', maxDate.getTime());
		params.maxDate = maxDate.getTime();
	}

	if(!!filter) {
		log.i('Adding filters', filter);
		if(filter && filter.searchKey && filter.searchValue) {
		//	query = query.where(filter.searchKey, '==', filter.searchValue);
			params[filter.searchKey] = filter.searchValue;
		}
	}



	//log.i("Loading from", startIndex, "to", endIndex);

//	const params = { branchId, limit: rowsPerPage, offset: startIndex };

	console.log('Loading captures to export. Using params', params);
	http.get('/cccaptures', { params, withCredentials: true })
		.then(response => {
			console.log('got', response.data);
//			callback({data: response.data.captures.map(convertAnalyticsToFirebase), count: response.data.total})


			const docs = response.data.captures.map(convertAnalyticsToFirebase);

			console.log('Loaded docs', docs);


/*
	  db.firestore().collection("captures")
			.orderBy("captureTimestamp", "asc")
			.get().then(snap => {
		
				log.i('Loaded', snap.size, 'captures');
				*/
		
//    		let header = 'ID; Data de captura; Cliente; Status; URL';
		    //let content = snap.docs.map(data => {
		    let content = docs.map(data => {
					//convertCaptureToReportRow

					//let itemId = data.id;
					//let rowData = data.data();
					let { id: itemId, ...rowData } = data;
				
					log.i('Loaded id', itemId, 'with data', rowData);
					let { routeItem, } = rowData;
					let { status, captureTimestamp, value, captureUrl, comment } = rowData;
					let { branchId, groupId, routeId, sequenceId, installationId, meterId } = rowData

					if(value === -1 || value === '-1') {
						value = undefined;
					}

					let statusMessage = parse(status);
				
				  let date = new Date(captureTimestamp);
					var hours = date.getHours();
					var minutes = date.getMinutes();
					var seconds = date.getSeconds();
				
				  var day = date.getDate();
				  var month = date.getMonth() + 1;
				  var year = date.getFullYear();
				
				  var dateString = year + '/' + ("0" + month).substr(-2) + '/' + ("0" + day).substr(-2);
					var timeString = ("0" + hours).substr(-2) + ":" + ("0" + minutes).substr(-2) + ":" + ("0" + seconds).substr(-2);
				  captureUrl = (captureUrl ? 'https://cloud.dcod.com.br/captures/' + itemId : 'envio pendente');
				
					return {
						'ID': itemId,
						'Data': dateString,
						'Hora': timeString,
					//	'Domínio': branchId,
						'Grupo': groupId,
						'Rota': routeId,
						'Sequência': sequenceId,
						'Instalação': installationId,
						'Hidrômetro': meterId,
						'Situação': statusMessage,
						'Valor': value,
						'Comentário': comment,
						'URL': captureUrl,
					};

					//let itemValues = [itemId, captureTimestamp, installationId, statusMessage, captureUrl];
				});

				let headers = [
						'ID',
						'Data',
						'Hora',
						'Grupo',
						'Rota',
						'Sequência',
						'Instalação',
						'Hidrômetro',
						'Situação',
						'Valor',
						'Comentário',
						'URL',
					];
				//let text = [header, ...content].join('\n');

try {

        var workbook = new Excel.Workbook();
        var worksheet = workbook.addWorksheet('Capturas', {
					views: [
						{state: 'frozen', ySplit: 1}
					]
				});

        worksheet.columns = headers.map(h => ({ header: h, key: h, width: 16}));
        /*worksheet.columns = [
         { header: 'ABC', key: 'a', width: 15 },
         { header: 'Description', key: 'b', width: 20 },
         { header: 'Comments', key: 'c', width: 20 }
        ];*/
        // setStyleToHeader(worksheet);

				content.forEach((c, i) => {
					let newRow = {};
					headers.forEach(h => {
						newRow[h] = c[h];
					});
					log.i('Adding row', newRow);
					worksheet.addRow(newRow);
					worksheet.getRow(i+1).font = { bold: false };
				});

        worksheet.getRow(1).font = { name: 'Calibri', family: 4, size: 12, bold: true };
        worksheet.getRow(1).border = {
            bottom: { style: 'thin' },
        };

				log.i('Styles set');
						/*
            let rows = data;
            for (var i = 0; i < rows.length; i++) {
								log.i('Adding row', rows[i], 'to sheet');
                worksheet.addRow({
                    a: rows[i].a,
                    b: rows[i].b ,
                    c: rows[i].c
                });
            }
						*/


						log.i('Data set');
						var res;
            workbook.xlsx.writeBuffer( {
                base64: true
            })
            .then( function (xls64) {
                // build anchor tag and attach file (works in chrome)
                var a = document.createElement("a");
                var data = new Blob([xls64], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

                var url = URL.createObjectURL(data);
                a.href = url;
                a.download = getFileName() + '.xlsx';
                document.body.appendChild(a);
                a.click();
                setTimeout(function() {
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);
                }, 0);
            })
            .catch(function(error) {
                log.i(error.message);
            });

						log.i('Wrote to res');

						log.i('res', res)
						log.i('workbook', workbook)
        //    res.setHeader('Content-Type', 'application/vnd.openxmlformats');
        //    res.setHeader("Content-Disposition", "attachment; filename=" + "ABC.xlsx");
        //    res.end(result, 'binary');
    } catch (e) {

			log.i(e)
    }

resolve();
return;

				let text = ['sep=,', headers.join(','), ...content.map(c => headers.map(h => c[h]).join(',') )].join('\n');
//				let text = Papa.unparse(content)
				log.i('Saving text', text);

				saveTextToFile(text);
				resolve();
		
			})


		})
		.catch(err => Promise.reject(err));


//		});
}

let pageLimitators = []


let convertAnalyticsToFirebase = (aC) => {
	return {
		captureId: aC.id,
		captureUrl: aC.captureurl,
		thumbnailUrl: aC.thumbnailurl,
		branchId: aC.branchid,
		timestamp: +aC.capturetimestamp,
		captureTimestamp: +aC.capturetimestamp,
		meterId: aC.meterid,

		groupId: aC.groupid,
		routeId: aC.routeid,
		sequenceId: aC.sequenceid,
		installationId: aC.installationid,

		value: aC.valuestring,
		
		...aC
	}
}


let getRoutesInfo = async(branchId, groupId) => {
	
	return http.get('/info', { params: { branchId, groupId }, withCredentials: true })
		.then(response => {
			console.log('got response', response.data);

			let data;
			if(!groupId) {
				data = response.data.map(a => a.groupid);
			} else {
				data = response.data.map(a => a.routeid);
			}

			console.log('got Mapped into', data);
/*
			data = data.sort(
				function (a, b){
				    var _a = parseInt(a), // If the values are integers only, parseInt will do too
				        _b = parseInt(b);
				    if (_a - _b === 0) {
				    	return (a > b) ? 1 : -1;
				    } else {
				    	return a - b;
				    }
				}
			)
			console.log('got sorted into', data);
			*/

			return data;
		})
		.catch(err => Promise.reject(err));

}


let monitorPageData = async ({pageIndex = 0, rowsPerPage = 12, minDate, maxDate, filter, branchId, groupId, routeId}, callback) => {

	var pageData = [];

	var startIndex = pageIndex * rowsPerPage;
	var endIndex = (pageIndex + 1) * rowsPerPage;

	// rowsPerPage = 1;

	log.i("Loading from", startIndex, "to", endIndex);

	const params = { branchId, limit: rowsPerPage, offset: startIndex };

	if(!!groupId) {
		params.groupId = groupId;
	}

	if(!!routeId) {
		params.routeId = routeId;
	}


	if(!!minDate) {
		log.i('Adding minDate with', minDate.getTime());
		//query = query.where('captureTimestamp', '>=', minDate.getTime());
		params.minDate = minDate.getTime();
	}

	if(!!maxDate) {
		log.i('Adding maxDate with', maxDate.getTime());
		//query = query.where('captureTimestamp', '<=', maxDate.getTime());
		params.maxDate = maxDate.getTime();
	}

	if(!!filter) {
		log.i('Adding filters', filter);
		if(filter && filter.searchKey && filter.searchValue) {
		//	query = query.where(filter.searchKey, '==', filter.searchValue);
			params[filter.searchKey] = filter.searchValue;
		}
	}


	console.log('Loading captures with params', params);
	http.get('/cccaptures', { params, withCredentials: true })
		.then(response => {
			console.log('got', response.data);
			callback({data: response.data.captures.map(convertAnalyticsToFirebase), count: response.data.total})
		})
		.catch(err => Promise.reject(err));


	return () => {}

/*



	var query;
	if(pageIndex === 0) {
		pageLimitators = [];
		query = db.firestore().collection("captures")
					.orderBy("captureTimestamp", "desc")
					.limit(rowsPerPage)
		
	} else {
		log.i("Moving to previous page")
		query = db.firestore().collection("captures")
					.orderBy("captureTimestamp", "desc")
					.startAfter(pageLimitators[pageIndex - 1])
					.limit(rowsPerPage)
		log.i("Getting items up to", pageLimitators[pageIndex - 1].data().databaseId)
	}

	if(!!minDate) {
		log.i('Adding minDate with', minDate.getTime());
		query = query.where('captureTimestamp', '>=', minDate.getTime());
	}

	if(!!maxDate) {
		log.i('Adding maxDate with', maxDate.getTime());
		query = query.where('captureTimestamp', '<=', maxDate.getTime());
	}

	if(!!filter) {
		log.i('Adding filters', filter);
		if(filter && filter.searchKey && filter.searchValue) {
			query = query.where(filter.searchKey, '==', filter.searchValue);
		}
	}

	return query
	.onSnapshot((querySnapshot) => {

		log.i('LOADEIDJED', querySnapshot.size, 'entries');
		var docs = querySnapshot.docs

		pageLimitators.push(docs[docs.length - 1]);
		pageData = docs.map(mapFirebaseToLocal2)
	log.i("Retrieved", pageData.length, "items");
	log.i("Finished loading here");

	callback({data: pageData});

})
*/
}

export {
	exportCaptures,
	get,
	monitorPageData,
	monitorTableSize,
	getRoutesInfo,
}
