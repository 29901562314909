const axios = require('axios');

const moment = require('moment');

const http = axios.create({
//	baseURL: 'http://192.168.15.19:3001'
	baseURL: 'https://auth.dcod.com.br'
});

const Logger = require('./Logger').default;
const log = Logger('Auth');

/*
// Alter defaults after instance has been created
http.defaults.headers.common['resourceId'] = '0AUykpuj6xEncneZJLSs';
http.defaults.headers.common['clientId'] = 'u4xjBF9iDiNj1bkjj38wsGmeiP03';
http.defaults.headers.common['resourceVersion'] = '1.8';
*/

//import { getDomainData, getExistingDomainConfigs } from './Domain';

//import { initialize as init, db } from './Database';
//import { getErrorMessage } from './Messages';


let onMockUserChange = (callback) => {
		onUserChangeCallback = callback;
		const mockUser = {
			photoURL: "https://www.w3schools.com/howto/img_avatar.png",
			branches: [ 'mirante', 'serra' ],//sessionData.branches,//,JSON.parse(sessionData.branches),
			username: 'mockboy',
			email: 'mock@boi.com',
			//...sessionData
		};
		setTimeout(() => callback(mockUser), 200);
}

let onUserChangeCallback = () => log.i('onUserChangeCallback not set');
let onUserChange = async (callback) => {
	log.i('Setting onUserChangeCallback');
	onUserChangeCallback = callback;

	
	console.log('Loading user data');
	const response = await http.get('/check', {withCredentials: true})

	const data = response.data || {};
	log.i('Got user data:', data, '.');

	const { sessionData } = data;

	if(sessionData.status === 'ok') {
		log.i('Session is OK. User data is', sessionData);
		onUserChangeCallback({
			photoURL: "https://www.w3schools.com/howto/img_avatar.png",
			branches: sessionData.branches,//,JSON.parse(sessionData.branches),
			username: sessionData.username,
			email: sessionData.email,
			//...sessionData
		});
	} else {
		log.i('Invalid user with status', sessionData.status);
		onUserChangeCallback();
	}

/*
	//let sConfs = window.localStorage.getItem(DOMAIN_KEY);
	let sConfs = getExistingDomainConfigs();
	if(sConfs) {
		log.i('Trying to initialize with saved confs');
		init(sConfs).then(db => {
			log.i('Got a new db', db);
//			init2go(db);
			setUserListener(db);
		});	
	} else {
		log.i('No confs found. User must logini from cold configs');
		onUserChangeCallback();
	}
*/
}

let logout = () => {
	log.i('Logging out');
	window.location = 'https://auth.dcod.com.br/logout';
	//onUserChangeCallback();
}

/*
let onAuthChangeCallback = () => log.i('onAuthChangeCallback not set');
let onAuthChange = (callback) => {
	log.i('Setting onAuthChangeCallback');
	onAuthChangeCallback = callback;

	//let sConfs = window.localStorage.getItem(DOMAIN_KEY);
	let sConfs = getExistingDomainConfigs();
	if(sConfs) {
		log.i('Trying to initialize with saved confs');
		init(sConfs).then(db => {
			log.i('Got a new db', db);
//			init2go(db);
			setAuthListener(db);
		});	
	} else {
		log.i('No confs found. User must logini from cold configs');
		onAuthChangeCallback();
	}
}

let setAuthListener = (db) => {
	db.auth().onAuthStateChanged((firebaseUser) => {
		onAuthChangeCallback(parseUserData(firebaseUser));
	});
}

let parseUserData = (userData) => {
	let parsedData;
	if(userData) {
		let { uid, displayName, email, phoneNumber, photoURL } = userData;
		displayName = displayName || "Teste";
		photoURL = photoURL || "https://www.w3schools.com/howto/img_avatar.png";
		parsedData = {id: uid, name: displayName, email, phoneNumber, photoURL};
		if(!!email) {
			let splitEmail = email.split('@');
			if(splitEmail.length === 2) {
				parsedData.domain = splitEmail[1];
				let splitDomain = splitEmail[1].split('.');
				if(splitDomain.length === 2) {
					parsedData.branch = splitDomain[0];
					parsedData.branchId = splitDomain[0];
				}
			}
		}
	}
	log.i('User data is:', parsedData);
	return parsedData;
}

let login = ({email, password}) => {
	log.i(`Trying to login with e-mail ${email}`);
	return getDomainData(email).then(domainData => {
		log.i('Loaded domain data', domainData, 'for email', email);
		return init(domainData);
	}).then((db) => {
		setAuthListener(db);
		log.i('Now trying to sign in');
		return db.auth().signInWithEmailAndPassword(email, password).then((a) => {
			log.i('Signed in!', a);
		})
	}).catch(e => {
		log.e(e);
		return Promise.reject(getErrorMessage(e.code));
	});
}

let logout = () => {
	return db.auth().signOut().then(() => {
		log.i('Signed OUT!');
	});
}

let resetPasswordForEmail = (email) => {
  return db.auth().sendPasswordResetEmail(email);
}
*/
export {
//	login,
//	logout,
//	resetPasswordForEmail,
//	changeUserPassword,
//	onAuthChange,
	onUserChange,
	logout,
	onMockUserChange,
}

