import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';

import { Link } from 'react-router-dom';

import { useTheme } from '@material-ui/styles';

import { Select, MenuItem } from '@material-ui/core';

import Progress from 'components/ProgressSpinner';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

//import { data, options } from './chart';

import {
  getExistingBranches,
  getBranchCapturesByDate,
	getDeviceCapturesHistogram,
} from 'modules/Analytics';

import moment from 'moment';

const getColor = (step, numOfSteps) => {
    // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
    // Adam Cole, 2011-Sept-14
    // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
    var r, g, b;
    var h = step / numOfSteps;
    var i = ~~(h * 6);
    var f = h * 6 - i;
    var q = 1 - f;
    switch(i % 6){
        case 0: r = 1; g = f; b = 0; break;
        case 1: r = q; g = 1; b = 0; break;
        case 2: r = 0; g = 1; b = f; break;
        case 3: r = 0; g = q; b = 1; break;
        case 4: r = f; g = 0; b = 1; break;
        case 5: r = 1; g = 0; b = q; break;
    }
    var c = "#" + ("00" + (~ ~(r * 255)).toString(16)).slice(-2) + ("00" + (~ ~(g * 255)).toString(16)).slice(-2) + ("00" + (~ ~(b * 255)).toString(16)).slice(-2);
    return (c);
}

const monthMap = {
	1: 'Janeiro',
	2: 'Fevereiro',
	3: 'Março',
	4: 'Abril',
	5: 'Maio',
	6: 'Junho',
	7: 'Julho',
	8: 'Agosto',
	9: 'Setembro',
	10: 'Outubro',
	11: 'Novembro',
	12: 'Dezembro',
}

const colorMap = {
	'mirante': '#222222',
	'vila-velha': '#CC0000',
	'serra': '#CCFF33',
	'test': '#00FFFF',
}

const today = new Date();
const todaysYear = today.getFullYear();
const todaysMonth = today.getMonth() + 1;
const todaysDay = today.getDate();

const minYear = 2019;

const useStyles = makeStyles((theme) => ({
  root: {},
  chartContainer: {
    height: 432,
    position: 'relative',
		paddingTop: theme.spacing(2),
  },
	content: {
		flexGrow: 1,
		padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    position: 'relative'
	},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestSales = props => {
	console.log('WTFUCKCKCKCK', props);
  const { className, branch, ...rest } = props;

	const {
		day,
		month,
		year,
	} = props;

  const classes = useStyles();

	const { palette } = useTheme();
	console.log(palette);

	const [ branchCaptures, setBranchCaptures ] = useState({});

	const [ groupedCaptures, setGroupedCaptures ] = useState({});

	const [ loading, setLoading ] = useState(true);


	let maxMonth = 12;
	let maxDay = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()

	if(todaysYear === year) {
		maxMonth = todaysMonth;
	}

	const yearsArray = [...new Array(1 + todaysYear - minYear)];
	const monthsArray = [...new Array(maxMonth)];


	if(year === todaysYear) {
		maxMonth = todaysMonth;
		if(month === todaysMonth) {
			maxDay = todaysDay
		}
	}


	React.useEffect(() => {
		if(!branch) return;
		setLoading(true);
		getDeviceCapturesHistogram({branchId: branch, year, month}).then(data => {
			console.log('Got deviceCapturesHistogram response', data, 'for branch', branch);

			let finalData = {}

			data.forEach(({deviceid, year, month, day, count}) => {
				const deviceData = finalData[deviceid] || {};
				deviceData[`${year}/${('' + month).padStart(2,0)}/${('' + day).padStart(2,0)}`] = count;
				finalData[deviceid] = deviceData;
			});

			console.log('Parsing finalData', finalData);

			const branches = Object.keys(finalData);

			const dSets = branches.map((branch, index) => {

				const entries = [];
				for(let day = 1; day <= maxDay; day++) {
				//for(let month = 1; (year !== todaysYear && month <= 12) || (year === todaysYear && month <= todaysMonth) ; month++) {
					const dateString = `${year}/${('' + month).padStart(2,0)}/${('' + day).padStart(2,0)}`;

					const deviceData = finalData[branch];

					console.log('Parsing deviceData', deviceData, 'for dateString', dateString);

					const count = deviceData[dateString] || 0;//finalData[branch][`${year}/${('' + month).padStart(2,0)}/${('' + day).padStart(2,0)}`] || {};
					console.log(+count, 'on day', day, 'for device', branch);
					entries.push(+count);	
				}
				return {
					label: branch,
					backgroundColor: getColor(index, branches.length),
					data: entries,
				};
			});

			setDatasets(dSets);
/*

			const datasetss = branches.map((branch, index) => {
				const entries = [];
				for(let month = 1; (year !== todaysYear && month <= 12) || (year === todaysYear && month <= todaysMonth) ; month++) {
					const { count = 0 } = data.find(d => d.branchid === branch && d.month === month) || {};
					console.log(+count, 'on month', month, 'for branch', branch);
					entries.push(+count);	
				}
				return {
					label: branch,
					backgroundColor: getColor(index, branches.length),
					data: entries,
				};
			});
			console.log('LLLLLL', datasetss);
			setDatasets(datasetss);

			*/

			setBranchCaptures(finalData);
			setLoading(false);
			/*
			ranchCaptures => {
				branchCaptures[branch] = data;
				return {...branchCaptures};
			});
			*/
		})
	}, [branch, year, month]);



	const daysArray = [...new Array(maxDay)];

	console.log('MAXDAY', maxDay, 'produced array', daysArray);

	const labels = daysArray.map((_, index) => `${year}/${('' + month).padStart(2,0)}/${('' + (index + 1)).padStart(2,0)}`);


	const [ datasets, setDatasets ] = useState([]);

	/*
	const datasets = [branch].map(b => {
		let captures = branchCaptures || {} ;

		let { total = 0, histogram = {} } = captures;

		let data = Object.keys(histogram).map(captureDate => {


			labels.add(captureDate);
			return histogram[captureDate]
		});

		return {
			label: b,
      backgroundColor: palette.primary.main,
			data,
		};
	})
	*/

	console.log('OMGISISTSGOOD', labels, 'is the label and datasets are', datasets);

const data = {
	labels: Array.from(labels),
	datasets,
}

const olddata = {
  labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug', '7 Aug'],
  datasets: [
    {
      label: 'This year',
      backgroundColor: palette.primary.main,
      data: [18, 5, 19, 27, 29, 19, 20]
    },
    {
      label: 'Last year',
      backgroundColor: palette.neutral,
      data: [11, 20, 12, 29, 30, 25, 13]
    }
  ]
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: '#FFF',//palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    ]
  }
};



  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
			elevation={4}
    >
      <CardHeader
        title="Histórico de capturas"
      />
      <Divider />
      <CardContent className={classes.content}>
					{loading && <Progress />}
        <div className={classes.chartContainer}>
          <Bar
            data={data}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string
};

export default LatestSales;
