import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import CircularProgress from '@material-ui/core/CircularProgress';

import Collapse from '@material-ui/core/Collapse';

import { lighten } from '@material-ui/core/styles/colorManipulator';

import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ptLocale from 'date-fns/locale/pt';

import Logger from '../../../modules/Logger';
const log = Logger('CardsPanel');


//class EnhancedTableHead extends React.Component {
//	createSortHandler = property => event => {
//		this.props.onRequestSort(event, property);
//	};
//
//	render() {
//		const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;
//
//		const {
//			rows,
//			showSelection = false, 
//		} = this.props;
//
//		return (
//			<TableHead>
//				<TableRow>
//					{rows.map(
//						(row, index) => (
//							<TableCell
//								key={row.id}
//								align={row.numeric ? 'right' : 'left'}
//								padding={showSelection && index === 0 ? 'none' : 'default'}
//								sortDirection={orderBy === row.id ? order : false}
//							>
//
//								{!row.sortable 
//								?
//									row.label
//									
//							:
//								(<Tooltip
//									title="Sort"
//									placement={row.numeric ? 'bottom-end' : 'bottom-start'}
//									enterDelay={300}
//								>
//									<TableSortLabel
//										active={orderBy === row.id}
//										direction={order}
//										onClick={this.props.onSort(row.id, order === 'desc' ? 'asc' : 'desc')}
//									>
//										{row.label}
//									</TableSortLabel>
//								</Tooltip>)
//								}
//							</TableCell>
//						),
//						this,
//					)}
//				</TableRow>
//			</TableHead>
//		);
//	}
//}
//
//EnhancedTableHead.propTypes = {
//	numSelected: PropTypes.number.isRequired,
//	onRequestSort: PropTypes.func.isRequired,
//	onSelectAllClick: PropTypes.func.isRequired,
//	order: PropTypes.string.isRequired,
//	orderBy: PropTypes.string.isRequired,
//	rowCount: PropTypes.number.isRequired,
//};

const toolbarStyles = theme => ({
	root: {
		paddingRight: theme.spacing.unit,
	},
	highlight:
		theme.palette.type === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
				}
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
				},
	spacer: {
		flex: '1 1 100%',
	},
	actions: {
		color: theme.palette.text.secondary,
		margin: '16px',
	},
	title: {
		flex: '0 0 auto',
	},
  container: {
    display: 'flex',
    flexWrap: 'wrap',
		justifyContent: 'center',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
		minWidth: '40%',

  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

	const MaDateTimePicker = props => ( 
      <DateTimePicker
				className={props.classes.textField}
				label={props.label}
				disableFuture
				ampm={false}
				value={props.value}
				onChange={props.onChange}
				autoOk
        format="dd/MM/yyyy HH:mm"
				cancelLabel="Cancelar"
				okLabel="Confirmar"
				clearLabel="Limpar"
				todayLabel="Hoje"
				clearable
			/>)

let EnhancedTableToolbar = props => {
	const {
		onMinDateChange,
		onMaxDateChange,
		onFilterSubmit,
		formValues,
		classes,
		title,
		actions: Actions,
		titleExtra : TitleExtra,
		groups,
		routes,
		selectedGroup,
		selectedRoute,
		setSelectedRoute,
		setSelectedGroup,
	} = props;
	
	const filters = [
		{ value: 'meterId', label: 'Nº Hidrômetro' },
		{ value: 'installationId', label: 'Número da ligação' },
	];

	const handleSubmit = e => {
		e.preventDefault();
		log.i('Got a form submit');
		onFilterSubmit();
	}

	log.i('ASDGroups are', groups);
	log.i('ASDRoutes are', routes);
	log.i('ASDSelectedGroup are', selectedGroup);
	log.i('ASDSelectedRoute are', selectedRoute);

	return (
	<>
		<Toolbar
			className={classes.root}
		>
			<div className={classes.title}>
				<Typography variant="h6" id="tableTitle">
					{props.title || 'Title props'}
				</Typography>
			</div>

				{TitleExtra && <div style={{marginBottom: '8px', marginLeft: '8px'}}>
					{TitleExtra}
				</div>}

			<div className={classes.spacer} />

          <Tooltip title="Filtrar capturas">
            <IconButton aria-label="filter list" onClick={props.handleFilterClick}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
			<div className={classes.actions}>
				{Actions && <Actions />}
			</div>
		</Toolbar>
      
			
			<Collapse in={props.showFilters} timeout="auto" unmountOnExit>


		<div className={classes.container} >
	    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
	      <MaDateTimePicker
					classes={classes}
					label="A partir da data"
					value={props.minDate}
					onChange={props.handleMinDateChange}
				/>
	      <MaDateTimePicker
					classes={classes}
					label="Até a data"
					value={props.maxDate}
					onChange={props.handleMaxDateChange}
				/>
	    </MuiPickersUtilsProvider>
		</div>
			


		<div className={classes.container} >
      <TextField
        id="standard-select-groupId"
        select
        label="Filtrar por grupo"
				disabled={groups.length === 0}
        className={classes.textField}
        value={selectedGroup}
        onChange={(e) => {
					const val = e.target.value;
//					props.handleChange('groupId')(e);
//					props.handleChange('routeId')({ target: { value: '' } });
					setSelectedRoute('');
					setSelectedGroup(val);
				}}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        hhelperText="Selecione uma opção de filtro"
        margin="normal"
      >
        {
					[	(<MenuItem key="all_groups" value={''}>
           		Todos os grupos
          	</MenuItem>),
					...groups.map(option => (
          	<MenuItem key={option} value={option}>
           	 {option}
          	</MenuItem>
        	))]
				}
      </TextField>

      <TextField
        id="standard-select-routeId"
        select
        label="Filtrar por rota"
				disabled={routes.length <= 1}
        className={classes.textField}
        value={selectedRoute}
        onChange={(e) => {
					const val = e.target.value;
					setSelectedRoute(val)
					//props.handleChange('routeId')(e)
				}}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        hhelperText="Filtre por uma rota específica dentro do grupo"
        margin="normal"
      >
        {!!selectedGroup ? 
          [	(<MenuItem key="all_routes" value={''}>
           		Todas as rotas
          	</MenuItem>),
					...routes.map(option => (
          	<MenuItem key={option} value={option}>
           	 {option}
          	</MenuItem>
        	))]
				:
          	<MenuItem key="no_route" value={''}>
           		Selecione um grupo primeiro
          	</MenuItem>
				}
      </TextField>
		</div>




    <form className={classes.container} noValidate autoComplete="off" onSubmit={handleSubmit} >

      <TextField
        id="standard-select-seachKey"
        select
        label="Buscar por"
        className={classes.textField}
        value={props.values.searchKey}
        onChange={props.handleChange('searchKey')}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        helperText="Selecione uma opção de filtro"
        margin="normal"
      >
        {filters.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        id="standard-search-searchValue"
        label="Valor"
        type="search"
        className={classes.textField}
        margin="normal"
				disabled={!props.values.searchKey}
        onChange={props.handleChange('searchValue')}
        value={props.values.searchValue}
      />

    </form>


      </Collapse>
	</>

	);
};

EnhancedTableToolbar.propTypes = {
	classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
	root: {
	},
	cardsHolder: {
		padding: '8px',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	table: {
	},
	tableWrapper: {
		overflowX: 'auto',
	},
});


const mainStyles = makeStyles(theme => ({
	root: {
	},
	cardsHolder: {
		padding: '8px',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	table: {
	},
	tableWrapper: {
		overflowX: 'auto',
	},
}));


export default function CapturesTable(props) {

	const classes = mainStyles();

	const { children, title } = props;
	const { selectedBranch, loading, data = [], order, orderBy, selected = [], rowsPerPage, page } = props;

	const {
		setSelectedGroup,
		selectedGroup,
		setSelectedRoute,
		selectedRoute,
		groups,
		routes,
	} = props;


	console.log('groups on mainStyles is', groups);

//		return (
//			<Paper className={classes.root} elevation={10}>
//				<EnhancedTableToolbar numSelected={selected.length} title={title} actions={this.props.actions} />
//				<div className={classes.tableWrapper}>
//					<Table className={classes.table} aria-labelledby="tableTitle">
//						<TableBody className={classes.cardsHolder}>
//							{children}	
//						</TableBody>
//					</Table>
//				</div>
//				<div style={{height: '64px'}} />
//			</Paper>
//		);
				//<EnhancedTableToolbar numSelected={selected.length} title={title} actions={this.props.actions} {...this.props}/>

		return (
			<Paper className={classes.root} elevation={10}>
				<EnhancedTableToolbar
					numSelected={selected.length}
					groups={groups}
					routes={routes}
					selectedGroup={selectedGroup}
					selectedRoute={selectedRoute}
					setSelectedGroup={setSelectedGroup}
					setSelectedRoute={setSelectedRoute}
					{...props}
				/>
				<div className={classes.tableWrapper}>
					<Table className={classes.table} aria-labelledby="tableTitle">
						<TableBody className={classes.cardsHolder}>
							{children}	
						</TableBody>
					</Table>
				</div>
				{props.disabled ? 
					<div style={{height:'24px'}} />
				:
				<TablePagination
					component="div"
					onChangePage={props.onChangePage}
					onChangeRowsPerPage={props.onChangeRowsPerPage}
					rowsPerPageOptions={props.rowsPerPageOptions}
					count={props.rowsCount}
					rowsPerPage={props.rowsPerPage}
					page={props.pageNumber}
					labelRowsPerPage="Capturas por página"
					labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
					backIconButtonProps={{
						'aria-label': 'Página anterior',
					}}
					nextIconButtonProps={{
						'aria-label': 'Próxima página',
					}}
				/>
				}
			</Paper>
		);

}




/*
class EnhancedTable extends React.Component {

	render() {
		const { classes, children, title } = this.props;
		const { loading, data = [], order, orderBy, selected = [], rowsPerPage, page } = this.props;

//		return (
//			<Paper className={classes.root} elevation={10}>
//				<EnhancedTableToolbar numSelected={selected.length} title={title} actions={this.props.actions} />
//				<div className={classes.tableWrapper}>
//					<Table className={classes.table} aria-labelledby="tableTitle">
//						<TableBody className={classes.cardsHolder}>
//							{children}	
//						</TableBody>
//					</Table>
//				</div>
//				<div style={{height: '64px'}} />
//			</Paper>
//		);
				//<EnhancedTableToolbar numSelected={selected.length} title={title} actions={this.props.actions} {...this.props}/>

		return (
			<Paper className={classes.root} elevation={10}>
				<EnhancedTableToolbar numSelected={selected.length} {...this.props}/>
				<div className={classes.tableWrapper}>
					<Table className={classes.table} aria-labelledby="tableTitle">
						<TableBody className={classes.cardsHolder}>
							{children}	
						</TableBody>
					</Table>
				</div>
				{this.props.disabled ? 
					<div style={{height:'24px'}} />
				:
				<TablePagination
					component="div"
					onChangePage={this.props.onChangePage}
					onChangeRowsPerPage={this.props.onChangeRowsPerPage}
					rowsPerPageOptions={this.props.rowsPerPageOptions}
					count={this.props.rowsCount}
					rowsPerPage={this.props.rowsPerPage}
					page={this.props.pageNumber}
					labelRowsPerPage="Capturas por página"
					labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
					backIconButtonProps={{
						'aria-label': 'Página anterior',
					}}
					nextIconButtonProps={{
						'aria-label': 'Próxima página',
					}}
				/>
				}
			</Paper>
		);
	}
}


//const myRows = [
//	{ id: 'name', sortable: true, numeric: false, label: 'Dessert (100g serving)' },
//	{ id: 'calories', sortable: true, numeric: true, label: 'Calories' },
//	{ id: 'fat', numeric: true, label: 'Fat (g)' },
//	{ id: 'carbs', numeric: true, label: 'Carbs (g)' },
//	{ id: 'protein', numeric: true, label: 'Protein (g)' },
//];
//
//let myData = [
//	createData('Cupcake', 305, 3.7, 67, 4.3),
//	createData('Donut', 452, 25.0, 51, 4.9),
//	createData('Eclair', 262, 16.0, 24, 6.0),
//	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//	createData('Gingerbread', 356, 16.0, 49, 3.9),
//	createData('Honeycomb', 408, 3.2, 87, 6.5),
//	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//	createData('Jelly Bean', 375, 0.0, 94, 0.0),
//	createData('KitKat', 518, 26.0, 65, 7.0),
//	createData('Lollipop', 392, 0.2, 98, 0.0),
//	createData('Marshmallow', 318, 0, 81, 2.0),
//	createData('Nougat', 360, 19.0, 9, 37.0),
//	createData('Oreo', 437, 18.0, 63, 4.0),
//]
//
//function fetchData(idToOrderBy, order, pageNumber, rowsPerPage) {
//	let tableData = [...myData];
//	if(idToOrderBy) {
//		tableData = stableSort(tableData, getSorting(order, idToOrderBy));
//	}
//
//	tableData = tableData.slice(pageNumber * rowsPerPage, pageNumber * rowsPerPage + rowsPerPage)
//
//	return new Promise(resolve => {
//		setTimeout(() => {
//			resolve(tableData);
//		}, 500);
//	});
//
//}
//
//function MTable(props) {
//
//
//	let [ pageNumber , setPageNumber ] = useState(0);
//	let [ rowsPerPage, setRowsPerPage ] = useState(10);
//
//	let [ order, setOrder ] = useState('asc');
//	let [ idToOrderBy, setIdToOrderBy ] = useState('');
//
//	let [ loading, setLoading ] = useState(true);
//
//	let [ tableData, setTableData ] = useState([]);
//
//	let handleChangePage = (event, page) => {
//		setPageNumber(page);
//	};
//
//	let handleChangeRowsPerPage = event => {
//		setRowsPerPage(event.target.value);
//	};
//	
//	React.useEffect(() => {
//		setLoading(true);
//		fetchData(idToOrderBy, order, pageNumber, rowsPerPage).then(data => {
//			setTableData(data);
//			setLoading(false);
//		});
//	}, [idToOrderBy, order, pageNumber, rowsPerPage]);
//
//	return (
//		<EnhancedTable {...props} 
//			onRowClick={(id) => log.i('clicked on row', id)}
//			canSelect={false}
//			title="My Table"
//			rows={myRows}
//			data={tableData}
//			orderBy={idToOrderBy}
//			order={order}
//			onSort={(id, order) => e => {
//				setOrder(order);
//				setIdToOrderBy(id);
//			}}
//			onChangePage={handleChangePage}
//			onChangeRowsPerPage={handleChangeRowsPerPage}
//			rowsPerPageOptions={[5,10,25,50]}
//			rowsCount={myData.length}
//			rowsPerPage={rowsPerPage}
//			pageNumber={pageNumber}
//			loading={loading}
//		/>
//	);
//}
//
//function MTableHeader(props) {
//}
//
//function MTableHeaderOption(props) {
//}
//
//function MTableEntry(props) {
//}



EnhancedTable.propTypes = {
	classes: PropTypes.object.isRequired,
};

//export default withStyles(styles)(EnhancedTable);
*/
