import React, { useState } from 'react';

import { Switch, Route, withRouter } from 'react-router-dom';

import CardsPanel from './CardsPanel';
//import Card from './CaptureCard';
import { Redirect } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import NoPicturesIcon from '@material-ui/icons/LocationOff';
import DownloadIcon from '@material-ui/icons/AddLocation';
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

import { watchRoutesCatalog } from './UploadStepper/Routes';
//import { watchRoutesCatalog } from '../../../modules/Routes';

import GroupsList from './GroupsList';

import Typography from '@material-ui/core/Typography';


import { Select, MenuItem } from '@material-ui/core';


import Logger from '../../../modules/Logger';
const log = Logger('RoutesMaster');

const styles = theme => ({
	pageRoot: {
		padding: '16px',
		margin: '8px',
		backgroundColor: '#4f4f4f',
		display: 'flex',
		flexWrap: 'wrap',
	},
	cardContainer: {
		margin: '16px',
	},
	noPictureIcon: {
		width: 100,
		height: 100,
		marginBottom: '8px',
		color: theme.palette.text.secondary,
	},
	noPictureText: {
		color: theme.palette.text.secondary,
	}
});

function MasterGrid(props) {

	let {
		classes,
		match,
		onPageDataRequest,
		selectedBranch,
		setSelectedBranch,
		branches,
	} = props;




	let [ pageNumber , setPageNumber ] = useState(0);
	let [ rowsPerPage, setRowsPerPage ] = useState(12);

	let [ dataLength, setDataLength ] = useState(0);

	let [ order, setOrder ] = useState('asc');
	let [ idToOrderBy, setIdToOrderBy ] = useState('');

	let [ loading, setLoading ] = useState(true);
	let [ pendingCatalog, setPendingCatalog ] = useState(false);

	let [ exportLoading, setExportLoading ] = useState(false);

	let [ tableData, setTableData ] = useState([]);

	let [ redirectTo, setRedirectTo ] = useState();

	let handleChangePage = (event, page) => {
		setPageNumber(page);
	};

	React.useEffect(() => {
		setTableData([]);
		setLoading(true);
		const id = watchRoutesCatalog(selectedBranch, data => {
			log.i('Catalog update', data);
			if(data.status === 'ok') {
				setTableData(data.catalog);
				setPendingCatalog(false);
			} else {
				setTableData([]);
				setPendingCatalog(true);
			}
			setLoading(false);
		});
		return () => clearInterval(id);
	}, [selectedBranch]);

	const handleClick = props.onClick;

	log.i('Routes props', props);

	return(
		<CardsPanel
			title="Rotas"
			titleExtra={
				<Select
					style={{marginTop: '8px', marginLeft: '8px', marginRight: '8px'}}
					disabled={branches.length <= 1}
          value={selectedBranch}
          onChange={e => setSelectedBranch(e.target.value)}
          inputProps={{
            name: 'branch',
            id: 'branch-selector',
          }}
        >
					{branches.map(branchName => (<MenuItem key={branchName} value={branchName}>{branchName}</MenuItem>) )}
        </Select>
			}
			loading={loading}
			disabled

			actions={(props) => (
			<Tooltip title="Carregar rotas">
      	<Button
					size="small"
					disabled={loading || pendingCatalog}
					variant="contained"
					color="primary"
					aria-label="Carregar rotas"
					className={classes.fab}
					onClick={handleClick}
				>
					{exportLoading ?
									<div style={{position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px'}} >
											<CircularProgress size={24} color="secondary" className={classes.progress} />
									</div>
					: <DownloadIcon />}
	      </Button>
			</Tooltip>
)
			}
		>
			{loading ?

					<div style={{position:'relative', height:'300px', width:'100%'}}>
						<CircularProgress color="primary" style={{position:'absolute', top:'50%', left:'50%', marginTop:'-20px', marginLeft:'-20px'}} size={40}/>
					</div>
			:
				tableData.length > 0 ?
					<GroupsList data={tableData} />
				:
					<div style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						height:'300px',
						width:'100%',
					}}>

						<div style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						position:'relative',
						marginTop: '-50px',
						}}>
						{pendingCatalog ?
							(<>

		    	    	<Typography variant="body1" className={classes.noPictureText} component="p">
		    	    	  As rotas estão sendo atualizadas
								</Typography>
								<LinearProgress style={{marginTop: '8px', width: '100%'}} />
							</>)
						:
							(<>
								<NoPicturesIcon className={classes.noPictureIcon} />

		    	    	<Typography variant="body1" className={classes.noPictureText} component="p">
		    	    	  Nenhuma rota encontrada
								</Typography>
							</>)
						}
						</div>
					</div>
							
			}
		</CardsPanel>
	);

}
				

//function MTable(props) {
//
//
//	let { onPageDataRequest } = props;
//
//	let [ pageNumber , setPageNumber ] = useState(0);
//	let [ rowsPerPage, setRowsPerPage ] = useState(10);
//
//	let [ dataLength, setDataLength ] = useState(0);
//
//	let [ order, setOrder ] = useState('asc');
//	let [ idToOrderBy, setIdToOrderBy ] = useState('');
//
//	let [ loading, setLoading ] = useState(true);
//
//	let [ tableData, setTableData ] = useState([]);
//
//	let [ redirectTo, setRedirectTo ] = useState();
//
//	let handleChangePage = (event, page) => {
//		setPageNumber(page);
//	};
//
//	let handleChangeRowsPerPage = event => {
//		setRowsPerPage(event.target.value);
//		setPageNumber(0);
//	};
//	
//	React.useEffect(() => {
//		setLoading(true);
//		onPageDataRequest(idToOrderBy, order, pageNumber, rowsPerPage).then(response => {
//			log.i('GOT DATA', response);
//			setTableData(response.data);
//			setDataLength(response.total);
//			setLoading(false);
//		});
//	}, [idToOrderBy, order, pageNumber, rowsPerPage]);
//
//	if(!!redirectTo) {
//		return <Redirect push to={redirectTo} />
//	}
//
//	return (
//		<Table {...props} 
//			onRowClick={(id) => {log.i('clicked on row', id, 'moving to', `${props.match.url}/${id}`); setRedirectTo(`${props.match.url}/${id}`)}}
//			canSelect={false}
//			title="My Table"
//			rows={myRows}
//			data={tableData}
//			orderBy={idToOrderBy}
//			order={order}
//			onSort={(id, order) => e => {
//				setOrder(order);
//				setIdToOrderBy(id);
//			}}
//			onChangePage={handleChangePage}
//			onChangeRowsPerPage={handleChangeRowsPerPage}
//			rowsPerPageOptions={[5,10,25,50]}
//			rowsCount={dataLength}
//			rowsPerPage={rowsPerPage}
//			pageNumber={pageNumber}
//			loading={loading}
//		/>
//	);
//}
//
//function Tables(props) {
//	const { classes } = props;
//
//	return (
//		<div>
//			<div className={classes.pageRoot}>
//				<MTable {...props} />
//			</div>
//		</div>
//	);
//}

export default withStyles(styles)(MasterGrid);
