import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';

import { Link } from 'react-router-dom';

import { useTheme } from '@material-ui/styles';

import { Select, MenuItem } from '@material-ui/core';

import Progress from 'components/ProgressSpinner';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

//import { data, options } from './chart';

import {
  getExistingBranches,
  getBranchCapturesByDate,
	getRoutesByMonth,
} from 'modules/Analytics';

const monthMap = {
	1: 'Janeiro',
	2: 'Fevereiro',
	3: 'Março',
	4: 'Abril',
	5: 'Maio',
	6: 'Junho',
	7: 'Julho',
	8: 'Agosto',
	9: 'Setembro',
	10: 'Outubro',
	11: 'Novembro',
	12: 'Dezembro',
}

const colorMap = {
	'mirante': '#222222',
	'vila-velha': '#CC0000',
	'serra': '#CCFF33',
	'test': '#00FFFF',
}

const today = new Date();
const todaysYear = today.getFullYear();
const todaysMonth = today.getMonth() + 1;

const minYear = 2019;

const useStyles = makeStyles((theme) => ({
  root: {},
	contentRoot: {
		padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    position: 'relative',
	},
  chartContainer: {
    height: 200,
    position: 'relative',
		padding: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestSales = props => {
	console.log('WTFUCKCKCKCK', props);
  const { className, branch, ...rest } = props;

  const classes = useStyles();

	const { palette } = useTheme();
	console.log(palette);

	const [ branchCaptures, setBranchCaptures ] = useState({});

	const [ year, setYear ] = useState(todaysYear);
	const [ month, setMonth ] = useState(todaysMonth);

	const [ groupedCaptures, setGroupedCaptures ] = useState({});

	const [ loading, setLoading ] = useState(true);

	const [ chartData, setChartData ] = useState({});

	React.useEffect(() => {

			if(!branch) {
				console.log('No branch selected yet. Waiting');
				return;
			}

		setLoading(true);
		getRoutesByMonth({branchId: branch, year, month}).then(routesData => {
			console.log('LLLLLOMG', 'got routes data', routesData);
	
			let labels = [];
			let data = []
      Object.keys(routesData).forEach(day => {
				labels.push(`${year}/${month}/${day}`);
				data.push(routesData[day]);
			});
			setChartData({
				labels,
				datasets: [{
					label: 'Rotas',
      		backgroundColor: palette.primary.main,
					data,
				}],
			});
			setLoading(false);

		});
	}, [branch, year, month]);

	let maxMonth = 12;

	if(todaysYear === year) {
		maxMonth = todaysMonth;
	}

	const yearsArray = [...new Array(1 + todaysYear - minYear)];
	const monthsArray = [...new Array(maxMonth)];

	/*
	const labels = new Set();
	const datasets = [branch].map(b => {
		let captures = branchCaptures || {} ;

		let { total = 0, histogram = {} } = captures;

		let data = Object.keys(histogram).map(captureDate => {


			labels.add(captureDate);
			return histogram[captureDate]
		});

		return {
			label: b,
			backgroundColor: colorMap[b],
			data,
		};
	})
*/
const data = chartData; 

const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: '#FFF',//palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    ]
  }
};



  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
			elevation={4}
    >
      <CardHeader
        action={
				<>

				<Select
					style={{marginTop: '8px', marginLeft: '8px', marginRight: '8px'}}
					disabled={monthsArray.length <= 1}
          value={month}
          onChange={e => setMonth(e.target.value)}
          inputProps={{
            name: 'month',
            id: 'month-filter',
          }}
        >
					{monthsArray.map((_,index) => (<MenuItem key={index} value={index+1}>{monthMap[index+1]}</MenuItem>) )}
        </Select>

				<Select
					style={{marginTop: '8px', marginLeft: '8px', marginRight: '8px'}}
					disabled={yearsArray.length <= 1}
          value={year}
          onChange={e => setYear(e.target.value)}
          inputProps={{
            name: 'year',
            id: 'year-filter',
          }}
        >
					{yearsArray.map((_,index) => (<MenuItem key={index} value={minYear + index}>{minYear + index}</MenuItem>) )}
        </Select>

				</>

        }
        title="Histórico"
      />
      <Divider />
      <CardContent className={classes.contentRoot}>
				{loading && <Progress />}
        <div className={classes.chartContainer}>
          <Bar
            data={data}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string
};

export default LatestSales;
