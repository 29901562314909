//import { db.firestore(). storage } from '../config/constants';
//import { db, firebase } from '../../../../modules/Database';
import Logger from '../../../../modules/Logger';

let log = Logger('Routes');

var routesData;

const axios = require('axios');

const moment = require('moment');

const http = axios.create({
//	baseURL: 'https://34.95.172.222'
	//baseURL: 'https://analytics.dcod.com.br'
	baseURL: 'https://hanalytics.dcod.com.br'
});


var db = {};

function getBranchCatalog(branchId, callback) {
	console.log('Checking routes catalog for branch', branchId);
	http.get('/routes', { params: { branchId, }, withCredentials: true})
		.then(response => {
			console.log('Retrieved catalog data', response.data);
			callback(response.data);
			/*
			callback({
				status: 'pending',
				catalog: [],
			});
			*/
		})
		.catch(e => console.log('WTFOMG', e));
}

export function watchRoutesCatalog(branchId, callback) {

	getBranchCatalog(branchId, callback);
	return setInterval(() => {
		getBranchCatalog(branchId, callback);
	}, 5000);
	/*
	return db.firestore()
					.collection('routes')
					.doc('!catalog')
					.onSnapshot(snap => {
						const catalog = snap.data() && snap.data().entries;
						log.i('Got catalog', catalog);
						if(!catalog) return callback([]);

						const parsedCatalog = {};
						catalog.forEach(entry => {
							const { groupId, routeId } = entry;
							const groupData = parsedCatalog[groupId] || [];
							groupData.push(entry);
							parsedCatalog[groupId] = groupData;
						});

						const parsedData = Object.keys(parsedCatalog).map(groupId => ({groupId, routes: parsedCatalog[groupId]}) );
						log.i('Parsed catalog into', parsedData);
						callback(parsedData);
					});
					*/
}

export function getRequiredFields() {
	return {
    groupId: "GRUPO",
    routeId: "ROTA",
    sequenceId: "SEQUENCIA",
    installationId: "ID_LIGACAO",
    addressStreetName: "RUA",
    addressNumber: "NUMERO",
    addressExtra: "COMPLEMENTO",
    addressNeighbourhood: "BAIRRO",
    meterId: "ID_HIDROMETRO",
	}
}

function getContentIndexes(header) {

  const dataLabels = getRequiredFields();

  const dataIndexes = {};
	const missingIndexes = [];

  for(let dataLabel in dataLabels) {
    let labelIndex = header.indexOf(dataLabels[dataLabel]);
		if(labelIndex < 0) {
			missingIndexes.push(dataLabels[dataLabel]);
		} else {
    	dataIndexes[dataLabel] = labelIndex;
		}
  }

	return [dataIndexes, missingIndexes];

}

export function parse(fileData, user) {

	return new Promise((resolve, reject) => {
	  log.i("got data type", typeof fileData);
		const [ header, ...data ] = fileData;
		log.i('Header is', header);
	  log.i("got data length", data.length);
	
	  // const totalEntries = 100;
	  const totalEntries = data.length;
	
	  // Get from a textfield
	//  const routeName = "staging";
	
		const [ dataIndexes, missingIndexes ] = getContentIndexes(header);
	  log.i("DataIndexes", dataIndexes);
		log.i('Missing', missingIndexes);
	
		if(missingIndexes.length > 0) {
			return reject({
				code: 'parse/missing-headers',
				message: 'Colunas não encontradas: ' + missingIndexes.join(', '),
				data: missingIndexes,
			});
		}
		//return;
	  // while(doneEntries < testEntries) {
	
	  const routeData = {};
	
		
	  // totalEntries = 30;
	
	  log.i("Starting");
	
	  for(let i = 0; i < totalEntries; i++) {
	
	    let currentData = data[i];
	
	    if(currentData.length !== header.length) {
	      log.i("skipping line", i);
	      continue;
	    }
	
	    let { groupId, routeId, sequenceId, installationId, meterId,
	      addressStreetName, addressNumber, addressExtra, addressNeighbourhood } = dataIndexes;
	
	    let group = currentData[groupId];
	    let route = currentData[routeId];
	    let sequence = currentData[sequenceId];
	    let installation = currentData[installationId];
	
	    // log.i("Group:" + group, "Route:" + route, "Sequence:" + sequence, "ID:" + installation);
	
	    let groupRoutes = routeData[group] || {};
	    let groupRouteEntries = groupRoutes[route] || [];
	
	
	    groupRouteEntries.push({
	      branchId: user.branch,
	      routeName: user.branch,
	      groupId: group,
	      routeId: route,
	      sequenceId: sequence,
	      installationId: installation,
	      meterId: currentData[meterId],
	      addressStreetName: currentData[addressStreetName],
	      addressNumber: currentData[addressNumber],
	      addressExtra: currentData[addressExtra],
	      addressNeighbourhood: currentData[addressNeighbourhood],
	    });
	
	    groupRoutes[route] = groupRouteEntries;
	    routeData[group] = groupRoutes;
	  }
	 
	
	  log.i("Parsed into", routeData);
		resolve(routeData);
	});
}

export async function writeData(data){
}



export async function sendRoutes(csv, branchId,routeData, user, monitor) {

var formData = new FormData();
formData.append("file", csv);
formData.append("branchId", branchId);

	console.log('Sending CSV file', csv);
	try {
	const res = await http.post('/routes',
		formData,
		{
			withCredentials: true,
    	headers: {
    		'Content-Type': 'multipart/form-data',
    	}
		}
	);
		console.log('Now back with res', res);
	} catch (e) {
		console.log('FAILED uploading:', e);
	}
}

export async function firebaseBasedSendRoutes(routeData, user, monitor) {
  log.i("Writing content");

	let branchId = user.branch;

	if(!branchId || !monitor ) {
		log.i('Aborting..');
		return;
	}

	const routeName = branchId

	const limitPerRoute = 500;
	const threshold = 1.5;

	const parsedRoutesData = [];

	let totalRoutes = 0;

  for(let group in routeData) {
    //log.i("Group", group);
    let groupRoutes = routeData[group];
    for(let route in groupRoutes) {
      let sequence = groupRoutes[route];
      log.i("Route", route, " from group ", group, "has", sequence.length, "entries");
			totalRoutes++;
			
			const addressGroups = [];

			if(sequence.length > limitPerRoute*threshold) {
//				let i = 1;
				while(sequence.length > limitPerRoute) {
					totalRoutes++;
					if(sequence.length < limitPerRoute*threshold) {
						let subGroup = sequence;
						addressGroups.push(subGroup);
						sequence = [];
//						groupRoutes[`${route}-${i++}`] = subGroup;
					} else {
						let subGroup = sequence.splice(0, limitPerRoute);
						addressGroups.push(subGroup);
//						groupRoutes[`${route}-${i++}`] = subGroup;
					}
				}
				if(sequence.length > 0){
					addressGroups.push(sequence);
//					groupRoutes[`${route}-${i}`] = sequence;
					totalRoutes++;
				}
//				delete groupRoutes[route];
				totalRoutes--;
			} else {
				addressGroups.push(sequence);
			}

      groupRoutes[route] = addressGroups;

			log.i('GroupRoutes now is', groupRoutes);
//			continue
      // log.i({
      //   routeName: routeName,
      //   routeData: {
      //     group: group,
      //     route: route,
      //     addresses: sequence,
      //   }
      // });

      // continue;
    }
  }

	log.i('NOW IM SENDING', routeData);
//	return;

	const myNewNewData = [];

  let groupCounter = 0;
  for(let group in routeData) {
    //log.i("Group", group);
    let groupRoutes = routeData[group];
    for(let route in groupRoutes) {

      let addressGroups = groupRoutes[route];
      log.i("Route", route, " from group ", group, "has", addressGroups.length, "address groups");

			let groupRouteAddressLength = 0;
			addressGroups.forEach(addressGroup => {
				groupRouteAddressLength += addressGroup.length;
			});
		

			const documents = [];
			
			const promises = addressGroups.map(async sequence => {

	      log.i("Route", route, " from group ", group, "has", sequence.length, "entries");
	      groupCounter++;
	
				let routeData = {
	        name: routeName,
	        group: group,
	        route: route,
	        addresses: sequence,
	      }


				const newRouteRef = db.firestore().collection('routes').doc();
				const newDataId = newRouteRef.id
				log.i('Sending', routeData, 'into ID', newDataId);
	      return newRouteRef.set(routeData).then(a => {
	        log.i("OK!", a);
					monitor(groupCounter, totalRoutes);
				
					routeData.documentId = newRouteRef.id;
					log.i('Returning id', newDataId);
					documents.push(newDataId);
	
//	      	log.i('adding', routeData, 'to datalist');
//					parsedRoutesData.push(routeData);
	      }).catch(e => {
	        console.error(e);
	      });
			
			});

			await Promise.all(promises).then(() => {

			log.i('Adding documents', documents, 'to the newnewdata');
			myNewNewData.push({
				branchId: routeName,
				groupId: group,
				routeId: route,
				addressesLength: groupRouteAddressLength,
				documents,
			});

			});

    }
  }

  log.i("finished sending ", groupCounter," groups!");
  log.i("finished building ", myNewNewData);


//	db.firestore().collection("metadata").get().then(log.i);
  // log.i(routeData);

	await new Promise(resolve => {
		setTimeout(() => {
			monitor(0, 0, "Salvando as alterações");
			log.i('NOW IMMA SAVE STUFF BASED ON', myNewNewData);
			log.i('I also have the user data', user);

			const { id, email, name: userName } = user;

			const newCatalogData = myNewNewData.map(routeData => {
				const { branchId, groupId, routeId, addressesLength, documents } = routeData;
				return {
					senderId: id,
					senderName: userName,
					senderEmail: email,
					uploadTimestamp: Date.now(), //firebase.firestore.FieldValue.serverTimestamp(),

					branchId,
					groupId,
					routeId,
					addresses: addressesLength,
					documents,
				};
			})

			log.i('Mapped into', newCatalogData);

			const catalogRef = db.firestore().collection('routes').doc('!catalog');
			log.i('Loading data from', catalogRef);
			catalogRef.get().then(snap => {
				log.i('Snap is', snap);
				let catalogData = snap.data();

				log.i('Loaded catalog', catalogData);

				if(!catalogData) {
					log.i('No catalog found. Aborting?');
					catalogData = {};
				}

				const catalogRoutes = catalogData.entries || [];
				log.i('Read catalog', catalogRoutes);
				
				const docsToDelete = []

				newCatalogData.forEach(newData => {
					const { branchId, groupId, routeId } = newData;
					log.i('Looking for branchId', branchId, 'groupId', groupId, 'routeId', routeId);

					const oldData = catalogRoutes.find(e => e.branchId === branchId && e.groupId === groupId && e.routeId === routeId);
					
					if(!oldData) {
						log.i('No old data');
						catalogRoutes.push(newData);
					} else {
						log.i('Found old entry', oldData);
						const oldDataIndex = catalogRoutes.indexOf(oldData);
						log.i('Its on index', oldDataIndex);
						if(oldDataIndex < 0) {
							log.i('This is weird')
							return;
						} else {
							log.i('Replacing', oldData, 'with', newData);
							docsToDelete.push(...oldData.documents);
							log.i('DocsToDelete now is', docsToDelete);
							catalogRoutes[oldDataIndex] = newData;
						}

					}

				});

				//catalogData.routes = catalogRoutes;
				catalogData.entries = catalogRoutes;

				log.i('Writing', catalogData, 'into server');

				catalogRef.set(catalogData).then(() => {
					log.i('Updated catalog');
					log.i('Now Ill delete', docsToDelete);
					const promises = docsToDelete.map(docId => db.firestore().collection('routes').doc(docId).delete())
					//setTimeout(() => {
					Promise.all(promises).then(() => {
						log.i('All deleted. Moving on');
						resolve()
					});
					//}, 250);

				});

				
			});

		}, 250);
	});
	
  return;

  // log.i("Saving..");
  
  // const savingPoint = db.firestore().collection("routes").doc();
  // savingPoint.set(routeData).then(a => {
  //   log.i("OK!", a);
  // }).catch(e => {
  //   console.error(e);
  // });

  // return;
  // var requests = db.firestore().collection("routes");
  // var skippedHeader = false;
  // data.forEach( function(s) { 
  //    // ... do something with s ...
  //   log.i(s);
  //   //var array = s.split(',');
  //   if (!skippedHeader){
  //      skippedHeader=true;
  //      return;
  //   }

  //   if (s.length<7){
  //     return;
  //   }
  //   var uuid = guid();
  //   log.i(uuid);
  //   //requests.doc().set({createDate:new Date(),groupId:s[5],latitude:Number(s[8].replace(",",".")),longitude:Number(s[9].replace(",",".")),streetName:s[1],streetNumber:Number(s[2]),installationId:s[0],routeId:s[6],sequenceId:s[7]});
  //   requests.doc().set({createDate:new Date(),installationId:Number(s[0]),addressOwnerName:s[1],addressStreetName:s[2],addressNumber:Number(s[3]),addressExtra:s[4],addressNeighbourhood:s[5],groupId:s[6],routeId:s[7],sequenceId:s[8],installationCategory:s[9],installationStatus:s[10],meterId:s[11]});
  //   requests.doc().set({createDate:new Date(),
  //     installationId:Number(s[0]),
  //     addressOwnerName:s[1],
  //     addressStreetName:s[2],
  //     addressNumber:Number(s[3]),
  //     addressExtra:s[4],
  //     addressNeighbourhood:s[5],
  //     groupId:s[6],
  //     routeId:s[7],
  //     sequenceId:s[8],
  //     installationCategory:s[9],
  //     installationStatus:s[10],
  //     meterId:s[11]});
  //   log.i("data:"+s); 
  // });
  
  // log.i("wrote!");
}

export function deleteMyRoutesBitch() {
	log.i('Fetching routes');
	db.firestore().collection("routes").get().then(snap => {
//		log.i('Deleting', snap);
		snap.docs.forEach(doc => {
			log.i('Deleting', doc.id)
			db.firestore().collection("routes").doc(doc.id).delete();
//			doc.delete()
		});
	});
}

export function uploadCsvFile() {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve();
		}, 2000);
	});
}

export function commitChanges() {
}

export function populateGroupMetadata() {
  log.i('OKOKOK');

  let groupList = {};
  let groupRoutesList = {};

return new Promise((resolve, reject) => {
  db.firestore().collection("routes").get().then(snap => {

    for(let data of snap.docs) {
      log.i(data.id, data.data());

      let mList = data.data();

      let {group, route, name} = mList;

      let listId = data.id;

      log.i('Group', group, 'has route', route, '(', name, ') on id', listId);

      let groupId = group;
      groupList[groupId] = true;

      let groupRoutes = groupRoutesList[groupId] || {};
      groupRoutes[route] = listId;
      groupRoutesList[groupId] = groupRoutes;

    }

    log.i(groupList);
    log.i(groupRoutesList);


    let finalGroupsList = [];
    let finalRoutesList = {};

    for(let groupId in groupRoutesList) {
      finalGroupsList.push(groupId);

      let rList = groupRoutesList[groupId];
      let nList = [];
      for(let routeId in rList) {
        nList.push(routeId);
      }
      finalRoutesList[groupId] = nList.sort();
    }

    log.i(finalGroupsList.sort());
    log.i(finalRoutesList);

    db.firestore().collection("metadata").doc('groups').set({
      groups: finalGroupsList.sort(),
      routes: groupRoutesList,
    }).then(() => {
   	  db.firestore().collection("routes").doc('!catalog').set({
   	    data: groupRoutesList,
   	  }).then(() => {
   	    log.i('ok mofooooooo!!');
	 	  	resolve();
   	  })
    //  log.i('ok mofooooooo!!');
		//	resolve();
    })


  });
});



}



/*
export function loadReportFromServer() {
  // captureLocation,
  // captureTimestamp,
  // captureUrl,
  // databaseId,
  // routeItem,


  db.firestore().collection("captures").get().then(snap => {

    // let serverData = [
    //   'ID; Data de captura; Latitude; Longitude; Instalação; Leitura automática; URL'
    // ];
    let serverData = [];



    snap.docs.map(data => {

      let { captureAutomatedValue, captureLocation, captureTimestamp, captureUrl, databaseId, routeItem } = data.data();
      // captureAutomatedValue = captureAutomatedValue || 'Nenhuma';

      log.i('initial capturetimestamp', captureTimestamp)
      captureTimestamp = new Date(captureTimestamp).toLocaleString();

      log.i('converted capturetimestamp', captureTimestamp)
      

      if(!captureUrl) {
        return;
      }

      // if(databaseId !== '0SEx116eCJIDzg9dApyc') {
      //   return;
      // }

      log.i(captureAutomatedValue, captureLocation, captureTimestamp, captureUrl, databaseId, routeItem);
      // serverData.push(
      //   databaseId + ';' + captureTimestamp + ';'
      //    + captureLocation.latitude + ';' + captureLocation.longitude + ';'
      //    + routeItem.installationId + ';'
      //    + captureAutomatedValue + ';' + captureUrl
      // )
      serverData.push( {
        databaseId ,
        captureTimestamp ,
        captureLocation,
        routeItem,
        captureAutomatedValue ,
        captureUrl : 'https://cloud.dcod.com.br/#/captures/' + databaseId,
      });
      
    });
    return serverData;
  })
  .then(serverData => {

    let newServerData = [];

    let promises = serverData.map(imageData => {



      // log.i(imageData);
      // let imageId = imageData.split(';')[0];
      let imageId = imageData.databaseId;

      let {databaseId ,
        captureTimestamp ,
        captureLocation,
        routeItem,
        captureAutomatedValue, captureUrl} = imageData;

        log.i(databaseId ,
          captureTimestamp ,
          captureLocation,
          routeItem,
          captureAutomatedValue);


      log.i('getting url for', imageId); 
      return storage.ref('originals/' + imageId + '.jpg').getDownloadURL()
        .then(url => {
          log.i(url)
          
          // return {// newServerData.push( {
          newServerData.push( {
            databaseId ,
            captureTimestamp ,
            captureLocation,
            routeItem,
            captureAutomatedValue ,
            captureUrl,
            rawUrl: url,
          // };
          });
          
          
        }).catch(e => {
          log.i("shit", e);
        });
        // log.i(p)
        // promises.push(p);
        log.i('finished it alll')
    });

    Promise.all(promises)
    .then((res) => {
    log.i('Newserverdata', res)
    log.i(promises)
    log.i(newServerData);
    serverData = newServerData;
  //   return newServerData;
  //   });

  // }).then(serverData => {
    // log.i('And now we do our thing with ', serverData)

    // return;

      log.i('Now going to the next part');

    let textToSave = 'ID; Data de captura; Latitude; Longitude; Instalação; Leitura automática; URL; Raw URL\n';

    for(let item of serverData) {
        textToSave += item.databaseId + ';' + item.captureTimestamp + ';'
         + item.captureLocation.latitude + ';' + item.captureLocation.longitude + ';'
         + item.routeItem.installationId + ';'
         + item.captureAutomatedValue + ';' 
         + item.captureUrl + ';' + item.rawUrl + '\n';
    // for(let line of serverData) {
      // textToSave += line + '\n';
    }

    var d = new Date(); // for now

    let fileName = 'dcodReport_' + d.getFullYear() + pad(d.getMonth() + 1, 2) + pad(d.getDate(), 2)
                    + '_' + pad(d.getHours(), 2) + pad(d.getMinutes(), 2) + pad(d.getSeconds(), 2)
                    + '.csv';
        
    log.i(fileName);

    // return;
    var hiddenElement = document.createElement('a');

    hiddenElement.href = 'data:text/csv,' + encodeURI(textToSave);
    hiddenElement.target = '_blank';
    hiddenElement.download = fileName;
    hiddenElement.click();
  });
  });
  


}
*/

function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function loadAutomatedValueToServer(data) {
  log.i("Writing contsent", data);

  // return;
  // log.i("got data type",
  //  typeof data);
  // log.i("got data length", data.length);

  log.i('Uploading', data.length, 'entries');

  data.map(d => {
    let dataId = d[0];
    let dataVal = d[1];

    if(dataId && dataVal) {
      log.i(dataId, dataVal);

      db.firestore().collection('captures').doc(dataId).update({
        captureAutomatedValue: dataVal
      }).then(() => {
        log.i('OK');
      });
    }
    
  });

}

/*
export function deleteOriginalImageList(data) {

  // log.i(data);


  data.map(imageIdArray => {

    let imageId = imageIdArray[0];
    if(!imageId) {
      return;
    }
    
    // log.i(imageId);
    // return;
    
    storage.ref('originals/' + imageId).delete()
    .then(url => {
      log.i(url)
    });
  });

}
*/

export function refreshCaptureAutomatedValue() {
  
  // db.firestore().collection("captures").where('databaseId', '==', 'i3r6DDTgqD27GwxauYKz').get().then(snap => {
  db.firestore().collection("captures").where('captureTimestamp', '>', 1534785085000).get().then(snap => {

    // let serverData = [
    //   'ID; Data de captura; Latitude; Longitude; Instalação; Leitura automática; URL'
    // ];
    let serverData = [];



    let totalToDo = 2;

    // const totalDocs = snap.docs.length;
    let totalDocs = 0;

    log.i('Got', totalDocs);
    
    // if(true) return;

    let done = 0;

    snap.docs.forEach(data => {
      let { uploadTimestamp, captureTimestamp, captureAutomatedValue, captureUrl, databaseId } = data.data();


        if(uploadTimestamp > 0) {
          if(captureAutomatedValue) {
            totalDocs++;
          //   log.i(databaseId, 'uploaded and processed');
          // } else {
            // log.i(databaseId, 'uploaded');
            if(totalToDo > 0) {
              const timeToWait = 15000 * totalDocs;
              log.i('waiting', timeToWait)
              setTimeout(() => {
              log.i('doing #' + done, 'out of', totalDocs, ':', databaseId, 'after', timeToWait);
              totalToDo--;
              done++;
              db.firestore().collection('captures').doc(databaseId).update({uploadTimestamp: 0})
              .then(() => {
                db.firestore().collection('captures').doc(databaseId).update({uploadTimestamp: uploadTimestamp})
                .then(() => {
                  log.i('OMG');
                  // done++;
                })
              })
            }, timeToWait);
            }
          }
        } else {
          log.i(databaseId, 'not uploaded');
        }
    });

      
  //     // captureAutomatedValue = captureAutomatedValue || 'Nenhuma';

  //     log.i('initial capturetimestamp', captureTimestamp)
  //     captureTimestamp = new Date(captureTimestamp).toLocaleString();

  //     log.i('converted capturetimestamp', captureTimestamp)
      

  //     if(!captureUrl) {
  //       return;
  //     }

  //     // if(databaseId !== '0SEx116eCJIDzg9dApyc') {
  //     //   return;
  //     // }

  //     log.i(captureAutomatedValue, captureLocation, captureTimestamp, captureUrl, databaseId, routeItem);
  //     // serverData.push(
  //     //   databaseId + ';' + captureTimestamp + ';'
  //     //    + captureLocation.latitude + ';' + captureLocation.longitude + ';'
  //     //    + routeItem.installationId + ';'
  //     //    + captureAutomatedValue + ';' + captureUrl
  //     // )
  //     serverData.push( {
  //       databaseId ,
  //       captureTimestamp ,
  //       captureLocation,
  //       routeItem,
  //       captureAutomatedValue ,
  //       captureUrl : 'https://cloud.dcod.com.br/#/captures/' + databaseId,
  //     });
      
  //   });
  //   return serverData;
  // })
  // .then(serverData => {

  //   let newServerData = [];

  //   let promises = serverData.map(imageData => {



  //     // log.i(imageData);
  //     // let imageId = imageData.split(';')[0];
  //     let imageId = imageData.databaseId;

  //     let {databaseId ,
  //       captureTimestamp ,
  //       captureLocation,
  //       routeItem,
  //       captureAutomatedValue, captureUrl} = imageData;

  //       log.i(databaseId ,
  //         captureTimestamp ,
  //         captureLocation,
  //         routeItem,
  //         captureAutomatedValue);


  //     log.i('getting url for', imageId); 
  //     return storage.ref('originals/' + imageId + '.jpg').getDownloadURL()
  //       .then(url => {
  //         log.i(url)
          
  //         // return {// newServerData.push( {
  //         newServerData.push( {
  //           databaseId ,
  //           captureTimestamp ,
  //           captureLocation,
  //           routeItem,
  //           captureAutomatedValue ,
  //           captureUrl,
  //           rawUrl: url,
  //         // };
  //         });
          
          
  //       }).catch(e => {
  //         log.i("shit", e);
  //       });
  //       // log.i(p)
  //       // promises.push(p);
  //       log.i('finished it alll')
  //   });

  //   Promise.all(promises)
  //   .then((res) => {
  //   log.i('Newserverdata', res)
  //   log.i(promises)
  //   log.i(newServerData);
  //   serverData = newServerData;
  // //   return newServerData;
  // //   });

  // // }).then(serverData => {
  //   // log.i('And now we do our thing with ', serverData)

  //   // return;

  //     log.i('Now going to the next part');

  //   let textToSave = 'ID; Data de captura; Latitude; Longitude; Instalação; Leitura automática; URL; Raw URL\n';

  //   for(let item of serverData) {
  //       textToSave += item.databaseId + ';' + item.captureTimestamp + ';'
  //        + item.captureLocation.latitude + ';' + item.captureLocation.longitude + ';'
  //        + item.routeItem.installationId + ';'
  //        + item.captureAutomatedValue + ';' 
  //        + item.captureUrl + ';' + item.rawUrl + '\n';
  //   // for(let line of serverData) {
      // textToSave += line + '\n';
    });
  }
