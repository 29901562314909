import { db, firebase } from './Database';

import Logger from './Logger';
const log = Logger('Users');

let users = [
	{ id: 'i1', data: {name: "Pedro", email: 'pedro@example.dcod.com.br'} },
	{ id: 'i2', data: {name: "Maria", email: 'maria@example.dcod.com.br'} },
	{ id: 'i3', data: {name: "Carlos", email: 'carlos@example.dcod.com.br'} },
	{ id: 'i4', data: {name: "Fernanda", email: 'fernanda@example.dcod.com.br'} },
];

let onNewData = (callback) => {
	return db.firestore().collection('users')
		.orderBy("creationTimestamp", "desc")
		.onSnapshot(snap => {
			log.i('Got a snapshot on realtime listener');
			let mappedData = snap.docs.map(doc => {
				let { name, user, domain, role, creationTimestamp, photoUrl } = doc.data();
				return { name, user, domain, role, creationTimestamp, photoUrl };
			});
			log.i('Sending back data', mappedData);
			callback({data: mappedData, total: snap.size});
		});
}

let post = (data) => {
	log.i('creating user with data', data);
	const { password, ...userData } = data;
  return db.auth().createUserWithEmailAndPassword(userData.user, password)
    .then(user => {
			log.i('got user', user);
  		return user.user.updateProfile({
    	displayName: userData.name
  	}).then(() => {
				log.i('Update profile. returning uid');
				return Promise.resolve(user.user.uid)
			});
	}).then((id) => {
		log.i('CREated user on auth:', id);
		return db.firestore().collection('users')
			.doc(id)
			.set({role: 'user', creationTimestamp: firebase.firestore.FieldValue.serverTimestamp(), ...userData})
			.then(snap => {
				log.i('SNAP ME BABY', snap);
				return Promise.resolve();
			});
	}).catch(e => {
		log.i('failed with', e);
		return Promise.reject(e);
	});;
}

let list = () => {
	return new Promise((resolve,reject) => {
		let response = { data: users.map(u => ({id: u.id, ...u.data})), total: users.length };
		timeout().then(() => resolve(response));
	});
}

let get = (id) => {
	return new Promise((resolve,reject) => {
		let user = users.find(u => u.id === id);
		let response = { id: user.id, ...user.data};
		timeout().then(() => resolve(response));
	});
}

let put = (id, data) => {
	return new Promise((resolve,reject) => {
		timeout().then(() => {
			//let user = users.indexOf(users.find(u => u.id === id))
			let user = users.find(u => u.id === id);
			//users.splice(userIndex, 1);
			user.data = data;
			//users.push({id, ...data});
			resolve(id);
		});
	});
}

let patch = (id, data) => {
	return new Promise((resolve,reject) => {
		timeout().then(() => {
			let user = users.find(u => u.id === id);
			let userData = user.data;
			Object.keys(data).map(key => {
				userData[key] = data[key];
			})
			resolve(id);
		});
	});
}

let timeout = (base = 500, min = 0) => {
	return new Promise(resolve => {
		let delay = Math.round(base*Math.random() + min);
		setTimeout(() => {
			resolve();
		}, delay);
	});
}

export {
	list,
	get,
	post,
	put,
	patch,
	onNewData,
}
