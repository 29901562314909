import React, { useState } from 'react';
import clsx from 'clsx';

import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import mockData from './data';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
		display: 'flex',
		flexDirection: 'column',
  },
  content: {
    padding: 0,
		flexGrow: 1,
  },
  image: {
    height: 48,
    width: 48
  },
	header: {
		height: '64px',
	},
	routerLink: {
	 textDecoration: 'none',
	 color: theme.palette.common.black,// '#bbbbbb',
	},
	contentRoot: {
		padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    position: 'relative'
	},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestProducts = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [products] = useState(mockData);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
			elevation={4}
    >
      <CardHeader
				className={classes.header}
        subtitle={`${products.length} in total`}
        title="Últimas capturas"
      />
      <Divider />
      <CardContent className={classes.contentRoot}>
        <List>
          {products.map((product, i) => (
            <ListItem
              divider={i < products.length - 1}
              key={product.id}
            >
              <ListItemAvatar>
                <img
                  alt="Captura"
                  className={classes.image}
                  src={'https://comps.canstockphoto.com/water-meter-illustration_csp44040721.jpg'}
                />
              </ListItemAvatar>
              <ListItemText
                primary={product.name}
                secondary={`Recebido ${product.updatedAt.fromNow()}`}
              />
              <IconButton
                edge="end"
                size="small"
              >
                <MoreVertIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
				<Link to="/captures" className={classes.routerLink} >
       	 <Button
       	   color="primary"
       	   size="small"
       	   variant="text"
       	 >
	     	     Ver capturas <ArrowRightIcon />
       	 </Button>
				</Link>
      </CardActions>
    </Card>
  );
};

LatestProducts.propTypes = {
  className: PropTypes.string
};

export default LatestProducts;
