import React from 'react';
import { BrowserRouter, Switch, Route, Redirect, Link } from 'react-router-dom';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

import { useUser } from 'UserProvider';

function MyRoute(props) {
	const {
		access = 'public',
		...otherProps
	} = props;

	switch(access) {
		case 'publicOnly':
			return <PublicRoute {...otherProps} />
		case 'private':
			return <PrivateRoute {...otherProps} />
		default: // public
			return <Route {...otherProps} />
	}
}

function RouteManager(props) {

	const user = useUser();

	const authenticated = !!user;

	const {
		defaultSecuredPath = '/',
		defaultUnsecuredPath = '/login',
		redirect = false,
		children,
		...otherProps
	} = props;

	return(
		<BrowserRouter>
			<Switch>
				{React.Children.map(children, (Child) => React.cloneElement(Child, { authenticated, defaultSecuredPath, defaultUnsecuredPath, ...otherProps }))}
				{redirect && <Redirect to={authenticated ? defaultSecuredPath : defaultUnsecuredPath} />}
			</Switch>
		</BrowserRouter>
	);
}

export { RouteManager as Router };
export { MyRoute as Route };
export { Switch };
export { Redirect };
export { Link };
