import React, { useState } from 'react';
import { getUser, logout } from '../../modules/Auth';
import { Router, Switch, Route, Link, Redirect } from '../../modules/RoutesManager';

import MyAppBar from './components/MyAppBar';
import Drawer from './components/Drawer';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import DrawerItems from './MyDrawerItems';
import PageContent from './PageContent';
import CapturesPage from './Captures';
import UsersPage from './Users';
import RoutesPage from './Routes';

import DashboardPage from './Dashboard';

import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MapIcon from '@material-ui/icons/Map';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import UsersIcon from '@material-ui/icons/People';

import DevicesIcon from '@material-ui/icons/Smartphone';
import DashboardIcon from '@material-ui/icons/Dashboard';

import { useUser } from 'UserProvider';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
}));

function MainPage(props) {
	
	document.title = "Decode Cloud";

	const classes = useStyles();

	let user = useUser(); //props.user;

	console.log('User on MAIN is', user);

	let [ open, setOpen ] = useState(false);
	let [ mobileOpen, setMobileOpen ] = useState(false);

//	setTimeout(() => setOpen(false), 500);

	let toggleDrawer = () => {
		setOpen(!open);
	}

	let toggleMobileDrawer = () => {
		setMobileOpen(!mobileOpen);
	}
			/*
				<div label="Dispositivos" path="/devices" icon={DevicesIcon}/>
			*/


	if(user.branchId === 'admin') {
	return (
	<div className={classes.root}>
		<MyAppBar
			userData={user}
			title={"Decode"}
			onLogout={logout}
			handleDrawerToggle={toggleDrawer}
			handleMobileDrawerToggle={toggleMobileDrawer}
		/>
		<Drawer 
			open={open}
			location={props.location}
			mobileOpen={mobileOpen}
			handleDrawerToggle={toggleDrawer}
			handleMobileDrawerToggle={toggleMobileDrawer}
		>

				<div label="Dashboard" path="/dash" icon={DashboardIcon}/>

		</Drawer>
    <main className={classes.content}>
      <div className={classes.toolbar} />
			<Switch>
				<Route path="/dash" render={() => <DashboardPage />} />
				<Route path="/" render={() => <Redirect to="/dash" />} />
			</Switch>
    </main>
	</div>
	);

	}

	return (
	<div className={classes.root}>
		<MyAppBar
			userData={user}
			title={"Decode"}
			onLogout={logout}
			handleDrawerToggle={toggleDrawer}
			handleMobileDrawerToggle={toggleMobileDrawer}
		/>
		<Drawer 
			open={open}
			location={props.location}
			mobileOpen={mobileOpen}
			handleDrawerToggle={toggleDrawer}
			handleMobileDrawerToggle={toggleMobileDrawer}
		>

				<div label="Dashboard" path="/dash" icon={DashboardIcon}/>
				<div label="Capturas" path="/captures" icon={PhotoLibraryIcon}/>
				<div label="Rotas" path="/routes" icon={MapIcon}/>

		</Drawer>
    <main className={classes.content}>
      <div className={classes.toolbar} />
			<Switch>
				<Route path="/dash" render={() => <DashboardPage />} />
				<Route path="/captures" render={() => <CapturesPage />} />
				<Route path="/routes" render={() => <RoutesPage user={user} />} />
				<Route path="/" render={() => <Redirect to="/dash" />} />
			</Switch>
    </main>
	</div>
	);

}
/*
			<div label="Usuários" path="/users" icon={UsersIcon}/>
			<Route path="/users" render={() => <UsersPage />} />
				<div label="Rotas" path="/routes" icon={MapIcon}/>
				<Route path="/routes" render={() => <RoutesPage user={user} />} />
				<Route path="/users" render={() => <UsersPage user={user} />} />
				<div label="Usuários" path="/users" icon={UsersIcon}/>

*/

export default MainPage;
