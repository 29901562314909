import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

const listStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));


export default function NestedList(props) {
  const classes = listStyles();

//	const data = JSON.parse('{"1":{"A":113,"B":74,"C":101,"D":91,"E":111,"F":61,"I":93,"G":71,"H":163,"J":69,"L":40,"N":76,"M":100,"O":60,"P":24}}');
	const data = props.data;
  const groups = data;

	const openAllGroups = {}
	groups.forEach(groupId => openAllGroups[groupId] = true)

	const [open, setOpen] = React.useState(openAllGroups);

  function handleClick() {
    setOpen(!open);
  }

	let timestampToDate = (ts) => {
		var date = new Date(ts);
		// Hours part from the timestamp
		var hours = "0" + date.getHours();
		// Minutes part from the timestamp
		var minutes = "0" + date.getMinutes();
		// Seconds part from the timestamp
		var seconds = "0" + date.getSeconds();
		
		var year = date.getFullYear();
		var month = "0" + (date.getMonth() + 1);
		var day = "0" + date.getDate();

		// Will display time in 10:30:23 format
		var formattedTime = day.substr(-2) + '/' + month.substr(-2) + '/' + year + " " + hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
		return formattedTime;
	}

	return (
    <List
			className={classes.root}
			subheader={<li />}
		>
      {groups.map(({groupId, routes}) => (
        <ListItem
					divider
					disableGutters
					key={`group-${groupId}`}
					className={classes.listSection}
				>
          <List dense className={classes.ul}>
            <ListSubheader>{`Grupo ${groupId}`}</ListSubheader>
            {routes.map(({routeId, addresses, senderEmail, senderName, uploadTimestamp}) => (
              <ListItem key={`item-${groupId}-${routeId}`}>
                <ListItemText primary={`Rota ${routeId} - ${addresses} endereços - Enviado por ${senderName} (${senderEmail}) em ${timestampToDate(uploadTimestamp)}`} />
              </ListItem>
            ))}
          </List>
        </ListItem>
      ))}
    </List>
  );

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Nested List Items
        </ListSubheader>
      }
      className={classes.root}
    >

		{groups.map(groupId => (
		<>
      <ListItem button onClick={handleClick}>

        <ListItemIcon>
					{open ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>

        <ListItemText
					disableTypography
	        primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>MyTitle</Typography>}
				/>
      </ListItem>

			
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
					{Object.keys(data[groupId]).map(routeId => (
          <ListItem className={classes.nested}>
            <ListItemText
							inset
							primary="Rota A40 - 1234 endereços"
						/>
          </ListItem>
					))}
        </List>
			</Collapse>
		</>
		))}

    </List>
  );
}
