import React, { createContext, useContext, useState } from 'react';
//import Cookies from 'js-cookie';


const UserContext = createContext();


export default function UserProvider({ children, user }) {
//	const [ username, fqdn = ''] = user.email.split('@');
//	const [ branchId, clientId ] = fqdn.split('.');
/*
const token = Cookies.get('accessToken');
console.log('CHecking user from token:', token);

let user;

if(!token) {
	user = {
		photoURL: 'https://www.w3schools.com/howto/img_avatar.png',
		name: 'Admin temporário',
		email: 'readonly@admin.aegea',
		branchId: 'admin',
	};
}

console.log('user will be passed as', user);
*/
	return (
		<UserContext.Provider
			value={user}
		>
			{children}
		</UserContext.Provider>
	);
}

export const useUser = () => useContext(UserContext);

