import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

const listStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: '40vh',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));


export default function NestedList(props) {
  const classes = listStyles();

//	const data = JSON.parse('{"1":{"A":113,"B":74,"C":101,"D":91,"E":111,"F":61,"I":93,"G":71,"H":163,"J":69,"L":40,"N":76,"M":100,"O":60,"P":24}}');
	const data = props.data;
  const groups = Object.keys(data);

	const openAllGroups = {}
	groups.forEach(groupId => openAllGroups[groupId] = true)

	const [open, setOpen] = React.useState(openAllGroups);

  function handleClick() {
    setOpen(!open);
  }

	return (
    <List
			className={classes.root}
			subheader={<li />}
		>
      {groups.map(groupId => (
        <ListItem
					divider
					disableGutters
					key={`group-${groupId}`}
					className={classes.listSection}
				>
          <List dense className={classes.ul}>
            <ListSubheader>{`Grupo ${groupId}`}</ListSubheader>
            {Object.keys(data[groupId]).map(routeId => (
              <ListItem key={`item-${groupId}-${routeId}`}>
                <ListItemText
									inset
									primary={`Rota ${routeId} - ${data[groupId][routeId]} endereços`}
								/>
              </ListItem>
            ))}
          </List>
        </ListItem>
      ))}
    </List>
  );
	
	return (
    <List className={classes.root} subheader={<li />}>
      {groups.map(groupId => (
        <li key={`group-${groupId}`} className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader>{`Grupo ${groupId}`}</ListSubheader>
            {Object.keys(data[groupId]).map(routeId => (
              <ListItem key={`item-${groupId}-${routeId}`}>
                <ListItemText primary={`Rota ${routeId} - ${data[groupId][routeId]} endereços`} />
              </ListItem>
            ))}
          </ul>
        </li>
      ))}
    </List>
  );

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Nested List Items
        </ListSubheader>
      }
      className={classes.root}
    >

		{groups.map(groupId => (
		<>
      <ListItem button onClick={handleClick}>

        <ListItemIcon>
					{open ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>

        <ListItemText
					disableTypography
	        primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>MyTitle</Typography>}
				/>
      </ListItem>

			
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
					{Object.keys(data[groupId]).map(routeId => (
          <ListItem className={classes.nested}>
            <ListItemText
							inset
							primary="Rota A40 - 1234 endereços"
						/>
          </ListItem>
					))}
        </List>
			</Collapse>
		</>
		))}

    </List>
  );
}
