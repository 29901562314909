
export function parse(status, value) {
	if(status.startsWith('skipped')) {
		let skipCode = status.split('/')[1]
		let reasonToSkip = getSkipReasonText(skipCode);
		return 'Ignorado: ' + reasonToSkip;
	} else if(status.startsWith('success')) {
		if(!value) {
			return 'Processamento concluído';
		} else {
			return 'Leitura: ' + value;
		}
	} else {
		return getCaptureStatusMessage(status);
	}
}

export function getSkipReasonText(reasonId) {
	let reasonToSkip = "";
	switch(reasonId) {
		case '11':
			reasonToSkip = "cachorro"
			break;
		case '12':
			reasonToSkip = "local sem acesso"
			break;
		case '13':
			reasonToSkip = "casa não existe"
			break;
		case '14':
			reasonToSkip = "medidor removido"
			break;
		case '21':
			reasonToSkip = "DADOS CADASTRO INCORRETOS"
			break;
		case '22':
			reasonToSkip = "HIDRÔMETRO DANIFICADO"
			break;
		case '23':
			reasonToSkip = "HIDROMETRO DIFICIL ACESSO"
			break;
		case '24':
			reasonToSkip = "HIDROMETRO NAO LOCALIZ"
			break;
		case '25':
			reasonToSkip = "IMOVEL NAO LOCALZADO"
			break;
		case '26':
			reasonToSkip = "IMOVEL C/PORTAO TRANCADO"
			break;
		case '27':
			reasonToSkip = "IMOVEL C/CACHORRO BRAVO"
			break;
		case '28':
			reasonToSkip = "LIGACAO COM HD RETIRADO"
			break;
		case '29':
			reasonToSkip = "HIDROMETRO EMBAÇADO"
			break;
		case '30':
			reasonToSkip = "SUSPEITA DE FRAUDE"
			break;
		case '31':
			reasonToSkip = "CLIENTE NÃO AUTORIZOU A ENTRADA"
			break;
		default:
			reasonToSkip = "MOTIVO NÃO INFORMADO"
	}
	return reasonToSkip;
}

export function getCaptureStatusMessage(statusCode) {
	let message = '';
	switch(statusCode) {
		case 'pending/waiting-upload':
			message = 'Aguardando upload';
			break;
		case 'pending/waiting-extraction':
			message = 'Enfileirado para processamento';
			break;
		case 'success/extraction-ok':
			message = 'Processamento concluído';
			break;
		case 'failed/meter-not-found':
			message = 'Medidor não detectado';
			break;
		case 'failed/digits-could-not-be-read':
			message = 'Captura fora de padrão';
			break;
		case 'failed/unknown-error':
			message = 'Falha na análise';
			break;
		default:
	}
	return message;
}

