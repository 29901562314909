import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import Card from '../CardsPanel';
import { Select, MenuItem } from '@material-ui/core';

//import CapturesByMonth from './CapturesByMonth';
import CapturesByMonth from './DiffTable';
import CapturesDistributionByDevice from './CapturesDistributionByDevice';
import LatestCaptures from './LatestCaptures';
import DivergenceDistribution from './DivergenceDistribution';

import {
  getBranchCapturesByDate,
} from 'modules/Analytics';

import { useUser } from 'UserProvider';

import DateSelector from 'components/NewDateSelector';

const useStyles = makeStyles(theme => ({
  dashRoot: {
    padding: theme.spacing(2),
    paddingTop: 0,
  }
}));

const today = new Date();
const todaysYear = today.getFullYear();
const todaysMonth = today.getMonth() + 1;
const todaysDay = today.getDate();

const Dashboard = (props) => {
  const classes = useStyles();

	const user = useUser();
	const branches = user.branches;

	const [ loading, setLoading ] = useState(true);
	//const [ branches, setBranches ] = useState([]);
	const [ selectedBranch, setSelectedBranch ] = useState(branches[0]);

	console.log('BraNCHEs Here ARE', branches);

//	const { branchId : userBranchId } = useUser();

	const [ day, setDay ] = useState(todaysDay);
	const [ month, setMonth ] = useState(todaysMonth);
	const [ year, setYear ] = useState(todaysYear);


  return (
		<Card
			title="Comparativo entre leituras"
			titleExtra={
				<Select
					style={{marginTop: '8px', marginLeft: '8px', marginRight: '8px'}}
					disabled={branches.length <= 1}
          value={selectedBranch}
          onChange={e => setSelectedBranch(e.target.value)}
          inputProps={{
            name: 'branch',
            id: 'branch-selector',
          }}
        >
					{branches.map(branchName => (<MenuItem key={branchName} value={branchName}>{branchName}</MenuItem>) )}
        </Select>
			}

			actions={() => (
				<div style={{display: 'flex', marginBottom: '8px'}}>
				<DateSelector
					month={month}
					onMonthChange={setMonth}
					year={year}
					onYearChange={setYear}
				/>
				</div>
			)}
			aasdasdctions={() => (
				<Select
					style={{marginTop: '8px', marginLeft: '8px', marginRight: '8px'}}
					disabled={branches.length <= 1}
          value={selectedBranch}
          onChange={e => setSelectedBranch(e.target.value)}
          inputProps={{
            name: 'branch',
            id: 'branch-selector',
          }}
        >
					{branches.map(branchName => (<MenuItem key={branchName} value={branchName}>{branchName}</MenuItem>) )}
        </Select>

			)}
		>
	    <div className={classes.dashRoot}>
	      <Grid
	        container
	        spacing={2}
	      >
	        <Grid item lg={4} xs={12}>
	          <DivergenceDistribution
							branch={selectedBranch}
							month={month}
							year={year}
						/>
	        </Grid>

	        <Grid item lg={8} xs={12}>
	          <CapturesByMonth
							branch={selectedBranch}
							month={month}
							year={year}
						/>
	        </Grid>
	
	      </Grid>
	    </div>
		</Card>
  );
};

export default Dashboard;
