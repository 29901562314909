import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import DownloadApkIcon from '@material-ui/icons/PhoneAndroid';

import Tooltip from '@material-ui/core/Tooltip';

import MenuContent from './MenuContent';
import ProfileMenu from './ProfileMenu';

import Hidden from '@material-ui/core/Hidden';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.modal + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
	menuButtonContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',	
	},
  menuButton: {
    width: theme.spacing(7) + 1,
  },
	profileButton: {
    width: theme.spacing(7) + 1,
	},
	actualToolbar: {
		justifyContent: 'space-between',
		padding: 0,
	},
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
	title: {
    color: theme.palette.common.white,
		marginLeft: '12px',
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
		}
	},
}));


function MyAppBar(props) {
	const classes = useStyles();

	const {
		userData,
		onLogout,
		title,
		handleDrawerToggle,
		handleMobileDrawerToggle,
	} = props;

	return (
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar className={classes.actualToolbar}>

			<div className={classes.menuButtonContainer}>
	    <Hidden smUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleMobileDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
	    </Hidden>
	    <Hidden xsDown implementation="css">
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
	    </Hidden>

					<img style={{ height: '28px' }}  src="https://cdn.dcod.com.br/assets/logo/full_text_white.svg" />

			</div>

<div style={{display:'flex'}}>
			<div className={classes.profileButton}>
				<ProfileMenu>
					<MenuContent userData={userData} logout={onLogout} />
				</ProfileMenu>
			</div>
</div>
        </Toolbar>
      </AppBar>
	);
}

export default MyAppBar;
