import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import NoPicturesIcon from '@material-ui/icons/LocationOff';

import { makeStyles } from '@material-ui/core/styles';

import Map from './Table';
import RoutesList from './LatestRoutes';

import mockData from './LatestRoutes/data';
import Progress from 'components/ProgressSpinner';

import Placeholder from '../Placeholder';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import FilterIcon from '@material-ui/icons/FilterList';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
	Popover,
	IconButton,
	Grid,
	TextField,
} from '@material-ui/core';



export default function FilterForm(props) {

	const classes = {};

	const {
		loading,
		onSubmit,
	} = props;

	const [ searchType, setSearchType ] = useState('pct');

	const [ minDiff, setMinDiff ] = useState();
	const [ maxDiff, setMaxDiff ] = useState();


	const sendSubmit = e => {
		e.preventDefault();
		onSubmit({ searchType, minDiff, maxDiff });
	}


	return (
				<div style={{width: '360px', padding: '16px'}} >
					<form onSubmit={sendSubmit}>
					<Grid	container spacing={2}>
						<Grid container item xs={12} alignItems="center">

					<Grid item xs={4}>
        <FormLabel component="legend" labelPlacement="start">Filtrar por</FormLabel>
					</Grid>

					<Grid item xs={8}>
        <RadioGroup row aria-label="filter" name="searchType" value={searchType} onChange={e => setSearchType(e.target.value)}>
          <FormControlLabel value="pct" control={<Radio />} label="%" />
          <FormControlLabel value="abs" control={<Radio />} label="m³" />
          <FormControlLabel value="gps" control={<Radio />} label="Km" />
        </RadioGroup>
					</Grid>

      <FormControl component="fieldset" className={classes.formControl}>
      </FormControl>

						</Grid>

							<Grid item xs={6}>
								<TextField
									style={{ margin: '4px' }}
									type="number"
									value={minDiff}
									disabled={loading}
									onChange={e => setMinDiff(e.target.value)}
									label="Mínimo"
									placeholder={'0'}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									style={{ margin: '4px' }}
									type="number"
									value={maxDiff}
									disabled={loading}
									onChange={e => setMaxDiff(e.target.value)}
									label="Máximo"
									placeholder={'100'}
								/>
							</Grid>

						<Grid item xs={12}>
							<Button type="Submit" fullWidth variant="contained" color="primary">Filtrar</Button>
						</Grid>

					</Grid>
					</form>
				</div>

	);
}
