import uuid from 'uuid/v1';
import moment from 'moment';

import 'moment/locale/pt'  // without this line it didn't work
moment.locale('pt')

export default [
  {
    id: uuid(),
    name: 'Grupo 1, Rota 1, Sequencia 23',
    imageUrl: '/images/products/product_1.png',
    updatedAt: moment().subtract(2, 'hours')
  },
  {
    id: uuid(),
    name: 'Grupo 1, Rota 1, Sequencia 22',
    imageUrl: '/images/products/product_2.png',
    updatedAt: moment().subtract(2, 'hours')
  },
  {
    id: uuid(),
    name: 'Grupo 1, Rota 1, Sequencia 21',
    imageUrl: '/images/products/product_3.png',
    updatedAt: moment().subtract(3, 'hours')
  },
  {
    id: uuid(),
    name: 'Grupo 1, Rota 1, Sequencia 20',
    imageUrl: '/images/products/product_4.png',
    updatedAt: moment().subtract(5, 'hours')
  },
  {
    id: uuid(),
    name: 'Grupo 1, Rota 1, Sequencia 19',
    imageUrl: '/images/products/product_5.png',
    updatedAt: moment().subtract(9, 'hours')
  },
  {
    id: uuid(),
    name: 'Grupo 1, Rota 1, Sequencia 18',
    imageUrl: '/images/products/product_5.png',
    updatedAt: moment().subtract(9, 'hours')
  }
];
