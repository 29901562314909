import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Typography,
  Button,
	Grid,
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import RefreshIcon from '@material-ui/icons/Refresh';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

import DateSelector from '../DateSelector';
import Placeholder from '../Placeholder';

import Progress from 'components/ProgressSpinner';

import {
	getCapturesStatus,
} from 'modules/Analytics';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
		display: 'flex',
		flexDirection: 'column',
  },
	content: {
	},
	contentRoot: {
		minHeight: '428px',
		flexGrow: 1,
		padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    position: 'relative'
	},
  chartContainer: {
    position: 'relative',
    height: '240px',
  },
	header: {
		height: '64px',
  },
  stats: {
		height: '',
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UsersByDevice = props => {
  const { className, branch, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

	const [ statusData, setStatusData ] = useState({});
	const [ loading, setLoading ] = useState(true);

	const [ year, setYear ] = useState((new Date()).getFullYear());
	const [ month, setMonth ] = useState((new Date()).getMonth() + 1);

	console.log('MMMMMAAAA');

	useEffect(() => {
		if(!branch) return;
		setLoading(true);
		getCapturesStatus({branchId: branch, year, month}).then(response => {
			console.log('Finished loading capturesStatus', response, 'for branch', branch);

			let skipped = 0;
			let processed = 0;
			let failed = 0;
			let pending = 0;
			let total = 0;

			const results = response.byStatus || []

			results.forEach(({status, count}) => {
				count = +count;
				total += count;
				if(status.startsWith('skipped')) {
					skipped += count;	
				} else if (status.startsWith('failed')) {
					failed += count;
				} else if (status.startsWith('success')) {
					processed += count;
				} else if (status.startsWith('pending')) {
					pending += count;
				}
			});

			setStatusData({
				skipped,
				processed,
				failed,
				pending,
				total,
			});

			setLoading(false);

		});

	},[branch, year, month]);

	console.log('Status data is', statusData);

	const {
		skipped,
		processed,
		failed,
		pending,
		total,
	} = statusData;

	const p = +(100*processed/total).toFixed(1);
	const q = +(100*failed/total).toFixed(1);
	const u = +(100*pending/total).toFixed(1);
	const ig = +(100 - (p + q + u)).toFixed(1);

	console.log('THEMEPALL', theme.palette);

	const pColor = '#5bc0de';//theme.palette.success.dark;
	const qColor = theme.palette.error.dark
	const uColor = theme.palette.primary.light;
	const iColor = theme.palette.warning.main;

  const data = {
    datasets: [
      {
        data: [processed, failed, pending, skipped],
        backgroundColor: [
					pColor,
					qColor,
					uColor,
					iColor,
        ],
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white,
      }
    ],
    labels: ['Processamento concluído', 'Qualidade insuficiente', 'Aguardando upload', 'Endereço ignorado']
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: '#FFF',//theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

	const v = (val) => {
		if(val === 0) return 0;
		if(!val) return '-';
		return val;
	}

  const devices = [
    {
      title: 'Processado',
      value: v(p),
      icon: <LaptopMacIcon />,
      color: pColor,
    },
    {
      title: 'Qualidade insuficiente',
      value: v(q),
      icon: <TabletMacIcon />,
      color: qColor,
    },
    {
      title: 'Aguardando upload',
      value: v(u),
      icon: <TabletMacIcon />,
      color: uColor,
    },
    {
      title: 'Endereço ignorado',
      value: v(ig),
      icon: <PhoneIphoneIcon />,
      color: iColor,
    }
  ];

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
			elevation={4}
    >
      <CardHeader
				style={{height: '64px'}}
        action={
					<DateSelector
						month={month}
						year={year}
						onMonthChange={setMonth}
						onYearChange={setYear}
					/>
        }
        title="Situação das capturas"
      />
      <Divider />
      <CardContent className={classes.contentRoot}>
					{loading && <Progress />}
        {!loading && total === 0 ? 
					<Placeholder
						icon={PhotoLibraryIcon}
						text="Nenhuma captura encontrada no mês escolhido"
					/>
				:
					<>
				<div className={classes.chartContainer}>
          <Doughnut
            data={data}
            options={options}
          />
        </div>
        <Grid container className={classes.stats}>
          {devices.map(device => (
            <Grid
							item
              className={classes.device}
							xs={6}
              key={device.title}
            >
              <Typography variant="body1">{device.title}</Typography>
              <Typography
                style={{ color: device.color }}
                variant="h2"
              >
                {device.value}%
              </Typography>
            </Grid>
          ))}
        </Grid>
				</>
				}
      </CardContent>
    </Card>
  );
};

UsersByDevice.propTypes = {
  className: PropTypes.string
};

export default UsersByDevice;
