import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

let log = { i: () => {} };

const useStyles = makeStyles({
  card: {
    width: 250,
  },
	show: {
		display: 'show',
	},
	hide: {
		display: 'none',
	},
});

export default function ImgMediaCard(props) {
  const classes = useStyles();

	let {
		captureData,
		onDetailsClick,
		} = props;


	let timestampToDate = (ts) => {
		var date = new Date(ts);
		// Hours part from the timestamp
		var hours = "0" + date.getHours();
		// Minutes part from the timestamp
		var minutes = "0" + date.getMinutes();
		// Seconds part from the timestamp
		var seconds = "0" + date.getSeconds();
		
		var year = date.getFullYear();
		var month = "0" + (date.getMonth() + 1);
		var day = "0" + date.getDay();

		// Will display time in 10:30:23 format
		var formattedTime = day.substr(-2) + '/' + month.substr(-2) + '/' + year + " " + hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
		return formattedTime;
	}

	let { id, thumbnailUrl, timestamp, routeItem } = captureData;
	let { routeName, routeId, groupId, sequenceId, installationId, meterId } = routeItem;

	let dateString = timestampToDate(timestamp); //'subheader';
	let meterInfo = `Hidrômetro ${meterId ? meterId : 'não informado'}`; //'text one';
	let groupRouteSequence = `Grupo ${groupId}, Rota ${routeId}, Seq. ${sequenceId}`; //'text two';
	let routeInfo = `${routeName} - Grupo ${groupId}` 
	let installationInfo = `Sequêcia ${sequenceId} (ligação ${installationId ? installationId : 'não informada'})`; //'Title';

	let imageTitle = routeInfo;
	let subheader = dateString;
	let textOne = groupRouteSequence;
	let textTwo = meterInfo;

	let imageUrl = thumbnailUrl;//'http://rdironworks.com/wp-content/uploads/2017/12/dummy-200x200.png';

	let [ loaded, setLoaded ] = React.useState(false);

	let handleLoaded = () => {
		log.i('Loaded image');
		setTimeout(() =>
		setLoaded(true), 500);
	}
	log.i('RENDERING WITH LOADED:', loaded);

  return (
    <Card className={classes.card}>
      <CardHeader
        title={imageTitle}
        subheader={subheader}
      />
      <CardActionArea onClick={onDetailsClick}>
        <CardMedia
					className={loaded ? classes.show : classes.hide}
          component="img"
          alt="Capture thumbnail"
					height="150"
          image={imageUrl}
					onLoad={handleLoaded}
        />
				{!loaded && <div style={{height:'150px', width:'100%', backgroundColor:'#cccccc'}}>
					<CircularProgress color="primary" style={{position:'absolute', top:'75px', left:'50%', marginTop:'-20px', marginLeft:'-20px'}} size={40}/>
				</div>}

        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {textOne}
					</Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {textTwo}
					</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
