import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import { list, get, post, put } from '../../../modules/Users';

import MasterPage from './Master';
import DetailsPage from './Details';

function MasterDetailsPage(props) {
	const { match, user } = props;

	return (
		<Switch>
			<Route path={`${match.url}`} exact render={(props) => <MasterPage {...props} onList={list} user={user} />} />
		</Switch>
	);
}

export default withRouter(MasterDetailsPage);
