import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';

import { Link } from 'react-router-dom';

import { useTheme } from '@material-ui/styles';

import { Select, MenuItem } from '@material-ui/core';

import Progress from 'components/ProgressSpinner';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

//import { data, options } from './chart';

import {
  getExistingBranches,
  getBranchCapturesByDate,
} from 'modules/Analytics';

const monthMap = {
	1: 'Janeiro',
	2: 'Fevereiro',
	3: 'Março',
	4: 'Abril',
	5: 'Maio',
	6: 'Junho',
	7: 'Julho',
	8: 'Agosto',
	9: 'Setembro',
	10: 'Outubro',
	11: 'Novembro',
	12: 'Dezembro',
}

const colorMap = {
	'mirante': '#222222',
	'vila-velha': '#CC0000',
	'serra': '#CCFF33',
	'test': '#00FFFF',
}

const today = new Date();
const todaysYear = today.getFullYear();
const todaysMonth = today.getMonth() + 1;

const minYear = 2019;

const useStyles = makeStyles((theme) => ({
  root: {},
  chartContainer: {
    height: 432,
    position: 'relative',
		paddingTop: theme.spacing(2),
  },
	content: {
		flexGrow: 1,
		padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    position: 'relative'
	},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestSales = props => {
	console.log('WTFUCKCKCKCK', props);
  const { className, branch, month, year, ...rest } = props;

  const classes = useStyles();

	const { palette } = useTheme();
	console.log(palette);

	const [ branchCaptures, setBranchCaptures ] = useState({});

	const [ oyear, setYear ] = useState(todaysYear);
	const [ omonth, setMonth ] = useState(todaysMonth);

	const [ groupedCaptures, setGroupedCaptures ] = useState({});

	const [ loading, setLoading ] = useState(true);

	React.useEffect(() => {
			if(!branch) return;
			setLoading(true);
			getBranchCapturesByDate(branch, year, month).then(data => {
				console.log('Got branches response', data, 'for branch', branch);
				setBranchCaptures(data);
				setLoading(false);
				/*
				ranchCaptures => {
					branchCaptures[branch] = data;
					return {...branchCaptures};
				});
				*/
			})
	}, [branch, year, month]);

	let maxMonth = 12;

	if(todaysYear === year) {
		maxMonth = todaysMonth;
	}

	const yearsArray = [...new Array(1 + todaysYear - minYear)];
	const monthsArray = [...new Array(maxMonth)];

	const labels = new Set();
	const datasets = [branch].map(b => {
		let captures = branchCaptures || {} ;

		let { total = 0, histogram = {} } = captures;

		let data = Object.keys(histogram).map(captureDate => {


			labels.add(captureDate);
			return histogram[captureDate]
		});

		return {
			label: b,
      backgroundColor: palette.primary.main,
			data,
		};
	})

	console.log('OMGISISTSGOOD', labels, 'is the label and datasets are', datasets);

const data = {
	labels: Array.from(labels),
	datasets,
}

const olddata = {
  labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug', '7 Aug'],
  datasets: [
    {
      label: 'This year',
      backgroundColor: palette.primary.main,
      data: [18, 5, 19, 27, 29, 19, 20]
    },
    {
      label: 'Last year',
      backgroundColor: palette.neutral,
      data: [11, 20, 12, 29, 30, 25, 13]
    }
  ]
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: '#FFF',//palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    ]
  }
};



  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
			elevation={4}
    >
      <CardHeader
        title="Histórico de capturas"
      />
      <Divider />
      <CardContent className={classes.content}>
					{loading && <Progress />}
        <div className={classes.chartContainer}>
          <Bar
            data={data}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string
};

export default LatestSales;
