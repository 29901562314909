import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Typography,
  Button,
	Grid,
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import RefreshIcon from '@material-ui/icons/Refresh';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

import DateSelector from '../DateSelector';
import Placeholder from '../Placeholder';

import Progress from 'components/ProgressSpinner';

import {
	getDeviceCaptures,
} from 'modules/Analytics';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
		display: 'flex',
		flexDirection: 'column',
  },
	content: {
	},
	contentRoot: {
		//minHeight: '428px',
		flexGrow: 1,
		alignItems: 'center',
		padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    position: 'relative',
		display: 'flex',
		flexDirection: 'row',
	},
  chartContainer: {
    position: 'relative',
    height: '100%',
		flexGrow: 1,
		padding: theme.spacing(2),
  },
	header: {
		height: '64px',
  },
  stats: {
		height: '100%',
		overflowX: 'auto',
    display: 'flex',
		flexGrow: 1,
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const getColor = (step, numOfSteps) => {
    // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
    // Adam Cole, 2011-Sept-14
    // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
    var r, g, b;
    var h = step / numOfSteps;
    var i = ~~(h * 6);
    var f = h * 6 - i;
    var q = 1 - f;
    switch(i % 6){
        case 0: r = 1; g = f; b = 0; break;
        case 1: r = q; g = 1; b = 0; break;
        case 2: r = 0; g = 1; b = f; break;
        case 3: r = 0; g = q; b = 1; break;
        case 4: r = f; g = 0; b = 1; break;
        case 5: r = 1; g = 0; b = q; break;
    }
    var c = "#" + ("00" + (~ ~(r * 255)).toString(16)).slice(-2) + ("00" + (~ ~(g * 255)).toString(16)).slice(-2) + ("00" + (~ ~(b * 255)).toString(16)).slice(-2);
    return (c);
}



const UsersByDevice = props => {
  const { className, branch, ...rest } = props;

	const {
		day,
		month,
		year,
	} = props;

  const classes = useStyles();
  const theme = useTheme();

	const [ statusData, setStatusData ] = useState({});
	const [ loading, setLoading ] = useState(true);

	const [ originalstuff, setOriginalstuff ] = useState([]);

	console.log('MMMMMAAAA');

	useEffect(() => {
		if(!branch) return;
		setLoading(true);
		getDeviceCaptures({branchId: branch, year, month}).then(response => {
			console.log('Finished loading capturesStatus', response, 'for branch', branch);

			setOriginalstuff(response.map(({deviceid, total, employeename}) => ({label: deviceid, value: +total, employee: employeename})));

		/*
			let skipped = 0;
			let processed = 0;
			let failed = 0;
			let pending = 0;
			let total = 0;

			const results = response.byStatus || []

			results.forEach(({status, count}) => {
				count = +count;
				total += count;
				if(status.startsWith('skipped')) {
					skipped += count;	
				} else if (status.startsWith('failed')) {
					failed += count;
				} else if (status.startsWith('success')) {
					processed += count;
				} else if (status.startsWith('pending')) {
					pending += count;
				}
			});

			setStatusData({
				skipped,
				processed,
				failed,
				pending,
				total,
			});
*/
			setLoading(false);

		});

	},[branch, year, month]);

	console.log('Status data is', statusData);

	const {
		skipped,
		processed,
		failed,
		pending,
		total,
	} = statusData;

	const p = +(100*processed/total).toFixed(1);
	const q = +(100*failed/total).toFixed(1);
	const u = +(100*pending/total).toFixed(1);
	const ig = +(100 - (p + q + u)).toFixed(1);

	console.log('THEMEPALL', theme.palette);

	const pColor = '#5bc0de';//theme.palette.success.dark;
	const qColor = theme.palette.error.dark
	const uColor = theme.palette.primary.light;
	const iColor = theme.palette.warning.main;
/*
	const originalstuff = [
    {
			label: 'iAProcessamento concluído',
			value: 100,
		},{
			label: 'iAQualidade insuficiente',
			value: 800,
		},{
			label: 'i22AAguardando upload',
			value: 420,
		},{
			label: 'i21AAguardando upload',
			value: 4440,
		},{
			label: 'i12AAguardando upload',
			value: 430,
		},{
			label: 'i1AAguardando upload',
			value: 100,
		},{
			label: 'i2AEndereço ignorado',
			value: 20,
		},
	]
*/
  const stuff = originalstuff
		//.sort((a, b) => b.value - a.value)
		.map((d, index) => { d.color = getColor(index, originalstuff.length); return d });

  const data = {
    datasets: [
      {
        data: stuff.map(d => d.value),//[processed, failed, pending, skipped],
        backgroundColor: stuff.map(d => d.color),/*[
					pColor,
					qColor,
					uColor,
					iColor,
        ],*/
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white,
      }
    ],
    labels: stuff.map(d => d.label),//['Processamento concluído', 'Qualidade insuficiente', 'Aguardando upload', 'Endereço ignorado']
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: '#FFF',//theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

	const v = (val) => {
		if(val === 0) return 0;
		if(!val) return '-';
		return val;
	}

	const totalVal = stuff.reduce((acc, item) => {
		return acc + item.value;
	}, 0);

	console.log('KAOKAOKAOKAOKAOK', totalVal);

	const devices = stuff.map(({ label: title, value, color}) => ({ title, value: (100*value/totalVal).toFixed(2), color }))

  const olddevices = [
    {
      title: 'Processado',
      value: v(p),
      icon: <LaptopMacIcon />,
      color: pColor,
    },
    {
      title: 'Qualidade insuficiente',
      value: v(q),
      icon: <TabletMacIcon />,
      color: qColor,
    },
    {
      title: 'Aguardando upload',
      value: v(u),
      icon: <TabletMacIcon />,
      color: uColor,
    },
    {
      title: 'Aguardando upload',
      value: v(u),
      icon: <TabletMacIcon />,
      color: uColor,
    },
    {
      title: 'Aguardando upload',
      value: v(u),
      icon: <TabletMacIcon />,
      color: uColor,
    },
    {
      title: 'Endereço ignorado',
      value: v(ig),
      icon: <PhoneIphoneIcon />,
      color: iColor,
    }
  ];



  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
			elevation={4}
    >
      <CardHeader
        title="Capturas por dispositivo"
      />
      <Divider />



      <CardContent className={classes.contentRoot}>
					{loading && <Progress />}
        {!loading && total === 0 ? 
					<Placeholder
						icon={PhotoLibraryIcon}
						text="Nenhuma captura encontrada no mês escolhido"
					/>
				:
					<Grid
						container
						style={{height: '428px'}}
					>

					<Grid
						item
						xs={8}
						style={{height: '100%'}}
					>
				<div className={classes.chartContainer}>
          <Doughnut
            data={data}
            options={options}
          />
        </div>
					</Grid>

        <Grid item xs={4} container className={classes.stats}>
          {devices.map(device => (
            <Grid
							item
              className={classes.device}
							xs={12}
              key={device.title}
            >
              <Typography variant="body1">{device.title}</Typography>
              <Typography
                style={{ color: device.color }}
                variant="h2"
              >
                {device.value}%
              </Typography>
            </Grid>
          ))}
        </Grid>
				</Grid>
				}
      </CardContent>



    </Card>
  );






};

UsersByDevice.propTypes = {
  className: PropTypes.string
};

export default UsersByDevice;
