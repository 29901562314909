import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import TotalLifetimeCaptures from './LifetimeCaptures';
import TotalRoutes from './Routes';
import TotalBranches from './Branches';
import TotalDevices from './Devices';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { getGlobalNumbers } from 'modules/Analytics';

const useStyles = makeStyles(theme => ({
  dashRoot: {
    padding: theme.spacing(2),
    paddingTop: 0,
  }
}));

const GlobalDashboard = (props) => {
  const classes = useStyles();

	const { user } = props;

  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down('xs'));

	const direction = responsive ? 'column-reverse' : 'row';

	const [ loading, setLoading ] = useState(true);

	const [ data, setData ] = useState({});

	console.log('USER ON METRICS CARD IS', user);

	useEffect(() => {
		console.log('Loading metrics data');
		setLoading(true);
		getGlobalNumbers({ branchId: user.branchId }).then(data => {
			console.log('Loaded globals:', data);
			const { captures, devices, branches, routes, users } = data;

			const { total : totalCaptures, thisMonth : capturesThisMonth } = captures;
			const { total : totalDevices, thisMonth : devicesThisMonth } = users;
			const { total : totalRoutes, thisMonth : routesThisMonth } = routes;
			const { total : totalBranches, latest : latestBranch } = branches;
			
			setData({
				totalBranches,
				latestBranch,
				totalDevices,
				devicesThisMonth,
				totalRoutes,
				routesThisMonth,
				totalCaptures,
				capturesThisMonth
			});

			setLoading(false);
		}).catch(err => {
			setData()
		});
		/*
		setTimeout(() => {
		}, 2000);
		*/
	}, [user]);

	const {
		totalBranches,
		latestBranch,

		totalDevices,
		devicesThisMonth,

		totalRoutes,
		routesThisMonth,

		totalCaptures,
		capturesThisMonth,
	} = data;

  return (
	      <Grid
	        container
					direction={direction}
	        spacing={2}
	      >
	
	        <Grid item lg={3} sm={6} xl={3} xs={12}>
	          <TotalBranches
							total={totalBranches}
							latest={latestBranch}
							loading={loading}
						/>
	        </Grid>
	
	        <Grid item lg={3} sm={6} xl={3} xs={12}>
	          <TotalDevices
							total={totalDevices}
							thisMonth={devicesThisMonth}
							loading={loading}
						/>
	        </Grid>
	
	        <Grid item lg={3} sm={6} xl={3} xs={12}>
	          <TotalRoutes
							total={totalRoutes}
							thisMonth={routesThisMonth}
							loading={loading}
						/>
	        </Grid>
	
	        <Grid item lg={3} sm={6} xl={3} xs={12}>
	          <TotalLifetimeCaptures
							total={totalCaptures}
							thisMonth={capturesThisMonth}
							loading={loading}
						/>
	        </Grid>
	
	      </Grid>
  );
};

export default GlobalDashboard;
