import React, { useState } from 'react';

import Card from './CardsPanel';

import RoutesMap from './RoutesMap';

import { Grid } from '@material-ui/core';
import { Select, MenuItem } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import {
  getExistingDevices,
  getBranchCapturesByDate,
} from 'modules/Analytics';

const useStyles = makeStyles(theme => ({
  dashRoot: {
    padding: theme.spacing(2),
    paddingTop: 0,
  }
}));

export default function Maps(props) {
	
  const classes = useStyles();

	const {
		branch
	} = props;

	const {
		day,
		month,
		year,
	} = props;

	const [ branches, setBranches ] = useState([]);
	const [ selectedBranch, setSelectedBranch ] = useState('');

	React.useEffect(() => {
		if(!branch) return;
		
		getExistingDevices({ branchId: branch }).then(data => {
			let branches = data || [];
			branches = branches.map(d => d.deviceid);
			console.log('Got devices response', data);
			setBranches(branches);
			if(branches.length > 0) {
				setSelectedBranch(branches[0]);
			}
		});
		
	}, [branch]);

	return (
	<Card
		title="Detalhe por dispositivo"
	    className={classes.dashRoot}
			actions={() => (
				<Select
					style={{marginTop: '8px', marginLeft: '8px', marginRight: '8px'}}
					disabled={branches.length <= 1}
          value={selectedBranch}
          onChange={e => setSelectedBranch(e.target.value)}
          inputProps={{
            name: 'device',
            id: 'device-selector',
          }}
        >
					{branches.map(branchName => (<MenuItem key={branchName} value={branchName}>{branchName}</MenuItem>) )}
        </Select>

			)}
	>
	    <div className={classes.dashRoot}>
	      <Grid
	        container
	        spacing={2}
	      >
	
	        <Grid item xl={6} xs={12}>
			<RoutesMap 
				branch={branch}
				deviceId={selectedBranch}
				day={day}
				month={month}
				year={year}
			/>
	        </Grid>


	      </Grid>
	    </div>
	</Card>
	);
}
