import React, { useState } from 'react';

import Card from './CardsPanel';

import RoutesMap from './RoutesMap';
import RoutesByMonth from './RoutesByMonth';

import { Grid } from '@material-ui/core';
import { Select, MenuItem } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import {
  getBranchCapturesByDate,
} from 'modules/Analytics';

import { useUser } from 'UserProvider';

const useStyles = makeStyles(theme => ({
  dashRoot: {
    padding: theme.spacing(2),
    paddingTop: 0,
  }
}));

export default function Maps() {
	
  const classes = useStyles();

	const { branches } = useUser();

//	const [ branches, setBranches ] = useState([]);
	const [ selectedBranch, setSelectedBranch ] = useState(branches[0]);

	return (
	<Card
		title="Requisições realizadas"
	    className={classes.dashRoot}
			actions={() => (
				<Select
					style={{marginTop: '8px', marginLeft: '8px', marginRight: '8px'}}
					disabled={branches.length <= 1}
          value={selectedBranch}
          onChange={e => setSelectedBranch(e.target.value)}
          inputProps={{
            name: 'branch',
            id: 'branch-selector',
          }}
        >
					{branches.map(branchName => (<MenuItem key={branchName} value={branchName}>{branchName}</MenuItem>) )}
        </Select>

			)}
	>
	    <div className={classes.dashRoot}>
	      <Grid
	        container
	        spacing={2}
	      >
	
	        <Grid item xl={6} xs={12}>
			<RoutesByMonth 
				branch={selectedBranch}
			/>
	        </Grid>
	
	        <Grid item xl={6} xs={12}>
			<RoutesMap 
				branch={selectedBranch}
			/>
	        </Grid>


	      </Grid>
	    </div>
	</Card>
	);
}
