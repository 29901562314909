import withRoot from './modules/Bootstrap';
// --- Post bootstrap -----
import React, { useState, useEffect } from 'react';
import { Router, Switch, Route } from './modules/RoutesManager';

import StandByPage from './pages/StandBy';
//import LoginPage from './pages/Login';
//import RecoveryPasswordPage from './pages/RecoveryPassword';
//import MainPage from './pages/Main/Dashboard';
import MainPage from './pages/Main';

import UsersPage from './pages/Main/Users';

import DashboardPage from './pages/Main/Dashboard';

import {
	onUserChange
//	onMockUserChange as onUserChange
} from './modules/Auth';

import UserProvider from 'UserProvider';

import Cookies from 'js-cookie';

//const authServer = "192.168.15.19:3001";
const authServer = "https://auth.dcod.com.br";


function App(props) {
	
			/*
	useEffect(() => {
		const token = Cookies.get('accessToken');
		console.log('CHecking user from token:', token);
		if(!token) {
			const cookieUser = {
				photoURL: 'https://www.w3schools.com/howto/img_avatar.png',
				name: 'Admin temporário',
				email: 'readonly@admin.aegea',
				branchId: 'admin',
			};
			setUser(cookieUser)
		setServicesReady(true);
		} else {
			onAuthChange((user) => {
				setUser(user)
				setServicesReady(true);
			});
		//}
	}, []);

			*/
	/*
	let [ servicesReady, setServicesReady ] = useState(true);

	useEffect(() => {
		return
		onAuthChange((user) => {
			setUser(user)
			setServicesReady(true);
		});
	}, []);
	*/


	/*

			<Route access="publicOnly" path="/login" component={LoginPage} />
			<Route access="publicOnly" path="/login" component={UsersPage} />
			<Route access="publicOnly" path="/login" component={CapturesPage} />
			<Route access="publicOnly" path="/login" component={LoginPage} />
			<Route access="publicOnly" path="/recover" component={RecoveryPasswordPage} />
			<Redirect to='https://google.com' />
			<Route access="private" path="/" render={(props) => <MainPage {...props} user={user} />} />
	*/

	let [ user, setUser ] = useState({});
	let [ servicesReady, setServicesReady ] = useState(false);


	useEffect(() => {
		console.log('Setting user listener');
		onUserChange((user) => {
			setUser(user)
			setServicesReady(true);
		});
	}, []);


	if(!servicesReady) {
		return <StandByPage />
	}

	return (
	<UserProvider user={user}>
		<Router	defaultExternalUnsecuredPath={authServer} >
			<Route access="private" path="/" component={MainPage} />
		</Router>
	</UserProvider>
	);
}

export default withRoot(App);
