import React from 'react';
import logoImage from './imageOnlyLogo.svg';
import { makeStyles } from '@material-ui/core/styles';

import './Logo.css';

const useStyles = makeStyles(theme => ({
  logo: {
		width: '100%',
    justifyContent: 'center',
	  margin: 0,
	  position: 'absolute',
	  top: '50%',
	  MsTransform: 'translateY(-50%)',
	  transform: 'translateY(-50%)',
  },
	navLogo: {
    display: 'flex',
    justifyContent: 'center',
		width: '100%',
	},
}));


function Logo(props) {
	const classes = useStyles();
	return (
			<img alt="logo" className={`${classes.navLogo} App-logo`} src={logoImage} />
	);
}

export default Logo;
