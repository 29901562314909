import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const buttonStyles = makeStyles(theme => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function MyButton(props) {

	const classes = buttonStyles();
	const { loading, children, ...otherProps } = props;

	return (
          <Button
						{...otherProps}	
          >
            {children}
						{loading && <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />}
          </Button>
	);
}

export default MyButton;
