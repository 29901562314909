import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
	spinnerContainer: {
		position: 'absolute', height: '100%', width: '100%'
	},
	progressBackdrop: {
		// height hack from https://stackoverflow.com/questions/18665171/how-to-make-the-main-content-div-fill-height-of-screen-with-css
		width: '100%',
		position: 'absolute',
		top: 0, /* Header Height */
		bottom: 0,
		zIndex: theme.zIndex.appBar,
		backgroundColor: '#FFF', ///theme.palette.primary.main,
		//backgroundColor: theme.palette.primary.main,
		opacity: 0.8,
	},
	spinner: {
		position:'absolute',
		top:'50%',
		left:'50%',
		marginTop:'-20px',
		marginLeft:'-20px',
		//color: '#FFF',
		color: theme.palette.primary.main,
	}
}));

export default function ProgressSpinner(props) {
	const classes = styles();


	return (
		<div className={classes.spinnerContainer}>
			<div className={classes.progressBackdrop}>
				<CircularProgress
					size={40}
					className={classes.spinner}

				/>
			</div>
		</div>
	);
}
