import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

export default class MyTable extends React.Component {



	render() {

		return (
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={3}
                  count={this.props.totalRows}
                  rowsPerPage={this.props.rowsPerPage}
                  page={this.props.page}
                  onChangePage={this.props.handleChangePage}
                  onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                  
                />

              </TableRow>
            </TableFooter>
		);
	}

	

	showMe(clickedItem) {
		this.props.onRowClick(clickedItem);
	}


}

// class TablePaginationActions extends React.Component {
//   handleFirstPageButtonClick = event => {
//     this.props.onChangePage(event, 0);
//   };

//   handleBackButtonClick = event => {
//     this.props.onChangePage(event, this.props.page - 1);
//   };

//   handleNextButtonClick = event => {
//     this.props.onChangePage(event, this.props.page + 1);
//   };

//   handleLastPageButtonClick = event => {
//     this.props.onChangePage(
//       event,
//       Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
//     );
//   };

//   render() {
//     const { classes, count, page, rowsPerPage, theme } = this.props;

//     return (
//       <div >
//         <IconButton
//           onClick={this.handleFirstPageButtonClick}
//           disabled={page === 0}
//           aria-label="First Page"
//         >
//           <FirstPageIcon />
//         </IconButton>
//         <IconButton
//           onClick={this.handleBackButtonClick}
//           disabled={page === 0}
//           aria-label="Previous Page"
//         >
//           <KeyboardArrowLeft />
//         </IconButton>
//         <IconButton
//           onClick={this.handleNextButtonClick}
//           disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//           aria-label="Next Page"
//         >
//           <KeyboardArrowRight />
//         </IconButton>
//         <IconButton
//           onClick={this.handleLastPageButtonClick}
//           disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//           aria-label="Last Page"
//         >
//           <LastPageIcon />
//         </IconButton>
//       </div>
//     );
//   }
// }
