import React from 'react';

import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import GridMaterial from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { withStyles } from '@material-ui/core/styles'

import { getDataById, fetchLogData } from './dataservice';

const styles = theme => ({
	pageroot: {
		margin: '16px',
	},
	tabItemRoot: {
	},
});

function Details(props) {
	
	const {
		classes,
		match,
	} = props;

	const itemId = match.params.id;

	let [ data, setData ] = React.useState();
	let [ loading, setLoading ] = React.useState(true);

	React.useEffect(() => {
		getDataById(itemId).then(setData);
	}, [itemId]);

	return (
		<div>
			<h1>DETAILS FOR {itemId}</h1>
			{data && <p>{JSON.stringify(data)}</p>}
		</div>
	);

}
export default withStyles(styles)(Details);
