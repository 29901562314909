import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import PersonAddIcon from '@material-ui/icons/ArrowBack';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import BrokenImage from './brokenImage.svg';

import Fab from '@material-ui/core/Fab';

import CircularProgress from '@material-ui/core/CircularProgress';

import { lighten } from '@material-ui/core/styles/colorManipulator';

import { getSkipReasonText, getCaptureStatusMessage } from './Parser';
import { parse } from './Parser';

import Logger from '../../../modules/Logger';
const log = Logger('ImagePanel');

//class EnhancedTableHead extends React.Component {
//	createSortHandler = property => event => {
//		this.props.onRequestSort(event, property);
//	};
//
//	render() {
//		const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;
//
//		const {
//			rows,
//			showSelection = false, 
//		} = this.props;
//
//		return (
//			<TableHead>
//				<TableRow>
//					{rows.map(
//						(row, index) => (
//							<TableCell
//								key={row.id}
//								align={row.numeric ? 'right' : 'left'}
//								padding={showSelection && index === 0 ? 'none' : 'default'}
//								sortDirection={orderBy === row.id ? order : false}
//							>
//
//								{!row.sortable 
//								?
//									row.label
//									
//							:
//								(<Tooltip
//									title="Sort"
//									placement={row.numeric ? 'bottom-end' : 'bottom-start'}
//									enterDelay={300}
//								>
//									<TableSortLabel
//										active={orderBy === row.id}
//										direction={order}
//										onClick={this.props.onSort(row.id, order === 'desc' ? 'asc' : 'desc')}
//									>
//										{row.label}
//									</TableSortLabel>
//								</Tooltip>)
//								}
//							</TableCell>
//						),
//						this,
//					)}
//				</TableRow>
//			</TableHead>
//		);
//	}
//}
//
//EnhancedTableHead.propTypes = {
//	numSelected: PropTypes.number.isRequired,
//	onRequestSort: PropTypes.func.isRequired,
//	onSelectAllClick: PropTypes.func.isRequired,
//	order: PropTypes.string.isRequired,
//	orderBy: PropTypes.string.isRequired,
//	rowCount: PropTypes.number.isRequired,
//};

const toolbarStyles = theme => ({
	root: {
		paddingRight: theme.spacing.unit,
	},
	highlight:
		theme.palette.type === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
				}
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
				},
	spacer: {
		flex: '1 1 100%',
	},
	actions: {
		color: theme.palette.text.secondary,
		margin: '16px',
	},
	title: {
		display: 'flex',
		flexDirection: 'row',
		flex: '0 0 auto',
		alignItems: 'center',
		marginTop: '8px',
	},
	fab: {
		marginRight: '16px',
	}
});

let EnhancedTableToolbar = props => {
	const {
		classes,
		title,
		actions: Actions,
		goBack,
	} = props;
	
	let handleReturn = () => {
		goBack()
	}
		

	return (
		<Toolbar
			className={classes.root}
		>
			<div className={classes.title}>
      	<Fab size="small" variant="contained" color="inherit" aria-label="Retornar" className={classes.fab} onClick={handleReturn}>
					<PersonAddIcon />
	      </Fab>
				<Typography variant="h6" id="tableTitle">
					{props.title || 'Title props'}
				</Typography>
			</div>
			<div className={classes.spacer} />
			<div className={classes.actions}>
				{Actions && <Actions />}
			</div>
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
	root: {
	},
	cardsHolder: {
		padding: '8px',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	smallTextField: {
    margin: theme.spacing(1),
		width: '142px',
	},
	textField: {
    margin: theme.spacing(1),
		minWidth: '300px',
	},
	largeTextField: {
    margin: theme.spacing(1),
		width: '616px',
	},
	table: {
	},
	tableWrapper: {
		overflowX: 'auto',
	},
});

//class EnhancedTable extends React.Component {
function EnhancedTable(props) {// extends React.Component {
/*
	state = {
		loaded: false,
	}
*/
//	render() {
	const { classes, children, title, captureUrl, data } = props;
//	const { data = [], order, orderBy, selected = [], rowsPerPage, page } = props;

	let [ url, setUrl ] = useState();
	let [ loading, setLoading ] = useState(true);

	let handleOnLoad = () => {
		setLoading(false);
	}

	useEffect(() => {
		if(!!data) {
			setUrl(data.captureUrl);
		}
	}, [data])

	let handleOnError = () => {
		setUrl(BrokenImage);
		setLoading(false);
	}
	
	
	let routeData = data || {};
	let { groupId, routeId, sequenceId, installationId, meterId, comment, latitude, longitude } = routeData;

	let parsedStatus = '';
	let details = '';
	if(!!data) {
		let { status, value } = data;
		if(status.startsWith('skipped')) {
			parsedStatus = 'Endereço ignorado';
			let skipCode = status.split('/')[1]
			details = getSkipReasonText(skipCode);
		} else if(status.startsWith('success')) {
			parsedStatus = 'Processamento concluído';
			details = 'Leitura: ' + value;
		} else {
			parsedStatus = getCaptureStatusMessage(status);
		}
	}	

	log.i('Loading URL', url);

	return (
		<Paper className={classes.root} elevation={10}>
			<EnhancedTableToolbar
				title={title}
				actions={props.actions}
				goBack={props.goBack}
			/>
			<div>
				<div style={{width: '100%', display: 'flex', flexDirection: 'column', marginTop: '8px', paddingBottom: '18px', justifyContent: 'center', alignItems: 'center'}}>
					<div style={{width: '100%', display: 'flex', marginTop: '8px', justifyContent: 'center'}}>
						{groupId && <TextField
							className={classes.smallTextField}
							disabled
							label="Grupo"
							value={groupId}
							/>}
						{routeId && <TextField
							className={classes.smallTextField}
							disabled
							label="Rota"
							value={routeId}
							/>}
						{sequenceId && <TextField
							className={classes.smallTextField}
							disabled
							label="Sequência"
							value={sequenceId}
							/>}
						{installationId && <TextField
							className={classes.smallTextField}
							disabled
							label="Instalação"
							value={installationId}
							/>}
					</div>

					<div style={{width: '100%', display: 'flex', marginTop: '8px', justifyContent: 'center'}}>
						{latitude && <TextField
							className={classes.textField}
							disabled
							label="Latitude"
							value={latitude}
							/>}
						{longitude && <TextField
							className={classes.textField}
							disabled
							label="Longitude"
							value={longitude}
							/>}
					</div>

					<div style={{width: '100%', display: 'flex', marginTop: '8px', justifyContent: 'center'}}>
						{parsedStatus && <TextField
							className={classes.textField}
							disabled
							label="Status"
							value={parsedStatus}
							/>}
						{details && <TextField
							className={classes.textField}
							disabled
							label="Detalhes"
							value={details}
							/>}
					</div>
					<div style={{width: '100%', display: 'flex', marginTop: '8px', justifyContent: 'center'}}>
						{comment && <TextField
							className={classes.largeTextField}
							disabled
							label="Comentário"
							value={comment}
							/>}
					</div>
					<div
						style={{maxWidth: '720px', height: !loading ? 'auto' : '360px' , position: 'relative'}} 
					>
						<img
							style={{width: '100%'}}
							src={url}
							onLoad={handleOnLoad}
							onError={handleOnError}

						/>
						{loading &&
							<CircularProgress color="primary" style={{position:'absolute', top:'50%', left:'50%', marginTop:'-20px', marginLeft:'-20px'}} size={40}/>
						}
					</div>
				</div>
			</div>
		</Paper>
	);
}


//const myRows = [
//	{ id: 'name', sortable: true, numeric: false, label: 'Dessert (100g serving)' },
//	{ id: 'calories', sortable: true, numeric: true, label: 'Calories' },
//	{ id: 'fat', numeric: true, label: 'Fat (g)' },
//	{ id: 'carbs', numeric: true, label: 'Carbs (g)' },
//	{ id: 'protein', numeric: true, label: 'Protein (g)' },
//];
//
//let myData = [
//	createData('Cupcake', 305, 3.7, 67, 4.3),
//	createData('Donut', 452, 25.0, 51, 4.9),
//	createData('Eclair', 262, 16.0, 24, 6.0),
//	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//	createData('Gingerbread', 356, 16.0, 49, 3.9),
//	createData('Honeycomb', 408, 3.2, 87, 6.5),
//	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//	createData('Jelly Bean', 375, 0.0, 94, 0.0),
//	createData('KitKat', 518, 26.0, 65, 7.0),
//	createData('Lollipop', 392, 0.2, 98, 0.0),
//	createData('Marshmallow', 318, 0, 81, 2.0),
//	createData('Nougat', 360, 19.0, 9, 37.0),
//	createData('Oreo', 437, 18.0, 63, 4.0),
//]
//
//function fetchData(idToOrderBy, order, pageNumber, rowsPerPage) {
//	let tableData = [...myData];
//	if(idToOrderBy) {
//		tableData = stableSort(tableData, getSorting(order, idToOrderBy));
//	}
//
//	tableData = tableData.slice(pageNumber * rowsPerPage, pageNumber * rowsPerPage + rowsPerPage)
//
//	return new Promise(resolve => {
//		setTimeout(() => {
//			resolve(tableData);
//		}, 500);
//	});
//
//}
//
//function MTable(props) {
//
//
//	let [ pageNumber , setPageNumber ] = useState(0);
//	let [ rowsPerPage, setRowsPerPage ] = useState(10);
//
//	let [ order, setOrder ] = useState('asc');
//	let [ idToOrderBy, setIdToOrderBy ] = useState('');
//
//	let [ loading, setLoading ] = useState(true);
//
//	let [ tableData, setTableData ] = useState([]);
//
//	let handleChangePage = (event, page) => {
//		setPageNumber(page);
//	};
//
//	let handleChangeRowsPerPage = event => {
//		setRowsPerPage(event.target.value);
//	};
//	
//	React.useEffect(() => {
//		setLoading(true);
//		fetchData(idToOrderBy, order, pageNumber, rowsPerPage).then(data => {
//			setTableData(data);
//			setLoading(false);
//		});
//	}, [idToOrderBy, order, pageNumber, rowsPerPage]);
//
//	return (
//		<EnhancedTable {...props} 
//			onRowClick={(id) => log.i('clicked on row', id)}
//			canSelect={false}
//			title="My Table"
//			rows={myRows}
//			data={tableData}
//			orderBy={idToOrderBy}
//			order={order}
//			onSort={(id, order) => e => {
//				setOrder(order);
//				setIdToOrderBy(id);
//			}}
//			onChangePage={handleChangePage}
//			onChangeRowsPerPage={handleChangeRowsPerPage}
//			rowsPerPageOptions={[5,10,25,50]}
//			rowsCount={myData.length}
//			rowsPerPage={rowsPerPage}
//			pageNumber={pageNumber}
//			loading={loading}
//		/>
//	);
//}
//
//function MTableHeader(props) {
//}
//
//function MTableHeaderOption(props) {
//}
//
//function MTableEntry(props) {
//}


export default withStyles(styles)(EnhancedTable);
