import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Typography,
  Button
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import RefreshIcon from '@material-ui/icons/Refresh';
import TabletMacIcon from '@material-ui/icons/TabletMac';

import Progress from 'components/ProgressSpinner';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
		display: 'flex',
		flexDirection: 'column',
  },
	content: {
		flexGrow: 1,
		padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    position: 'relative'
	},
  chartContainer: {
    position: 'relative',
    height: '300px',
  },
	header: {
		height: '64px',
  },
  stats: {
		height: '',
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UsersByDevice = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [
      {
        data: [63, 15, 22],
        backgroundColor: [
          theme.palette.success.main,
          theme.palette.warning.main,
          theme.palette.error.main,
        ],
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white,
      }
    ],
    labels: ['Menor que 1%', 'Entre 1% e 10%', 'Maior que 10%']
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: '#FFF',//theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  const devices = [
    {
      title: 'Abaixo de 1%',
      value: '63',
      icon: <LaptopMacIcon />,
      color: theme.palette.success.main
    },
    {
      title: 'Entre 1% e 10%',
      value: '15',
      icon: <TabletMacIcon />,
      color: theme.palette.warning.main
    },
    {
      title: 'Acima de 10%',
      value: '23',
      icon: <PhoneIphoneIcon />,
      color: theme.palette.error.main
    }
  ];

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
			elevation={4}
    >
      <CardHeader
				style={{height: '64px'}}
        action={
          <IconButton
						disabled
					>
            <RefreshIcon />
          </IconButton>
        }
        title="Divergências de leitura"
      />
      <Divider />
      <CardContent className={classes.content}>
					<Progress />
        <div className={classes.chartContainer}>
          <Doughnut
            data={data}
            options={options}
          />
        </div>
        <div className={classes.stats}>
          {devices.map(device => (
            <div
              className={classes.device}
              key={device.title}
            >
              <Typography variant="body1">{device.title}</Typography>
              <Typography
                style={{ color: device.color }}
                variant="h2"
              >
                {device.value}%
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

UsersByDevice.propTypes = {
  className: PropTypes.string
};

export default UsersByDevice;
