import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import Card from '../CardsPanel';

import CapturesByYear from './CapturesByYear';
import CapturesDistributionPerBranch from './CapturesDistributionPerBranch';

import DivergenceDistribution from './DivergenceDistribution';

import MetricCards from './MetricCards';

import { useUser } from 'UserProvider';

const useStyles = makeStyles(theme => ({
  dashRoot: {
    padding: theme.spacing(2),
    paddingTop: 0,
  }
}));

const GlobalDashboard = (props) => {
  const classes = useStyles();

	const {
		branches = [],
	} = props

	const user = useUser();

	console.log('USER IN GLOBALS IS', user, 'isOnAdminBranch?', user.branchId === 'admin');

  return (
		<Card
			title="Visão geral"
		>
	    <div className={classes.dashRoot}>
	      <Grid
	        container
	        spacing={2}
	      >
	
	        <Grid item xs={12}>
	          <MetricCards user={user} />
	        </Grid>
	
	        <Grid item lg={8} md={12} xl={12} xs={12}>
	          <CapturesByYear branches={branches}/>
	        </Grid>
	
	        <Grid item lg={4} md={12} xl={12} xs={12}>
	          <DivergenceDistribution />
	        </Grid>

	      </Grid>
	    </div>
		</Card>
  );
};

export default GlobalDashboard;
