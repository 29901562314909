import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import NoPicturesIcon from '@material-ui/icons/LocationOff';

import { makeStyles } from '@material-ui/core/styles';

import Map from './Table';
import RoutesList from './LatestRoutes';

import mockData from './LatestRoutes/data';
import Progress from 'components/ProgressSpinner';

import Placeholder from '../Placeholder';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import FilterIcon from '@material-ui/icons/FilterList';


import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import FilterForm from './FilterForm';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
	Popover,
	IconButton,
	Grid,
	TextField,
} from '@material-ui/core';

import { Select, MenuItem } from '@material-ui/core';

import {
  getExistingBranches,
  getBranchCapturesByDate,
	listDailyRoutes,
	getRequestCaptures,
	listCaptures,
} from 'modules/Analytics';

import moment from 'moment';

const monthMap = {
	1: 'Janeiro',
	2: 'Fevereiro',
	3: 'Março',
	4: 'Abril',
	5: 'Maio',
	6: 'Junho',
	7: 'Julho',
	8: 'Agosto',
	9: 'Setembro',
	10: 'Outubro',
	11: 'Novembro',
	12: 'Dezembro',
}

const colorMap = {
	'mirante': '#222222',
	'vila-velha': '#CC0000',
	'serra': '#CCFF33',
	'test': '#00FFFF',
}


const styles = makeStyles(theme => ({
  root: {
		height: '100%',
	},
	contentRoot: {
		padding: 0,
		height: '100%',
		overflow: 'auto',
    '&:last-child': {
      paddingBottom: 0,
    },
		height: 432,
	},
	routeContainer: {
		position: 'relative',
		height: '400px',
		display: 'flex',
	},
	noPictureIcon: {
		width: 100,
		height: 100,
		marginBottom: '8px',
		color: theme.palette.text.secondary,
	},
	noPictureText: {
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));


const today = new Date();
const todaysYear = today.getFullYear();
const todaysMonth = today.getMonth() + 1;
const todaysDay = today.getDate();

const minYear = 2019;

	const mockMarkers = [
  	{ lat: -22.89, lng: -47.05 },
  	{ lat: -22.90, lng: -47.05 },
  	{ lat: -22.90, lng: -47.06 },
  	{ lat: -22.89, lng: -47.06 },
	]


export default function RoutesMap(props) {

	const classes = styles();


  const { className, branch, year, month, ...rest } = props;

	const [ branchCaptures, setBranchCaptures ] = useState({});

	const [ ssyear, setYear ] = useState(todaysYear);
	const [ aamonth, setMonth ] = useState(todaysMonth);
	const [ day, setDay ] = useState(todaysDay);

	const [ groupedCaptures, setGroupedCaptures ] = useState({});

	const [ loading, setLoading ] = useState(true);

	const [ selectedRoute, setSelectedRoute ] = useState();

	const [ routes, setRoutes ] = useState([]);


	const [ searchType, setSearchType ] = useState('pct');

	const [ minPctDiff, setMinPctDiff ] = useState();
	const [ maxPctDiff, setMaxPctDiff ] = useState();

	const [ minAbsDiff, setMinAbsDiff ] = useState();
	const [ maxAbsDiff, setMaxAbsDiff ] = useState();

	const [ minGpsDiff, setMinGpsDiff ] = useState();
	const [ maxGpsDiff, setMaxGpsDiff ] = useState();


	React.useEffect(() => {

			console.log('WRFMF');

			if(!branch) {
				return;
			}

			setRoutes([]);
			setLoading(true);

	//		getRequestCaptures(selectedRoute.requestid).then(data => {
			listCaptures({ branchId: branch, year, month,
				minPctDiff, maxPctDiff, minAbsDiff, maxAbsDiff, minGpsDiff, maxGpsDiff,
			}).then(data => {
				console.log('Got capturesList response', data, 'for branch', branch);
				/*
				setMarkers(data.map(d => {// ({ latitude : lat, longitude : lng, capturetimestamp : ts }) => {
					return {
						lat: d.latitude,
						lng: d.longitude,
						cDate: moment(+d.capturetimestamp).format('LLLL'),
						info: `Sequência #${d.sequenceid}`
					};
				}));
				*/

				setRoutes(data);
				setLoading(false);
			});
			/*
			getBranchCapturesByDate(branch, year, month).then(data => {
				setBranchCaptures(data);
				setMarkers(mockMarkers);
			})
			*/
	}, [branch, year, month,
			minPctDiff,
			maxPctDiff,
			minAbsDiff,
			maxAbsDiff,
			minGpsDiff,
			maxGpsDiff,
		]);


	let maxMonth = 12;
	let maxDay = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()

	if(year === todaysYear) {
		maxMonth = todaysMonth;
		if(month === todaysMonth) {
			maxDay = todaysDay
		}
	}

	const yearsArray = [...new Array(1 + todaysYear - minYear)];
	const monthsArray = [...new Array(maxMonth)];
	const daysArray = [...new Array(maxDay)];

	
	const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

	

	const handleSubmit = ({ searchType, minDiff, maxDiff }) => {
		if(searchType === 'pct') {
			setMinPctDiff(minDiff);
			setMaxPctDiff(maxDiff);
			setMinAbsDiff();
			setMaxAbsDiff();
			setMinGpsDiff();
			setMaxGpsDiff();
		} else if(searchType === 'abs') {
			setMinPctDiff();
			setMaxPctDiff();
			setMinAbsDiff(minDiff);
			setMaxAbsDiff(maxDiff);
			setMinGpsDiff();
			setMaxGpsDiff();
		} else if(searchType === 'gps') {
			setMinPctDiff();
			setMaxPctDiff();
			setMinAbsDiff();
			setMaxAbsDiff();
			setMinGpsDiff(minDiff);
			setMaxGpsDiff(maxDiff);
		}
	}
	

	return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
			elevation={4}
    >
      <CardHeader
        action={
				<>


      <IconButton
				aria-describedby={id}
				variant="contained"
				onClick={handleClick}
				size="small"
				style={{marginTop: '8px', marginRight: '8px'}}
			>
        <FilterIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
				<FilterForm loading={loading} onSubmit={handleSubmit} />
      </Popover>

				</>

        }
        title="Capturas"
      />
      <Divider />
      <CardContent className={classes.contentRoot}>
		<div
			className={classes.routeContainer}
		>

		
			{(routes.length > 0) ? (<> 
				<Map 
					captures={routes}

					loading={loading}
				/>
			</>)
			:
			(loading ?
				(<Progress />)
				:
				(
				<Placeholder
						icon={PhotoLibraryIcon}
						text="Nenhuma captura com valores para comparação foi encontrada"
					/>
				)
			)}
		</div>
      </CardContent>
    </Card>
  );
}
