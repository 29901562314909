import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import Tooltip from '@material-ui/core/Tooltip';

import { Link } from 'react-router-dom';

import Logger from '../../../modules/Logger';
const log = Logger('MainPanelDrawerContent');

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
	linksList: {
		padding: 0,
	},
	routerLink: {
	 textDecoration: 'none',
	 color: theme.palette.common.black,// '#bbbbbb',
	},
	drawerContentContainer: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
}));

function DrawerItems(props) {
	
  const classes = useStyles();
  const theme = useTheme();

	const {
		location
	} = props;

	let ListEntry = (props) => {
		const {
			open,
			selected,
			icon : Icon,
			label,
			path,
		} = props;
		log.i('open is', open);
		if(!open) {
		return (
			<Tooltip title={label} placement="right">
				<Link to={path} className={classes.routerLink} >
    	      <ListItem selected={selected} button key={path} style={{height: '64px'}}>
    	        <ListItemIcon><Icon/></ListItemIcon>
    	        <ListItemText primary={label} />
    	      </ListItem>
				</Link>
			</Tooltip>
		);
		}
		return (
			<Link to={path} className={classes.routerLink} >
          <ListItem selected={selected} button key={path} style={{height: '64px'}}>
            <ListItemIcon><Icon/></ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
			</Link>
		);
	}

	return (
		<>
    <div className={classes.toolbar} />
   	<div className={classes.drawerContentContainer} >
    	<List className={classes.linksList}>
    	  {React.Children.map(props.children, child => {
					let { icon, label, path } = child.props;
					log.i('Comparing path', path, 'with location', location);
					const selected = location.pathname.startsWith(path);
					log.i(path, 'reports as', selected ? 'SELECTED' : 'NOT selected');
					return <ListEntry selected={selected} icon={icon} label={label} path={path} open={props.open} />
				})}
    	</List>
    </div>
		</>
	);
}

/*
	const appName = process.env.REACT_APP_NAME || 'cloud';
	const appVersion = process.env.REACT_APP_VERSION || '1.2.14';

			<div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'flex-end', alignItems: 'center', padding: '8px', }}>
    	    <Typography variant="body1" color="textSecondary" component="p">
    	      {appName}
					</Typography>
    	    <Typography variant="caption" color="textSecondary" component="p">
						v{appVersion}
					</Typography>
			</div>
*/

export default DrawerItems;
