import React, { useState } from 'react';

import Canvas from './Canvas';
import Logo from './Logo';

function StandBy(props) {
	return (
		<Canvas>
			<Logo/>
		</Canvas>
	);
}

export default StandBy;
