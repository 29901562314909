import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import { Link } from 'react-router-dom';

import MySnackbarContentWrapper from '../../../components/Snackbar';
import MyButton from '../../../components/LoadingButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
	container: {
		padding: '16px',
		minWidth: '300px',
		width: '100%',
	},
  paper: {
		paddingLeft: '32px',
		paddingRight: '32px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '8px',
			paddingRight: '8px',
    },
		width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginBottom: theme.spacing(2),
  },
	formInput: {
		//backgroundColor: '#FFFFFF',
		marginLeft: '8px',
		marginRight: '8px',
	},
	subtitle: {
		marginTop: theme.spacing(1),
	},
	margin: {
    margin: theme.spacing(1),
	},
  submit: {
    margin: theme.spacing(1, 0.5, 0),
  },
	spacer: {
		flex: '1',
	},
	text: {
    marginTop: theme.spacing(2),
		//color: '#FFFFFF',
	},
	link: {
		color: '#FFFFFF',
		textDecorationColor: '#FFFFFF',
	},
	formInputs: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexWrap: 'nowrap',
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
    },
	},
	submitRow: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingRight: '8px',
	},
}));

function Form(props) {
  const classes = useStyles();
}


function MyForm(props) {

	const classes = useStyles()
	const {
		error,
		user,
		onErrorClear,
		onCancel,
		onSubmit,
		loading,
	} = props;

	const [ name, setName ] = useState();
	const [ email, setEmail ] = useState();
	const [ password, setPassword ] = useState();
	const [ remember, setRemember ] = useState(false);

	const [ wasUserManuallyEdited, setUserManuallyEdited ] = useState(false);

	const [ showPassword, setShowPassword ] = useState(false);

	let handleSubmit = (e) => {
		e.preventDefault();
		onSubmit({ name, user: `${email}@${user.domain}`, password });
	}


	const handleNameChange = e => {
		const { value } = e.target;
		setName(e.target.value);
		if(!wasUserManuallyEdited) {
			if(!value) {
				setEmail();
			} else {
				setEmail(value.replace(/\s+([^\s])/g, '.$1').toLowerCase());
			}
		}
	}

	const handleUserChange = e => {
		const { value } = e.target;
		setEmail(value);
		if(!!value) {
			setUserManuallyEdited(true);
		} else {
			setUserManuallyEdited(false);
		}
	}

  const handleClickShowPassword = () => {
    setShowPassword(showPassword => !showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

	return (
      <Paper className={classes.paper} elevation={5}>
        <Typography component="h1" variant="h5" className={classes.text}>
					Informe os dados do novo usuário
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
					<div className={classes.formInputs}>
	          <TextField
							className={classes.formInput}
		          disabled={loading}
	            variant="filled"
	            margin="normal"
	            fullWidth
							required
	            id="name"
	            label="Nome"
							type="name"
	            name="name"
							autoComplete="name"
	            autoFocus
							value={name}
							onChange={handleNameChange}
	          />
	          <TextField
							className={classes.formInput}
		          disabled={loading}
	            variant="filled"
	            margin="normal"
	            fullWidth
							required
	            id="user"
	            label="Usuário"
							type="user"
	            name="user"
							autoComplete="user"
							value={email}
							onChange={handleUserChange}
       				InputProps={{
		          	endAdornment: (
								<InputAdornment
									style={{
										alignItems: 'flex-start',
									}}
									position="end"
								>
									@{user.domain}
								</InputAdornment>
								)
		       		}}

	          />
	          <TextField
							className={classes.formInput}
		          disabled={loading}
	            variant="filled"
	            margin="normal"
							required
	            fullWidth
	            name="password"
	            label="Senha"
		          type={showPassword ? 'text' : 'password'}
	            id="password"
							autoComplete="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}

       				InputProps={{
         				endAdornment: (
         				  <InputAdornment position="end">
         				    <IconButton
         				      aria-label="toggle password visibility"
         				      onClick={handleClickShowPassword}
         				      onMouseDown={handleMouseDownPassword}
         				    >
         				      {showPassword ? <Visibility /> : <VisibilityOff />}
         				    </IconButton>
         				  </InputAdornment>
         				)
		       		}}
	          />
					</div>

		      {error && <MySnackbarContentWrapper
		        variant="error"
		        className={classes.margin}
		        message={error}
						onClose={onErrorClear}
		      />}

					<div className={classes.submitRow}>
			<div className={classes.spacer} />
	          <MyButton
		          disabled={loading}
	            variant="contained"
	            className={classes.submit}
							onClick={onCancel}
	          >
	            Cancelar
	          </MyButton>
	          <MyButton
	            type="submit"
		          disabled={loading}
	            variant="contained"
	            color="primary"
							loading={loading}
	            className={classes.submit}
	          >
	            Adicionar
	          </MyButton>
					</div>
        </form>
      </Paper>

	);
}

export default MyForm;
