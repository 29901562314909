import React from 'react';

import {
	Typography,
	CardHeader,
	CardContent,
} from '@material-ui/core';
import NoPicturesIcon from '@material-ui/icons/Layers';
import { withStyles } from '@material-ui/core/styles';

import PicturePreview from './PicturePreview';

import Progress from 'components/ProgressSpinner';

import moment from 'moment';

import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
		iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
		iconUrl: require('leaflet/dist/images/marker-icon.png'),
		shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const styles = theme => ({
	noPictureIcon: {
		width: 100,
		height: 100,
		marginBottom: '8px',
		color: theme.palette.text.secondary,
	},
	noPictureText: {
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
});


class SimpleExample extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			lat: -22.89,
			lng: -47.05,
			zoom: 15
		}
	}

	render() {

		const markers = this.props.markers;
		const loading = this.props.loading;

		const classes = this.props.classes;

		const position = [this.state.lat, this.state.lng];

		console.log('MARKERS IS', markers);

		let center = [this.state.lat, this.state.lng];

		let entries = markers ? markers.length : 0;
		if(entries > 0) {
			console.log(JSON.stringify(markers));
			let maxLat = -1000;
			let maxLng = -1000;
			let minLat = 1000;
			let minLng = 1000;
			let accLat = 0;
			let accLng = 0;
	
			markers.forEach(({latitude : lat, longitude : lng}) => {
				lat = +lat;
				lng = +lng;
				maxLat = lat > maxLat ? lat : maxLat;
				maxLng = lat > maxLng ? lng : maxLng;
				minLat = lat < minLat ? lat : minLat;
				minLng = lat < minLng ? lng : minLng;
	
				accLat += lat;
				accLng += lng;
			});

			console.log('ACCLAT', accLat, 'ACCLNG', accLng, 'ENTRIES', entries);

			center = { lat: accLat/entries, lng: accLng/entries };

			console.log('Set childrenCenter as', center);

			
			const maxDiff = Math.max( Math.abs(maxLat - minLat), Math.abs(maxLng - minLng) );
			console.log('Max diff is', maxDiff, maxLat, minLat, maxLng, minLng);
			console.log('Max diff expr', maxLat - minLat) 
			
//			childrenZoom = Math.round(30 - 100*maxDiff)

		}



		return (
		<div style={{ position: 'relative', height: '100%', width: '100%'}}>

			{loading && <Progress />}

			{markers ?
			<LeafletMap
				style={{
					// height hack from https://stackoverflow.com/questions/18665171/how-to-make-the-main-content-div-fill-height-of-screen-with-css
					width: '100%',
					position: 'absolute',
					top: 0, /* Header Height */
					bottom: 0,
				}}
				center={center}
				zoom={this.state.zoom}
			>
				<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
					url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
				/>
				{markers.map(marker => {
					let {
						latitude: lat,
						longitude : lng,
						capturetimestamp,
						sequenceid,
						thumbnailurl,
					} = marker;
					//let {cDate, info, ...markerPosition} = marker
					return (
					<Marker position={{ lat, lng }}>
						{marker && <Popup>
							<CardContent style={{padding: 0}}>
								{moment(+capturetimestamp).format('LLLL')}
							</CardContent>
							<CardHeader
								style={{ padding: 0, paddingTop: '8px' }}
								title={`Sequência #${sequenceid}`}
							/>
							<PicturePreview
								imageUrl={thumbnailurl}
							/>
						</Popup>}
					</Marker>
					);
				})}
			</LeafletMap>
			:
			<div
				style={{
					// height hack from https://stackoverflow.com/questions/18665171/how-to-make-the-main-content-div-fill-height-of-screen-with-css
					width: '100%',
					position: 'absolute',
					top: 0, /* Header Height */
					bottom: 0,
				}}
			>
				
						<div style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							position:'relative',
							height: '100%',
							}}>
								<NoPicturesIcon className={classes.noPictureIcon} />
	
			    	    <Typography variant="body1" className={classes.noPictureText} component="p">
			    	      Selecione uma rota para visualizar os marcadores
								</Typography>
							</div>




			</div>
			}

		</div>
		);
	}
}

export default withStyles(styles)(SimpleExample);
