import React, { useState } from 'react';
import clsx from 'clsx';

import { Link } from 'react-router-dom';

import Progress from 'components/ProgressSpinner';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  root: {
		minWidth: '250px',
    height: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'auto',
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			height: '300px',
		}
  },
	noOverflow: {
		overflow: 'hidden',
	},
  content: {
    padding: 0,
		flexGrow: 1,
		width: '100%',
		borderRight: '1px',
		borderTop: '0px',
		borderLeft: 0,
		borderBottom: 0,
		borderColor: '#cccccc',
		borderStyle: 'solid',
		[theme.breakpoints.down('sm')]: {
			borderRight: '0px',
			borderTop: '1px',
			borderLeft: 0,
			borderBottom: 0,
			borderColor: '#cccccc',
			borderStyle: 'solid',
		},
  },
  image: {
    height: 48,
    width: 48
  },
	header: {
		height: '64px',
	},
	routerLink: {
	 textDecoration: 'none',
	 color: theme.palette.common.black,// '#bbbbbb',
	},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestProducts = props => {
  const {
		className,
		loading,
		routes,
		selectedRoute,
		setSelectedRoute,
		...rest
	} = props;

  const classes = useStyles();


	console.log('AAALISLISLIST', 'selected:', selectedRoute,'routes', routes);
//  const [products] = useState(mockData);

	function handleListSelection(item) {
		console.log('LISLISLIST', 'selecting', item);
		setSelectedRoute(item);
	}

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
				{loading && <Progress />}
      <div className={`${classes.content} ${loading ? classes.noOverflow : ''}`}>
        <List disablePadding>
          {routes.map((route, i) => (
            <ListItem
							button
							selected={route === selectedRoute}
							onClick={() => handleListSelection(route)}
              divider={i < routes.length - 1}
              key={`item-${route.requestid}-${route.groupid}-${route.routeid}`}
            >
              <ListItemText
                primary={`Req. #${route.requestid} - ${route.captures} ${route.captures > 1 ? 'capturas' : 'captura'}`}
                secondary={`Grupo ${route.groupid}, Rota ${route.routeid}`}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );

};

LatestProducts.propTypes = {
  className: PropTypes.string
};

export default LatestProducts;
