import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const canvasStyles = makeStyles(theme => ({
	pageContent: {
		minHeight: '100vh',
		padding: 0,
		backgroundColor: theme.palette.primary.main,
		width: '100%',
		minWidth: '300px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		padding: 0,
	},
	centralizeVertically: {
		display:'flex',
		flexDirection:'column',
		justifyContent: 'center',
	},
}));

function Canvas(props) {
	const classes = canvasStyles();
	const { children } = props;

	return (
		<div className={classes.pageContent}>
			{React.Children.map(children, Child => {
				return (
					<div className={classes.centralizeVertically} key={Child.props.className}>
						{React.cloneElement(Child)}
					</div>
				); 
			})}
		</div>
	);
}

export default Canvas;
