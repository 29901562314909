let counter = 0;
function createData(name, calories, fat, carbs, protein) {
	counter += 1;
	return { id: `id_${counter}`, name, calories, fat, carbs, protein };
}

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function stableSort(array, cmp) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}



const myRows = [
	{ id: 'name', sortable: true, numeric: false, label: 'Dessert (100g serving)' },
	{ id: 'calories', sortable: true, numeric: true, label: 'Calories' },
	{ id: 'fat', numeric: true, label: 'Fat (g)' },
	{ id: 'carbs', numeric: true, label: 'Carbs (g)' },
	{ id: 'protein', numeric: true, label: 'Protein (g)' },
];

let myData = [
	createData('Cupcake', 305, 3.7, 67, 4.3),
	createData('Donut', 452, 25.0, 51, 4.9),
	createData('Eclair', 262, 16.0, 24, 6.0),
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
	createData('Gingerbread', 356, 16.0, 49, 3.9),
	createData('Honeycomb', 408, 3.2, 87, 6.5),
	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
	createData('Jelly Bean', 375, 0.0, 94, 0.0),
	createData('KitKat', 518, 26.0, 65, 7.0),
	createData('Lollipop', 392, 0.2, 98, 0.0),
	createData('Marshmallow', 318, 0, 81, 2.0),
	createData('Nougat', 360, 19.0, 9, 37.0),
	createData('Oreo', 437, 18.0, 63, 4.0),
]

export function fetchData(idToOrderBy, order, pageNumber, rowsPerPage) {
	let tableData = [...myData];
	if(idToOrderBy) {
		tableData = stableSort(tableData, getSorting(order, idToOrderBy));
	}

	tableData = tableData.slice(pageNumber * rowsPerPage, pageNumber * rowsPerPage + rowsPerPage)

	return new Promise(resolve => {
		setTimeout(() => {
			resolve({data: tableData, total: myData.length});
		}, 500);
	});

}

export function getDataById(id) {
	let idData = myData.find(d => d.id === id);
	return new Promise(resolve => {
		setTimeout(() => {
			resolve(idData);
		}, 1000);
	});
}

let logData = [
	{ text:'2019-12-31 14:30:21 - Equipamento realizou procedimento... ' },
	{ text:'2019-12-31 14:30:21 - Equipamento realizou procedimento... ' },
	{ text:'2019-12-31 14:30:21 - Equipamento realizou procedimento... ' },
	{ text:'2019-12-31 14:30:21 - Equipamento realizou procedimento... ' },
	{ text:'2019-12-31 14:30:21 - Equipamento realizou procedimento... ' },
	{ text:'2019-12-31 14:30:21 - Equipamento realizou procedimento... ' },
	{ text:'2019-12-31 14:30:21 - Equipamento realizou procedimento... ' },
	{ text:'2019-12-31 14:30:21 - Equipamento realizou procedimento... ' },
	{ text:'2019-12-31 14:30:21 - Equipamento realizou procedimento... ' },
	{ text:'2019-12-31 14:30:21 - Equipamento realizou procedimento... ' },
];

export function fetchLogData() {
	return new Promise(resolve => {
		setTimeout(() => {
			resolve(logData);
		}, 1000);
	});
}

