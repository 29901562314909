import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import Hidden from '@material-ui/core/Hidden';
import MDrawer from './MDrawer';
import RDrawer from './RDrawer';
import DrawerContent from './DrawerContent';

import Logger from '../../../modules/Logger';
const log = Logger('MainPanelDrawer');

//import MenuIcon from './MenuIcon';

const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
}));

function MyDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();

	log.i('Mah props is', props);

	const {
		open,
		mobileOpen,
		handleDrawerToggle,
		handleMobileDrawerToggle,
		title,
		items : DrawerItems,
		content : PageContent,
		children,
	} = props;

  function handleDrawerOpen() {
  }

  function handleDrawerClose() {
  }


  return (
    <div className={classes.root}>
	    <Hidden smUp implementation="css">
				<RDrawer
					open={mobileOpen}
					handleDrawerToggle={handleMobileDrawerToggle}
					title={"Decode"}
				>
					<DrawerContent
						location={props.location}
					>
						{children}
					</DrawerContent>
				</RDrawer>
	    </Hidden>
	    <Hidden xsDown implementation="css">
				<MDrawer
					open={open}
					handleDrawerToggle={handleDrawerToggle}
					title={"Decode"}
				>
					<DrawerContent
						location={props.location}
						open={open}
					>
						{children}
					</DrawerContent>
				</MDrawer>
	    </Hidden>
    </div>
  );
}

export default MyDrawer;
