import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

//import { get } from '../../../modules/Captures';

import MasterPage from './Master';
//import DetailsPage from './Details';

function MasterDetailsPage(props) {
	const { match } = props;

	return (
		<Switch>
			<Route path={`${match.url}`} render={(props) => <MasterPage {...props} />} />
		</Switch>
	);

/*
	return (
		<Switch>
			<Route path={`${match.url}`} exact render={(props) => <MasterPage {...props} />} />
			<Route path={`${match.url}/:id`} render={(props) => <DetailsPage {...props} get={get} />} />
		</Switch>
	);
	*/

	return null
}

export default withRouter(MasterDetailsPage);
