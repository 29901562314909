import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

//import MasterPage from './pieces';
import Global from './pieces/GlobalStats';
import Local from './pieces/LocalStats';
import Maps from './pieces/Maps';
import Captures from './pieces/Captures';
import Devices from './pieces/Devices';
import Users from './pieces/Users';
//import MasterPage from './Master';

function MasterDetailsPage(props) {
	const { match } = props;

	console.log(`${match.url}`);

	return (
		<Switch>
			<Route path={`${match.url}`} exact render={(props) => <Global {...props} />} />
			<Route path={`${match.url}/requests`} render={(props) => <Maps {...props} />} />
			<Route path={`${match.url}/branches`} render={(props) => <Local {...props} />} />
			<Route path={`${match.url}/captures`} render={(props) => <Captures {...props} />} />
			<Route path={`${match.url}/devices`} render={(props) => <Devices {...props} />} />
			<Route path={`${match.url}/users`} render={(props) => <Users {...props} />} />
		</Switch>
	);

}

export default withRouter(MasterDetailsPage);
