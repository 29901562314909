import React, { useState } from 'react';

import Card from './CardsPanel';

import RoutesMap from './RoutesMap';

import { Grid } from '@material-ui/core';
import { Select, MenuItem } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import {
  getExistingUsers as getExistingDevices,
  getBranchCapturesByDate,
} from 'modules/Analytics';

const useStyles = makeStyles(theme => ({
  dashRoot: {
    padding: theme.spacing(2),
    paddingTop: 0,
  }
}));

export default function Maps(props) {
	
  const classes = useStyles();

	const {
		branch
	} = props;

	const {
		day,
		month,
		year,
	} = props;

	const [ branches, setBranches ] = useState([]);
	const [ selectedBranch, setSelectedBranch ] = useState('');

	const [ selectedUserId, setSelectedUserId ] = useState('');

	console.log('Mapping branches countU:', branches); 

	React.useEffect(() => {
		if(!branch) return;
		
		getExistingDevices({ branchId: branch, month, year }).then(data => {
			let branches = data || [];
			branches = branches.map(d => {
				if(!!d.employeename && !!d.employeeid) {
					return {
						label: `${d.employeename} (${d.employeeid})`,
						value: d.employeeid,
					}
				} else {
					return {
						label: 'Não informado',
						value: -1,
					}
				}
			});

			console.log('Got devices response', data);
			setBranches(branches);
			if(branches.length > 0) {
				setSelectedUserId(branches[0].value);
			}
		});
		
	}, [branch, month, year]);

	return (
	<Card
		title="Detalhe por usuário"
	    className={classes.dashRoot}
			actions={() => (
				<Select
					style={{marginTop: '8px', marginLeft: '8px', marginRight: '8px'}}
					disabled={branches.length <= 1}
          value={selectedUserId}
          onChange={e => setSelectedUserId(e.target.value)}
          inputProps={{
            name: 'user',
            id: 'user-selector',
          }}
        >
					{branches.map(branchName => (<MenuItem key={branchName.value} value={branchName.value}>{branchName.label}</MenuItem>) )}
        </Select>

			)}
	>
	    <div className={classes.dashRoot}>
	      <Grid
	        container
	        spacing={2}
	      >
	
	        <Grid item xl={6} xs={12}>
			<RoutesMap 
				branch={branch}
				deviceId={selectedBranch}
				userId={selectedUserId}
				day={day}
				month={month}
				year={year}
			/>
	        </Grid>


	      </Grid>
	    </div>
	</Card>
	);
}
