import React, { useState } from 'react';

import { Switch, Route, withRouter } from 'react-router-dom';

import { fetchData } from './dataservice';


//import CardsPanel from '../Dashboard/pieces/CardsPanel';
import CardsPanel from './CardsPanel';


import Card from './CaptureCard';
//import Card from './CaptureCardClass';
import { Redirect } from 'react-router-dom';
import { Select, MenuItem } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import NoPicturesIcon from '@material-ui/icons/Camera';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
	monitorPageData,
	monitorTableSize,
	exportCaptures,
	getRoutesInfo,
} from '../../../modules/Captures';

import DetailsPage from './Details';
import { get } from '../../../modules/Captures';

import { useUser } from 'UserProvider';

import Typography from '@material-ui/core/Typography';

import {
  getExistingBranches,
  getBranchCapturesByDate,
} from 'modules/Analytics';

import Logger from '../../../modules/Logger';
const log = Logger('CaptureMaster');






const getBranchGroups = (branchId) => {
	const branchGroups = {
		mirante: [1, 2, 3],
		serra: [3, 4, 5],
	}

	return Promise.resolve(branchGroups[branchId]);
}

const getBranchGroupRoutes = (branchId, groupId) => {
	const branchGroupRoutes = {
		mirante: {
			1: [ 'M102', 'M103' ],
			2: [ 'M201', 'M202' ],
			3: [ 'M300', 'M301', 'M302' ],
		},
		serra: {
			3: [ 'S301', 'S302' ],
			4: [ 'S400', 'S401', 'S402' ],
			5: [ 'S502', 'S503' ],
		},
	}

	return Promise.resolve(branchGroupRoutes[branchId][groupId]);
}













const styles = theme => ({
	pageRoot: {
		padding: '16px',
		margin: '8px',
		backgroundColor: '#4f4f4f',
		display: 'flex',
		flexWrap: 'wrap',
	},
	cardContainer: {
		margin: '16px',
	},
	noPictureIcon: {
		width: 100,
		height: 100,
		marginBottom: '8px',
		color: theme.palette.text.secondary,
	},
	noPictureText: {
		color: theme.palette.text.secondary,
	}
});

function MasterGrid(props) {

	log.i('MasterGrid props is', props);

	let { classes, match, onPageDataRequest } = props;

	let [ pageNumber , setPageNumber ] = useState(0);
	let [ rowsPerPage, setRowsPerPage ] = useState(12);

	let [ dataLength, setDataLength ] = useState(0);

	let [ order, setOrder ] = useState('asc');
	let [ idToOrderBy, setIdToOrderBy ] = useState('');

	let [ loading, setLoading ] = useState(true);
	let [ exportLoading, setExportLoading ] = useState(false);

	let [ tableData, setTableData ] = useState([]);

	let [ redirectTo, setRedirectTo ] = useState();

	let [ minDate, setMinDate ] = useState(null);
	let [ maxDate, setMaxDate ] = useState(null);
	let [ filterPair, setFilterPair ] = useState();

	let [ filterValue, setFilterValue ] = useState();
	let [ showFilters, setShowFilters ] = useState(false);

	let handleChangePage = (event, page) => {
		setPageNumber(page);
	};

	let handleChangeRowsPerPage = event => {
		setRowsPerPage(event.target.value);
		setPageNumber(0);
	};

/*
	React.useEffect(() => {
		return monitorTableSize(counters => {
			setDataLength(counters['captures']);
		});
	}, []);
	*/

		const user = useUser();
		log.i('CurrUser is', user);


//	const [ loading, setLoading ] = useState(true);
	const [ branches, setBranches ] = useState(user.branches || []);
	const [ selectedBranch, setSelectedBranch ] = useState(user.branches && user.branches.length > 0 ? user.branches[0] : '');


	const [ groups, setGroups ] = useState([]);
	const [ routes, setRoutes ] = useState([]);

	const [ selectedGroup, setSelectedGroup ] = useState('');
	const [ selectedRoute, setSelectedRoute ] = useState('');

/*
	React.useEffect(() => {
		log.i('QWE CLeaning selected route cause selected group changed')
		setSelectedRoute('');
	}, [ selectedGroup ]);
	React.useEffect(() => {
		log.i('QWE Cleaning routes cause groups changed'); 
		setRoutes([]);
	}, [ groups ]);

*/

	React.useEffect(() => {
		log.i('QWE Reloading groups cause branch changed'); 
		setGroups([]);
		setSelectedGroup('');
		log.i('Loading groups');
		if(!selectedBranch) {
			log.i('Waiting for a selected branch');
		} else {
			getRoutesInfo(selectedBranch).then(groups => {
				log.i('QWE Set groups', groups);
				setGroups(groups);
//				if(groups.length === 1) {
//					log.i('QWE Set SEL group as 0:', groups[0]);
//					setSelectedGroup(groups[0]);
//				}
			});
		}
	}, [ selectedBranch ]);




	React.useEffect(() => {

		if(!selectedGroup) {
			return log.i('Nothing to do untl a group is selected');
		}

		log.i('QWE Reloading routes cause either branch or selectedgroup changed'); 
		setRoutes([]);
		setSelectedRoute('');
		log.i('Loading routes');
		if(!selectedBranch) {
			log.i('Waiting for a selected branch');
		} else {
			getRoutesInfo(selectedBranch, selectedGroup).then(routes => {
				log.i('QWE Set routes', routes);
				setRoutes(routes);
//				if(routes.length === 1) {
//					log.i('QWE Set SEL routes as 0:', routes[0]);
//					setSelectedRoute(routes[0]);
//				}
			});
		}
	}, [ selectedBranch, selectedGroup ]);


	React.useEffect(() => {
		if(!user) {
			log.i('Waiting for a valid user');
			return;
		}
		log.i('User changed. Updating branches to', user.branches);
		const newBranches = user.branches || [];
		setBranches(newBranches);
		if(newBranches.length > 0) {
			setSelectedBranch(newBranches[0]);
		}
	}, [ user ]);


	React.useEffect(() => {

		if(!selectedBranch) {
			log.i('Waiting for a selected branch');
			return;
		}

		log.i('QWE Loading captures data cause someone changed'); 
		setLoading(true);
		//onPageDataRequest(idToOrderBy, order, pageNumber, rowsPerPage).then(response => {
//		return onNewData(response => {
		monitorPageData({
			pageIndex: pageNumber, rowsPerPage,
			minDate, maxDate,
			filter: filterPair,
			branchId: selectedBranch,
			groupId: selectedGroup,
			routeId: selectedRoute,
		}, response => {
		//return monitorPageData(pageNumber, rowsPerPage, minDate, maxDate, filterPair, response => {
			log.i('GOT DATA', response);
			setTableData(response.data);
			setDataLength(response.count);
//			setDataLength(response.total);
			setLoading(false);
		});
	}, [ idToOrderBy, order, pageNumber, rowsPerPage, minDate, maxDate, filterPair, selectedBranch, selectedGroup, selectedRoute ]);



//	React.useEffect(() => {
//		log.i('Setting onNewDataCallback');
//		return onNewData((data) => {
//			log.i('Got new data:', data, '. Merging it with', tableData);
//			let newData = [data, ...tableData];
//			log.i('Resulted in', newData);
//			setTableData(newData);
//		});
//	}, [tableData]);

	let handleDetailsClick = (dataId) => e => {
		log.i('CLicked on id', dataId);	
		setRedirectTo(dataId);
		//setRedirectTo(`${props.match.url}/${dataId}`);
	}

	

	let mockhandleExport = () => {

		if(exportLoading) return;

		setExportLoading(true);
		setTimeout(() => {
			setExportLoading(false);
		}, 5000)

	}

	let handleExport = () => {

		if(exportLoading) return;

		setExportLoading(true);
		exportCaptures({ branchId: selectedBranch,
					minDate,
					maxDate,
					filter: filterPair,
					branchId: selectedBranch,
					groupId: selectedGroup,
					routeId: selectedRoute,
				})
			.then(() => {
			log.i('Exporting captures');
			setExportLoading(false);
		});

	}




  const [values, setValues] = React.useState({
    searchKey: '',
		searchValue: '',
  });

  const handleChange = name => event => {
    setValues((values) => ({ ...values, [name]: event.target.value }) );
  };

	/*
	return (
		<Switch>
			<Route path={`${match.url}`} exact render={(props) => <MasterPage {...props} />} />
			<Route path={`${match.url}/:id`} render={(props) => <DetailsPage {...props} get={get} />} />
		</Switch>
	);
	*/

	const handleFilterSubmit = () => {
		log.i('master submit with', values);
		const { searchKey, searchValue } = values;
		setValues({searchKey, searchValue});
		setFilterPair({searchKey, searchValue});
	}

	const handleFilterClick = () => {
		log.i('momomo');
		setShowFilters(show => !show);	
	}






	return (
		<Switch>
			<Route path={`${match.url}/:id`} render={(props) => <DetailsPage {...props} get={get} />} />
			<Route path={`${match.url}`} exact render={(props) => (
				<MasterPage
					{...props}
					minDate={minDate}
					maxDate={maxDate}
					setMaxDate={setMaxDate}
					setMinDate={setMinDate}
					onFilter={handleFilterSubmit}
					filterValue={filterValue}
					onFilterValueChange={setFilterValue}


				classes={classes}

				handleFilterClick={handleFilterClick}
				showFilters={showFilters}

				handleMinDateChange={setMinDate}
				handleMaxDateChange={setMaxDate}

				selectedGroup={selectedGroup}
				selectedRoute={selectedRoute}
				setSelectedGroup={setSelectedGroup}
				setSelectedRoute={setSelectedRoute}
				groups={groups}
				routes={routes}

				values={values}
				handleChange={handleChange}
				handleFilterClick={handleFilterClick}
				handleFilterSubmit={handleFilterSubmit}


				loading={loading}
				setLoading={setLoading}
				branches={branches}
				setBranches={setBranches}
				selectedBranch={selectedBranch}
				setSelectedBranch={setSelectedBranch}

				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				dataLength={dataLength}
				rowsPerPage={rowsPerPage}
				pageNumber={pageNumber}
				loading={loading}
				tableData={tableData}
				handleExport={handleExport}
				exportLoading={exportLoading}


				/>)}
			/>
		</Switch>
	);

	if(!!redirectTo) {
		return <Redirect push to={redirectTo} />
	}

	if(props.location.pathname !== '/captures') {
		log.i('Imma show details');
		return <DetailsPage {...props} get={get} />
	}



}
				
	function MasterPage(props) {

		const {
			loading, setLoading,
			branches, setBranches,
			selectedBranch, setSelectedBranch,
			} = props;


	const {
		setSelectedGroup,
		selectedGroup,
		setSelectedRoute,
		selectedRoute,
		groups,
		routes,
	} = props;


		let { classes } = props;

		let handleFilterSubmit = values => {
			log.i('Filter submitted values', values);
			props.onFilter(values.searchKey);
		}
	
		let handleMaxDateChange = maxTs => {
			log.i('Max date is now', maxTs);
			props.setMaxDate(maxTs);
		}
	
		let handleMinDateChange = minTs => {
			log.i('Min date is now', minTs);
			props.setMinDate(minTs);
		}

		log.i('showfilters is', props.showFilters);
		log.i('filters are:', props.values);
		log.i('Branches is', branches);



		return(
			<CardsPanel
				title="Capturas"
			titleExtra={
				<Select
					style={{marginTop: '8px', marginLeft: '8px', marginRight: '8px'}}
					disabled={!branches || branches.length <= 1}
          value={selectedBranch}
          onChange={e => setSelectedBranch(e.target.value)}
          inputProps={{
            name: 'branch',
            id: 'branch-selector',
          }}
        >
					{ branches.map(branchName => (<MenuItem key={branchName} value={branchName}>{branchName}</MenuItem>) ) }
        </Select>
			}

				minDate={props.minDate}
				maxDate={props.maxDate}
				handleMinDateChange={props.handleMinDateChange}
				handleMaxDateChange={props.handleMaxDateChange}


				selectedGroup={selectedGroup}
				selectedRoute={selectedRoute}
				setSelectedGroup={setSelectedGroup}
				setSelectedRoute={setSelectedRoute}
				groups={groups}
				routes={routes}




				selectedBranch={selectedBranch}

				values={props.values}
				handleChange={props.handleChange}
				showFilters={props.showFilters}

				handleFilterClick={props.handleFilterClick}

				onFilterSubmit={props.handleFilterSubmit}
				onFilterValueChange={props.onFilterValueChange}
				filterValue={props.filterValue}

				onChangePage={props.handleChangePage}
				onChangeRowsPerPage={props.handleChangeRowsPerPage}
				rowsPerPageOptions={[6,12,24]}
				rowsCount={props.dataLength}
				rowsPerPage={props.rowsPerPage}
				pageNumber={props.pageNumber}
				loading={props.loading}
				disabled={props.tableData.length === 0}
	
				actions={() => (
				<Tooltip title="Exportar dados">
	      	<Button
						size="small"
						disabled={props.tableData.length === 0}
						variant="contained"
						color="primary"
						aria-label="Exportar dados"
						className={classes.fab}
						onClick={props.handleExport}
					>
						{props.exportLoading ?
										<div style={{position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px'}} >
												<CircularProgress size={24} color="secondary" className={classes.progress} />
										</div>
						: <DownloadIcon />}
		      </Button>
				</Tooltip>
	)
				}
			>
				{props.loading ?
	
						<div style={{position:'relative', height:'300px', width:'100%'}}>
							<CircularProgress color="primary" style={{position:'absolute', top:'50%', left:'50%', marginTop:'-20px', marginLeft:'-20px'}} size={40}/>
						</div>
				:
					props.tableData.length > 0 ?
						props.tableData.map(data => {
							return (
								<div key={data.id} className={classes.cardContainer}>
									<Card
										captureData={data}
									/>
								</div>
							);
						})
					:
						<div style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							height:'300px',
							width:'100%',
						}}>
	
							<div style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							position:'relative',
							marginTop: '-50px',
							}}>
								<NoPicturesIcon className={classes.noPictureIcon} />
	
			    	    <Typography variant="body1" className={classes.noPictureText} component="p">
			    	      Nenhuma captura encontrada
								</Typography>
							</div>
						</div>
								
				}
			</CardsPanel>
		);
	}

//function MTable(props) {
//
//
//	let { onPageDataRequest } = props;
//
//	let [ pageNumber , setPageNumber ] = useState(0);
//	let [ rowsPerPage, setRowsPerPage ] = useState(10);
//
//	let [ dataLength, setDataLength ] = useState(0);
//
//	let [ order, setOrder ] = useState('asc');
//	let [ idToOrderBy, setIdToOrderBy ] = useState('');
//
//	let [ loading, setLoading ] = useState(true);
//
//	let [ tableData, setTableData ] = useState([]);
//
//	let [ redirectTo, setRedirectTo ] = useState();
//
//	let handleChangePage = (event, page) => {
//		setPageNumber(page);
//	};
//
//	let handleChangeRowsPerPage = event => {
//		setRowsPerPage(event.target.value);
//		setPageNumber(0);
//	};
//	
//	React.useEffect(() => {
//		setLoading(true);
//		onPageDataRequest(idToOrderBy, order, pageNumber, rowsPerPage).then(response => {
//			log.i('GOT DATA', response);
//			setTableData(response.data);
//			setDataLength(response.total);
//			setLoading(false);
//		});
//	}, [idToOrderBy, order, pageNumber, rowsPerPage]);
//
//	if(!!redirectTo) {
//		return <Redirect push to={redirectTo} />
//	}
//
//	return (
//		<Table {...props} 
//			onRowClick={(id) => {log.i('clicked on row', id, 'moving to', `${props.match.url}/${id}`); setRedirectTo(`${props.match.url}/${id}`)}}
//			canSelect={false}
//			title="My Table"
//			rows={myRows}
//			data={tableData}
//			orderBy={idToOrderBy}
//			order={order}
//			onSort={(id, order) => e => {
//				setOrder(order);
//				setIdToOrderBy(id);
//			}}
//			onChangePage={handleChangePage}
//			onChangeRowsPerPage={handleChangeRowsPerPage}
//			rowsPerPageOptions={[5,10,25,50]}
//			rowsCount={dataLength}
//			rowsPerPage={rowsPerPage}
//			pageNumber={pageNumber}
//			loading={loading}
//		/>
//	);
//}
//
//function Tables(props) {
//	const { classes } = props;
//
//	return (
//		<div>
//			<div className={classes.pageRoot}>
//				<MTable {...props} />
//			</div>
//		</div>
//	);
//}

export default withStyles(styles)(MasterGrid);
