import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import LoadingButton from '../../../../components/LoadingButton'
import Snackbar from '../../../../components/Snackbar';

import RoutesList from './List';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import CircularProgress from '@material-ui/core/CircularProgress';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DoneIcon from '@material-ui/icons/DoneOutline';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import Papa from 'papaparse';
import { parse, upload, getRequiredFields } from './Routes';
import { uploadCsvFile, sendRoutes, commitChanges } from './Routes';

import Logger from '../../../../modules/Logger';
let log = Logger('UploadStepper');

const useStyles = makeStyles(theme => ({
  root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
    width: '90%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
	pageContent: {
		width: '100%',
		height: '60vh',
		overflow: 'scroll',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

function getSteps() {
	return [
		'Selecione o arquivo',
		'Confira os dados',
		'Aguarde o envio'
	];
}



const uploadPageStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  hiddenInput: {
    display: 'none',
  },
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	message: {
		paddingBottom: theme.spacing(3),
	},
	list: {
		maxHeight: '40vh',
		overflow: 'scroll',
	},
	field: {
		textAlign: 'center',
	}
}));


function UploadPage(props) {
	const classes = uploadPageStyles();

	const { user } = props;

	const [ error, setError ] = useState('');
	const [ parsing, setParsing ] = useState(false);

	let handleFileSelect = (e) => {

		let input = e.currentTarget;
		if(!input.files || input.files.length !== 1) {
			setError('');
			return;
		}

		let file = input.files[0];
		setError('');
		setParsing(true);

		let start = Date.now();
		log.i('handlefileselect with', file);


		/*
		const routeFilesData = [];

		let parsingPromises = files.map(file => {
			return new Promise((resolve, reject) => {
				if(file.name && file.name.endsWith('csv')) {
					let parsedData = Papa.parse(file, {
						complete: ({data}) => {
							log.i('Parsed into', data);
							parse(data, user)
							.then(routesData => {
							})
							.catch(e => {
								setError(e.message);
								setParsing(false);
							});
						},
						error: (err) => {
							setError('Arquivo inválido.');
							setParsing(false);
						},
					})
				} else {
					setError('Arquivo inválido.');
					setParsing(false);
					return;
				}
			})
		});

		Promise.all(parsingPromises).then(() => {
								setParsing(false);
								log.i('Took', (Date.now() - start), 'ms');
								props.onRouteLoad(routesData, file)
		})
		*/


		setTimeout(() => {
			if(file.name && file.name.endsWith('csv')) {
				let parsedData = Papa.parse(file, {
					complete: ({data}) => {
						log.i('Parsed into', data);
						parse(data, user)
						.then(routesData => {
							setParsing(false);
							log.i('Took', (Date.now() - start), 'ms');
							props.onRouteLoad(routesData, file)
						})
						.catch(e => {
							setError(e.message);
							setParsing(false);
						});
					},
					error: (err) => {
						setError('Arquivo inválido.');
						setParsing(false);
					},
				})
			} else {
				setError('Arquivo inválido.');
				setParsing(false);
				return;
			}
		}, 1000);

		//const file = e.currentTarget.value;

	}

	const fields = getRequiredFields();

	if(!user) {
		return (<h2>BZZZ</h2>);
	}

	return (
		
	<div className={classes.container}>
		<div className={classes.message}>
			<Typography variant="h6" component="p">
				Selecione um CSV com os seguintes campos
			</Typography>
			{Object.keys(fields).map(fieldId => (
				
				<Typography key={fieldId} className={classes.field} variant="body2" component="p">
				  {fields[fieldId]}
				</Typography>

			))}


		</div>
		{error && <Snackbar
		  variant="error"
		  className={classes.margin}
		  message={error}
			onClose={() => setError('')}
		/>}

		<input
			onChange={handleFileSelect}
      accept=".csv"
      className={classes.hiddenInput}
      id="contained-button-file"
      type="file"
    />
    <label htmlFor="contained-button-file">
      <LoadingButton
				component="span"
				variant="contained"
				color={'primary'}
				loading={parsing}
				disabled={parsing}
				className={classes.button}
			>
       	Selecionar csv
        <CloudUploadIcon className={classes.rightIcon} />
      </LoadingButton>
    </label>

	</div>
	);
}

const checkupPageStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
	checkbox: {
		margin: theme.spacing(1),
	},
	listContainer: {
	},
}));

function CheckupPage(props) {
	
	let { routes: data } = props;

	const classes = checkupPageStyles();

	const [ canSend, letSend ] = useState(false);

	const parsedData = {}

	Object.keys(data).map(groupId => {
		log.i('CHecking routes for group', groupId);
		let groupRoutes = data[groupId];
		log.i('Loaded routes', groupRoutes);
		let r = {};
		Object.keys(groupRoutes).map(routeId => {
			log.i('RouteId', routeId, 'loaded');
			let route = groupRoutes[routeId];
			log.i('It has', route.length, 'entries');
			r[routeId] = route.length;
		});
		return parsedData[groupId] = r;
	});

	log.i('ParsedData is', parsedData);

	return (
	<div className={classes.listContainer}>
		<RoutesList
			data={parsedData}
		/>

      <FormControlLabel
				className={classes.checkbox}
        control={
     			<Checkbox
     			  checked={canSend}
     			  onChange={() => letSend(canSend => !canSend)}
     			  value="Whatever?"
     			  inputProps={{
     			    'aria-label': 'Agreement checkbox',
     			  }}
     			/>
        }
        label={
					<Typography type="body2" style={{ fontSize: '12px' }}>
						Tenho ciência que os dados enviados afetarão as rotas listadas acima e que todo envio é registrado junto a meu usuário para futura auditoria.
					</Typography>
				}
      />

		<div>
			<Button
				className={classes.button}
				variant="contained"
				onClick={props.onCancel}
			>
				Corrigir
			</Button>
			
			<Button
				className={classes.button}
				color="primary"
				variant="contained"
				disabled={!canSend}
				onClick={props.onConfirm}
			>
				Enviar
			</Button>
		</div>
	</div>
	);
}


function AllFinishedPage(props) {
	return (
		<h1>GG</h1>
	);
}

/*
function uploadCsvFile(file) {

	return new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve();
		}, 2000);
	});

}

function sendRoutes(routes, monitor) {

	let data = [];

	if(routes) {
	Object.keys(routes).map(groupId => {
		Object.keys(routes[groupId]).map(routeId => {
			data.push(1);
		});
	});
	} else data = [1,1,1,1,1,1,1]

	let completed = 0;
	return new Promise((resolve, reject) => {
		data.map((d, index) => {
			setTimeout(() => {
				completed++;
				monitor(completed, data.length);
				if(completed >= data.length) resolve();
			}, 500*(index+1) );
		});
	});
}

function commitChanges() {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve()
		}, 2000 );
	});
}
*/
function SendToServerPage(props) {

	const { user, routes, file, branch: branchId } = props;

	const [ uploading, setUploading ] = useState(false);
	const [ completed, setCompleted ] = useState(false);

	const [ totalSent, setTotalSent ] = useState(5);
	const [ text, setText ] = useState('Conectando ao servidor');

	const monitor = (done, total, message) => {
			setText(message || `Enviando rota #${done} de ${total}`);
			setTotalSent(5 + 85*done/total);
	}

	/*
	useEffect(() => {
		uploadCsvFile(file).then(() => {
			setUploading(true);
			setText('Enviando rotas', routes);
			sendRoutes(routes, branchId, monitor).then(() => {
				log.i('Routes sent. Now updating catalog with routes', routes);
				setText('Salvando alterações');
					setTimeout(() => {
				commitChanges().then(() => {
						setCompleted(true);
						setTotalSent(100);
						setText('Finalizado!');
						finish();

				});
					}, 500);
			});
		});
	}, []);
	*/

	console.log('if I were to send it would be with branch', branchId);

	useEffect(() => {
		uploadCsvFile(file).then(() => {
			setUploading(true);
			setText('Enviando rotas', routes);
			sendRoutes(file, branchId, routes, user, monitor).then(() => {
				log.i('Completing');
				setCompleted(true);
				setTotalSent(100);
				setText('Finalizado!');
				finish();
			});
		});
	}, []);

	function finish() {
		props.onComplete();
	}

	return (
		<div style={{
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		}}>
			<div style={{ position: 'relative', 
			}}>
				{completed && <DoneIcon style={{ fontSize: 64 , position: 'absolute', top: '50%', marginTop: '-32px', left: '50%', marginLeft: '-32px' }} />}
    	  <CircularProgress color="primary" size={128} variant={ (completed) ? 'static' : 'indeterminate'} value={totalSent} />
			</div>
			<Typography style={{ padding: '16px' }} variant="h6" component="p">
				{text}
			</Typography>
		</div>
	);
}

function getStepPage(step) {
  switch (step) {
    case 0:
      return UploadPage;
    case 1:
      return CheckupPage;
    case 2:
      return SendToServerPage;
		case 3:
			return AllFinishedPage;
    default:
      return 'Unknown step';
  }
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Select campaign settings...';
    case 1:
      return 'What is an ad group anyways?';
    case 2:
      return 'This is the bit I really care about!';
    default:
      return 'Unknown step';
  }
}

export default function HorizontalLinearStepper(props) {
  const classes = useStyles();

	const { user, branch, letCancel, onComplete } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

	const [ routes, setRoutes ] = useState();
	const [ csvFile, setCsvFile ] = useState();

	//const [ canSend, letSend ] = useState(false);

  function isStepSkipped(step) {
    return skipped.has(step);
  }

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }
	let next = handleNext;

  function handleBack() {
		setCsvFile();
		setRoutes();
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }
	let previous = handleBack;

  function handleReset() {
    setActiveStep(0);
  }

	function handleFileSelect(file) {

	}

	function handleRouteLoad(routesData, file) {
		setCsvFile(file);
		setRoutes(routesData);
		next();
	}

	function handleConfirm() {
		log.i('handleconfirm');
		letCancel(false);
		next();
	}

	function handleCancel() {
		log.i('handlecancel');
		previous();
	}

	function handleComplete() {
		log.i('handlecomplete');
//		next();
		setTimeout(() => {
			onComplete();
		}, 2000);
	}

	const PageContent = getStepPage(activeStep);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
          <div className={classes.pageContent}>
						<PageContent
							onFileSelect={handleFileSelect}
							onRouteLoad={handleRouteLoad}
							onConfirm={handleConfirm}
							onCancel={handleCancel}
							onComplete={handleComplete}
							routes={routes}
							file={csvFile}
							user={user}
							branch={branch}
						/>
          </div>
    </div>
  );
}
