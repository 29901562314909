import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';

import { Link } from 'react-router-dom';

import { useTheme } from '@material-ui/styles';

import { Select, MenuItem } from '@material-ui/core';

import Progress from 'components/ProgressSpinner';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';

const monthMap = {
	1: 'Janeiro',
	2: 'Fevereiro',
	3: 'Março',
	4: 'Abril',
	5: 'Maio',
	6: 'Junho',
	7: 'Julho',
	8: 'Agosto',
	9: 'Setembro',
	10: 'Outubro',
	11: 'Novembro',
	12: 'Dezembro',
}

const today = new Date();
const todaysYear = today.getFullYear();
const todaysMonth = today.getMonth() + 1;

const minYear = 2019;

const useStyles = makeStyles((theme) => ({
  root: {},
  chartContainer: {
    height: 432,
    position: 'relative',
		paddingTop: theme.spacing(2),
  },
	content: {
		flexGrow: 1,
		padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    position: 'relative'
	},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestSales = props => {
	console.log('WTFUCKCKCKCK', props);
  const {
		className,
		branch,
		loading,
		year,
		month,
		onMonthChange,
		onYearChange,
		onDayChange,
		...rest
	} = props;

  const classes = useStyles();

	const { palette } = useTheme();
	console.log(palette);

	const [ day, setDay ] = useState(todaysMonth);

	let maxMonth = 12;

	if(todaysYear === year) {
		maxMonth = todaysMonth;
	}

	const yearsArray = [...new Array(1 + todaysYear - minYear)];
	const monthsArray = [...new Array(maxMonth)];

  return (
				<>

				<Select
					style={{marginTop: '8px', marginLeft: '8px', marginRight: '8px'}}
					disabled={loading || monthsArray.length <= 1}
          value={month}
          onChange={e => onMonthChange(e.target.value)}
          inputProps={{
            name: 'month',
            id: 'month-filter',
          }}
        >
					{monthsArray.map((_,index) => (<MenuItem key={index} value={index+1}>{monthMap[index+1]}</MenuItem>) )}
        </Select>

				<Select
					style={{marginTop: '8px', marginLeft: '8px', marginRight: '8px'}}
					disabled={loading || yearsArray.length <= 1}
          value={year}
          onChange={e => onYearChange(e.target.value)}
          inputProps={{
            name: 'year',
            id: 'year-filter',
          }}
        >
					{yearsArray.map((_,index) => (<MenuItem key={index} value={minYear + index}>{minYear + index}</MenuItem>) )}
        </Select>

				</>
  );
};

export default LatestSales;
