const axios = require('axios');

const moment = require('moment');

const http = axios.create({
//	baseURL: 'https://34.95.172.222'
	baseURL: 'https://analytics.dcod.com.br'
	//baseURL: 'https://hanalytics.dcod.com.br'
});

/*
// Alter defaults after instance has been created
http.defaults.headers.common['resourceId'] = '0AUykpuj6xEncneZJLSs';
http.defaults.headers.common['clientId'] = 'u4xjBF9iDiNj1bkjj38wsGmeiP03';
http.defaults.headers.common['resourceVersion'] = '1.8';
*/

function distance(lat1, lon1, lat2, lon2, unit = 'K') {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}


function generateGssData(arr = []) {
	return arr.map(a => {
		const { latitude, longitude, value } = a;
		if(value && value > 0) {
			const skew = Math.random()/2; //Up to +-25% error%
			const gssValue = (+value * (0.75 + skew)).toFixed(0) + "";
			console.log('Converted value', value, 'to gssValue', gssValue, '-', 0.75 + skew , ' multiplier');

		}

	});
}

function dateToFullString(date) {
	return `${date.getFullYear()}/${("0" + (date.getMonth() + 1)).substr(-2)}/${("0" + (date.getDate())).substr(-2)}`
}

/*
function getDailyCaptures(branch) {
	console.log('Loading data for branch', branch);
	const data = branches[branch] || [];

	const acc = {};

	console.log('Got data', data);

	data.forEach(capture => {
		const date = new Date(capture.captureTimestamp);
		const calendarDate = dateToFullString(date);
//		const calendarDate = `${date.getFullYear()}/${("0" + (date.getMonth() + 1)).substr(-2)}/${("0" + (date.getDate())).substr(-2)}`
		let totalSoFar = acc[calendarDate] || 0;
		acc[calendarDate] = totalSoFar + 1;
	});

	return acc;
	
}
*/

function getGlobalNumbers(params) {
	console.log('Loading globals with params', params);
	return http.get('/globals', { params, withCredentials: true})
		.then(response => Promise.resolve(response.data))
		.catch(err => Promise.reject(err));
}




function getCapturesOnDate(branch, year, month, day) {
	//const dailyAcc = getDailyCaptures(branch);
	let total = 0;
	let acc = {};

	const format = 'x'; //'DD/MM/YYYY'
	const dateString = `${year || 2019}-${month || 1}-${day || 1}`;
	const initialDate = new Date(dateString);
//	console.log('year', year, 'month', month, 'day', day, `(${dateString})`);
	

	let finalDate;
	if(day) {
		finalDate = m(initialDate).add(1, 'd').format(format);
	} else if(month) {
		finalDate = m(initialDate).add(1, 'M').format(format);
	} else if(year) {
		finalDate = m(initialDate).add(1, 'y').format(format);
	}
//	console.log('Finaldate:', finalDate);
	let [i, f] = [m(initialDate).format(format), finalDate];
	console.log('GCODDDD Going from date', i, 'to date', f, 'for', `${year}/${month}/${day}`);

	let a = new Date(+i);
	let b = new Date(+f);

	console.log("FUCKYOU", a, new Date(i), i, typeof(i));

	let today = new Date();

    const params = {branchId: branch, year, month};

	return http.get('/capturesCounter', { params, withCredentials: true})
    .then(response => {
		console.log('OMG IMMOMM', response);

		const entries = response.data;

		console.log('Parsing from', a,'to', b);

		while(a.getTime() < b.getTime() && a.getTime() < today.getTime() ) {
			const dateString = dateToFullString(a);
//			const dailyCaptures = data.find(d => (d.getMonth + 1)) //dailyAcc[dateString] || 0;

			const year = a.getFullYear();
			const month = a.getMonth() + 1;
			const day = a.getDate();

			const dailyEntry = entries.find(e => e.day === day && e.month === month && e.year === year) || {};

			console.log('Loaded entry', dailyEntry, 'for date', dateString);
			const dailyCaptures = dailyEntry.count || 0;

			//const dailyCaptures = dailyAcc[dateString] || 0;
			total += dailyCaptures;
			acc[dateString] = dailyCaptures;

			a.setDate(a.getDate() + 1);
		}

		return Promise.resolve({
					total,
					histogram: acc,
		});

	}).catch(err => {
		return Promise.reject(err);	
	});


}

function getCapturesGroupedByMonth(branch, year = 2019) {
	const dailyAcc = []//= getDailyCaptures(branch);
	let total = 0;
	let acc = {};
	
	let initialDate = new Date(`${year}/1/1`);
	let finalDate = new Date(`${year + 1}/01/01`)

	console.log('GCGBM Going from date', initialDate, 'to date', finalDate, 'for');

	let a = initialDate;
	let b = finalDate;

	let today = new Date();

	while(a.getTime() < b.getTime() && a.getTime() < today.getTime() ) {
		const currentMonth = a.getMonth() + 1
		const dateString = dateToFullString(a);
		const dailyCaptures = dailyAcc[dateString] || 0;
		total += dailyCaptures;
		acc[dateString] = dailyCaptures;

		a.setDate(a.getDate() + 1); 
	}

	return new Promise((res) => {
		setTimeout(() => {
			res({
				total,
				histogram: acc,
			});
		}, getMockDelay())
	});

}


function getCapturesDisparity(params) {
	return new Promise((resolve) => {
		http.get('/disparities', { params, withCredentials: true})
			.then(response => {
				console.log('Got /disparities response', response);
				resolve(response.data);
			})
			.catch(err => {
				resolve([]);
			});
	});
}


function getCapturesStatus(params) {
	return new Promise((resolve) => {
		http.get('/capturesStatus', { params, withCredentials: true })
			.then(response => {
				console.log('Got /capturesStatus response', response);
				resolve(response.data);
			})
			.catch(err => {
				resolve([]);
			});
	});
}



function getCapturesByYear(params) {
	return new Promise((resolve) => {
		http.get('/capturesDistributionByDate', { params, withCredentials: true })
			.then(response => {
				console.log('Got response', response);
				resolve(response.data);
			})
			.catch(err => {
				resolve([]);
			});
	});
}

function getRoutesByMonth(params) {
	return new Promise((resolve) => {
		http.get('/routesDistributionByDate', { params, withCredentials: true })
			.then(response => {
				console.log('Got response', response);

	let total = 0;
	let acc = {};

	const { year, month, day } = params;

	const format = 'x'; //'DD/MM/YYYY'
	const dateString = `${year || 2019}-${month || 1}-${day || 1}`;
	const initialDate = new Date(dateString);
//	console.log('year', year, 'month', month, 'day', day, `(${dateString})`);
	

	let finalDate;
	if(day) {
		finalDate = m(initialDate).add(1, 'd').format(format);
	} else if(month) {
		finalDate = m(initialDate).add(1, 'M').format(format);
	} else if(year) {
		finalDate = m(initialDate).add(1, 'y').format(format);
	}
//	console.log('Finaldate:', finalDate);
	let [i, f] = [m(initialDate).format(format), finalDate];
	console.log('GCODDDD Going from date', i, 'to date', f, 'for', `${year}/${month}/${day}`);

	let a = new Date(+i);
	let b = new Date(+f);

	console.log("FUCKYOU", a, new Date(i), i, typeof(i));



	let today = new Date();

	let entries = response.data;

	while(a.getTime() < b.getTime() && a.getTime() < today.getTime() ) {

			const dateString = dateToFullString(a);


			const year = a.getFullYear();
			const month = a.getMonth() + 1;
			const day = a.getDate();

			const dailyEntry = entries.find(e => e.day === day && e.month === month && e.year === year) || {};

			console.log('Loaded entry', dailyEntry, 'for date', dateString);
			const dailyCaptures = +dailyEntry.count || 0;

			//const dailyCaptures = dailyAcc[dateString] || 0;
			total += dailyCaptures;
			acc[day] = dailyCaptures;
/*
		const currentMonth = a.getMonth() + 1
		const dateString = dateToFullString(a);
		const dailyCaptures = dailyAcc[dateString] || 0;
		total += dailyCaptures;
		acc[dateString] = dailyCaptures;
*/
		a.setDate(a.getDate() + 1); 
	}

				resolve(acc);
			})
			.catch(err => {
				resolve([]);
			});
	});
}

function getExistingBranches() {
	return new Promise((resolve) => {
		http.get('/branches', { withCredentials: true })
			.then(response => {
				console.log('Got response', response);
				resolve({branches: ['teresina']});
//				resolve(response.data);
			})
			.catch(err => {
				resolve([]);
			});
	});
}





function listDailyRoutes(params) {
	return new Promise((resolve) => {
		http.get('/listRoutes', { params, withCredentials: true })
			.then(response => {
				console.log('Got response', response);
				resolve(response.data);
			})
			.catch(err => {
				resolve([]);
			});
	});
}



function getRequestCaptures(params) {
	console.log('getRequestCaptures with params', params);
	return new Promise((resolve) => {
		http.get('/listRequestCaptures', { params, withCredentials: true })
			.then(response => {
				console.log('Got response', response);
				resolve(response.data);
			})
			.catch(err => {
				resolve([]);
			});
	});
}

function listCaptures(params) {
	console.log('listCaptures with params', params);
	return new Promise((resolve) => {
		http.get('/capturesList', { params, withCredentials: true })
			.then(response => {
				console.log('Got /listCaptures response', response);
				resolve(response.data);
			})
			.catch(err => {
				resolve([]);
			});
	});
}


function getMeterTypeDistribution(params) {
	return new Promise((resolve) => {
		http.get('/meterTypeDistribution', { params, withCredentials: true })
			.then(response => {
				console.log('Got /meterTypeDistribution response', response);
				resolve(response.data);
			})
			.catch(err => {
				resolve([]);
			});
	});
}

function getExistingDevices(params) {
	console.log('getExistingDevices with params', params);
	return new Promise((resolve) => {
		http.get('/listDevices', { params, withCredentials: true })
			.then(response => {
				console.log('Got /listDevices response', response);
				resolve(response.data);
			})
			.catch(err => {
				resolve([]);
			});
	});
}

function getDeviceCaptures(params) {
	console.log('getDeviceCaptures with params', params);
	return new Promise((resolve) => {
		http.get('/countDeviceCaptures', { params, withCredentials: true})
			.then(response => {
				console.log('Got /countDeviceCaptures response', response);
				resolve(response.data);
			})
			.catch(err => {
				resolve([]);
			});
	});
}

function getDeviceCapturesHistogram(params) {
	console.log('getDeviceCapturesHistogram with params', params);
	return new Promise((resolve) => {
		http.get('/deviceCapturesHistogram', { params, withCredentials: true  })
			.then(response => {
				console.log('Got /deviceCapturesHistogram response', response);
				resolve(response.data);
			})
			.catch(err => {
				resolve([]);
			});
	});
}




function getExistingUsers(params) {
	console.log('getExistingDevices with params', params);
	return new Promise((resolve) => {
		http.get('/listUsers', { params, withCredentials: true })
			.then(response => {
				console.log('Got /listUsers response', response);
				resolve(response.data);
			})
			.catch(err => {
				resolve([]);
			});
	});
}

function getUserCaptures(params) {
	console.log('getUserCaptures with params', params);
	return new Promise((resolve) => {
		http.get('/countUserCaptures', { params, withCredentials: true })
			.then(response => {
				console.log('Got /countUserCaptures response', response);
				resolve(response.data);
			})
			.catch(err => {
				resolve([]);
			});
	});
}

function getUserCapturesHistogram(params) {
	console.log('getUserCapturesHistogram with params', params);
	return new Promise((resolve) => {
		http.get('/userCapturesHistogram', { params, withCredentials: true })
			.then(response => {
				console.log('Got /userCapturesHistogram response', response);
				resolve(response.data);
			})
			.catch(err => {
				resolve([]);
			});
	});
}





function getMockDelay(min = 300, max = 1000) {
	return min + (max-min)*Math.random();
	
}

/*
generateGssData(test);
getDailyCaptures('mirante');
console.log('ONAONONONDATE');
getCapturesOnDate('test');
getCapturesOnDate('mirante', 2019, 07);
getCapturesOnDate('mirante', 2019, 07, 30);
getCapturesOnDate('mirante', 2019, 06, 30);
*/

module.exports = {
	//getExistingBranches,
	getBranchCapturesByDate: getCapturesOnDate,
	getMontlyCaptures: getCapturesOnDate,
	getCapturesGroupedByMonth,
	getGlobalNumbers,
	getCapturesByYear,
	getRoutesByMonth,
	listDailyRoutes,
	getRequestCaptures,
	getCapturesStatus,
	getCapturesDisparity,
	listCaptures,
	getMeterTypeDistribution,

	getExistingDevices,
	getDeviceCaptures,
	getDeviceCapturesHistogram,

	getExistingUsers,
	getUserCaptures,
	getUserCapturesHistogram,

}


const m = moment //require('moment');
function rangeIt(year, month, day) {
	const format = 'DD/MM/YYYY'
	const dateString = `${year || 2019}-${month || 1}-${day || 1}`;
	const initialDate = new Date(dateString);
//	console.log('year', year, 'month', month, 'day', day, `(${dateString})`);
	

	let finalDate;
	if(year) {
		finalDate = m(initialDate).add(1, 'y').format(format);
	} else if(month) {
		finalDate = m(initialDate).add(1, 'M').format(format);
	} else if(day) {
		finalDate = m(initialDate).add(1, 'd').format(format);
	}
//	console.log('Finaldate:', finalDate);
	return [m(initialDate).format(format), finalDate];
}
