import React, { useState } from 'react';

import CardsPanel from './CardsPanel';
import Card from './CaptureCard';
import Table from './Table';
import { Redirect } from 'react-router-dom';

import NoPicturesIcon from '@material-ui/icons/LocationOff';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { withStyles } from '@material-ui/core/styles'

import { post, onNewData } from '../../../modules/Users';

import Logger from '../../../modules/Logger';
const log = Logger('UsersMaster');

const styles = theme => ({
	pageRoot: {
		padding: '16px',
		margin: '8px',
		backgroundColor: '#4f4f4f',
		display: 'flex',
		flexWrap: 'wrap',
	},
	cardContainer: {
		margin: '16px',
	},
});

const myRows = [
	{ id: 'name', label: 'Nome' },
	{ id: 'user', label: 'Usuário' },
];

function MasterGrid(props) {

	let { classes, onPageDataRequest, user } = props;

	let [ pageNumber , setPageNumber ] = useState(0);
	let [ rowsPerPage, setRowsPerPage ] = useState(10);

	let [ dataLength, setDataLength ] = useState(0);

	let [ order, setOrder ] = useState('asc');
	let [ idToOrderBy, setIdToOrderBy ] = useState('');

	let [ loading, setLoading ] = useState(true);
	let [ formLoading, setFormLoading ] = useState(false);

	let [ tableData, setTableData ] = useState([]);

	let [ redirectTo, setRedirectTo ] = useState();

	let [ showForm, setShowForm ] = useState(false);
	let [ formError, setFormError ] = useState();

	let handleChangePage = (event, page) => {
		setPageNumber(page);
	};

	let handleChangeRowsPerPage = event => {
		setRowsPerPage(event.target.value);
		setPageNumber(0);
	};
	
	React.useEffect(() => {
		
		setLoading(true);
		return onNewData(response => {
			log.i('GOT DATA', response);
			setTableData(response.data);
			setDataLength(response.total);
			setLoading(false);
		});
	}, []);

	let handleDetailsClick = (dataId) => e => {
		log.i('CLicked on id', dataId);	
	}

	let handleSubmit = (data) => {
		log.i('submitting', data);
		setFormError();
		setFormLoading(true);
		post(data).then(() => {
			setShowForm(false);
			setFormLoading(false);
		})
		.catch(err => {
			log.e(err);
			let errorMessage;
			switch(err.code) {
				case 'auth/invalid-email': {
					errorMessage = 'E-mail com formato inválido';
					break;
				}
				case 'auth/email-already-in-use': {
					errorMessage = 'E-mail já cadastrado';
					break;
				}
				case 'auth/weak-password': {
					errorMessage = 'Senha muito curta';
					break;
				}
				default: {
					errorMessage = 'Falha no cadastro.';
				}
			}
			setFormError(errorMessage);
			setFormLoading(false);
		});
	}

	if(!!redirectTo) {
		return <Redirect push to={redirectTo} />
	}

	return (
		<Table {...props} 
			canSelect={false}
			title="Usuários"
			rows={myRows}
			data={tableData}
			orderBy={idToOrderBy}
			order={order}
			onSort={(id, order) => e => {
				setOrder(order);
				setIdToOrderBy(id);
			}}
			loading={loading}
			showForm={showForm}
			formLoading={formLoading}
			onFormSubmit={handleSubmit}
			onFormCancel={() => setShowForm(false)}
			onButtonClick={() => setShowForm(true)}
			formError={formError}
			user={user}
		/>
	);
}

export default withStyles(styles)(MasterGrid);
