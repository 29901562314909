import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import Card from '../CardsPanel';
import { Select, MenuItem } from '@material-ui/core';

import CapturesByMonth from './CapturesByMonth';
import CapturesStatusDistribution from './StatusDistribution';
import CapturesDistributionByDevice from './CapturesDistributionByDevice';
import LatestCaptures from './LatestCaptures';
import DivergenceDistribution from './DivergenceDistribution';

//import DevicesCaptures from './DevicesCaptures';
import DevicesCaptures from './CapturesByMonth';
import CapturesByDevice from './CapturesByDevice';

import Maps from './Maps';

import { useUser } from 'UserProvider';

import DateSelector from 'components/NewDateSelector';

import {
  getBranchCapturesByDate,
} from 'modules/Analytics';

const useStyles = makeStyles(theme => ({
  dashRoot: {
    padding: theme.spacing(2),
    paddingTop: 0,
  }
}));

const today = new Date();
const todaysYear = today.getFullYear();
const todaysMonth = today.getMonth() + 1;
const todaysDay = today.getDate();

const Dashboard = (props) => {
  const classes = useStyles();

	const user = useUser();
	const branches = user.branches;

	const [ loading, setLoading ] = useState(true);
//	const [ branches, setBranches ] = useState([]);
	const [ selectedBranch, setSelectedBranch ] = useState(branches[0]);

	console.log('BraNCHEs Here ARE', branches);

//	const { branchId : userBranchId } = useUser();

	const [ day, setDay ] = useState(todaysDay);
	const [ month, setMonth ] = useState(todaysMonth);
	const [ year, setYear ] = useState(todaysYear);


  return (
	<>
		<Card
			title="Visão geral"
			titleExtra={
				<Select
					style={{marginTop: '8px', marginLeft: '8px', marginRight: '8px'}}
					disabled={branches.length <= 1}
          value={selectedBranch}
          onChange={e => setSelectedBranch(e.target.value)}
          inputProps={{
            name: 'branch',
            id: 'branch-selector',
          }}
        >
					{branches.map(branchName => (<MenuItem key={branchName} value={branchName}>{branchName}</MenuItem>) )}
        </Select>
			}

			actions={() => (
				<div style={{display: 'flex', marginBottom: '8px'}}>
				<DateSelector
					month={month}
					onMonthChange={setMonth}
					year={year}
					onYearChange={setYear}
				/>
				</div>
			)}
		>
	    <div className={classes.dashRoot}>
	      <Grid
	        container
	        spacing={2}
	      >
	        <Grid item xs={12}>
	          <CapturesByDevice
							branch={selectedBranch}
							month={month}
							year={year}
						/>
	        </Grid>

	        <Grid item xs={12}>
	          <DevicesCaptures
							branch={selectedBranch}
							month={month}
							year={year}
						/>
	        </Grid>
	
	      </Grid>
	    </div>
		</Card>


		<div style={{ height: '16px' }} />


		<Maps 
			branch={selectedBranch}
							month={month}
							year={year}
		/>

	</>


  );
};

export default Dashboard;
