import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	picturePreviewRoot: {
		position: 'relative',
		padding: theme.spacing(1),
	},
	crop: {
		height: '150px',
		width: '100%',
		overflow: 'hidden',
	},
	fill: {
		width: '375px',
		height: 'auto',
		marginTop: '-100px',
		marginLeft: '-50px',
	},
	loading: {
		position: 'relative',
		height: '304px',
  },
	show: {
		display: 'show',
	},
	hide: {
		display: 'none',
	},
}));

export default function PicturePreview(props) {

	const classes = useStyles();

	const {
		imageUrl,// = 'https://firebasestorage.googleapis.com/v0/b/d992983fc.appspot.com/o/thumbnails%2FoS7yrZlnWwz2W97aJqfr?alt=media&token=85b2026c-eecc-4be9-9a24-3ec666227927',
	} = props;

	let [ loaded, setLoaded ] = React.useState(false);

	let handleLoaded = () => {
		console.log('Loaded image');
		setLoaded(true);
	}

	return (
		<div className={classes.picturePreviewRoot}>
			<div
				className={`${classes.crop} ${loaded ? classes.show : classes.hide}`}
			>
    	  <img
					className={classes.fill}
    	    component="img"
					draggable="false"
    	    alt="Capture thumbnail"
					height="150"
    	    src={imageUrl}
					onLoad={handleLoaded}
    	  />
			</div>
			{!loaded && <div style={{height:'150px', width:'100%'}}>
				<CircularProgress color="primary" style={{position:'absolute', top:'75px', left:'50%', marginTop:'-20px', marginLeft:'-20px'}} size={40}/>
			</div>}
		</div>
	);
}
