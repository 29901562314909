import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import FilterListIcon from '@material-ui/icons/FilterList';
import NoPicturesIcon from '@material-ui/icons/Person';

import Fab from '@material-ui/core/Fab';

import RegisterForm from './RegisterForm';

import CircularProgress from '@material-ui/core/CircularProgress';

import { lighten } from '@material-ui/core/styles/colorManipulator';

class EnhancedTableHead extends React.Component {
	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

		const {
			rows,
			showSelection = false, 
		} = this.props;

		return (
			<TableHead>
				<TableRow>
					{showSelection && <TableCell padding="checkbox">
						<Checkbox
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={numSelected === rowCount}
							onChange={onSelectAllClick}
						/>
					</TableCell>}
					{rows.map(
						(row, index) => (
							<TableCell
								key={row.id}
								align={row.numeric ? 'right' : 'left'}
								padding={showSelection && index === 0 ? 'none' : 'default'}
								sortDirection={orderBy === row.id ? order : false}
							>

								{!row.sortable 
								?
									row.label
									
							:
								(<Tooltip
									title="Sort"
									placement={row.numeric ? 'bottom-end' : 'bottom-start'}
									enterDelay={300}
								>
									<TableSortLabel
										active={orderBy === row.id}
										direction={order}
										onClick={this.props.onSort(row.id, order === 'desc' ? 'asc' : 'desc')}
									>
										{row.label}
									</TableSortLabel>
								</Tooltip>)
								}
							</TableCell>
						),
						this,
					)}
				</TableRow>
			</TableHead>
		);
	}
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
	root: {
		paddingRight: theme.spacing.unit,
	},
	highlight:
		theme.palette.type === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
				}
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
				},
	spacer: {
		flex: '1',
	},
	actions: {
		margin: '16px',
	},
	title: {
		flex: '0 0 auto',
	},
});

let EnhancedTableToolbar = props => {
	const { numSelected, classes } = props;

	return (
		<Toolbar className={classes.root}>
			<div className={classes.title}>
				<Typography variant="h6" id="tableTitle">
					{props.title}
				</Typography>
			</div>
			<div className={classes.spacer} />
			<div className={classes.actions}>
			<Tooltip title="Adicionar usuário">
      	<Fab size="small" variant="contained" color="primary" aria-label="Adicionar usuário" className={classes.fab} onClick={props.onButtonClick}>
					<PersonAddIcon />
	      </Fab>
			</Tooltip>

			</div>
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
	root: {
		width: '100%',
	},
	table: {
	},
	tableWrapper: {
		overflowX: 'auto',
	},
	noPictureIcon: {
		width: 100,
		height: 100,
		marginBottom: '8px',
		color: theme.palette.text.secondary,
	},
	noPictureText: {
		color: theme.palette.text.secondary,
	}
});

class EnhancedTable extends React.Component {
	state = {
		selected: [],
		showForm: false,
	};

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}

		this.setState({ order, orderBy });
	};

	handleSelectAllClick = event => {
		if (event.target.checked) {
			this.setState(state => ({ selected: state.data.map(n => n.id) }));
			return;
		}
		this.setState({ selected: [] });
	};

	handleClick = (event, id) => {

		const { selected } = this.state;
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		this.setState({ selected: newSelected });
	};

	isSelected = id => this.state.selected.indexOf(id) !== -1;

	render() {
		const { classes } = this.props;
		const { data, order, orderBy, selected = [], rowsPerPage, page } = this.props;
		let emptyRows = Math.min(rowsPerPage, this.props.rowsCount) - Math.min(rowsPerPage, data.length);

		return (
			<Paper className={classes.root} elevation={10}>
				{this.props.title && <EnhancedTableToolbar
				numSelected={selected.length}
				title={this.props.title}
				onButtonClick={this.props.onButtonClick}
			/>}
					{this.props.showForm &&	<RegisterForm
							onCancel={this.props.onFormCancel}
							onSubmit={this.props.onFormSubmit}
							loading={this.props.formLoading}
							error={this.props.formError}
							user={this.props.user}
						/>}
				{data.length === 0 ?
					<div style={{
						display: 'flex',
						position:'relative',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						height:'300px',
						width:'100%',
					}}>

							{this.props.loading ?
											<div style={{position: 'absolute', top: '50%', left: '50%', marginTop: '-20px', marginLeft: '-20px'}} >
													<CircularProgress className={classes.progress} />
											</div>
							:
								<div style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									marginTop: '-50px',
								}}>
									<NoPicturesIcon className={classes.noPictureIcon} />

		    			    <Typography variant="body1" className={classes.noPictureText} component="p">
		    			      Nenhum usuário cadastrado
									</Typography>
								</div>
							}
						</div>
				:
				<div className={classes.tableWrapper}>
					<Table className={classes.table} aria-labelledby="tableTitle">
						<EnhancedTableHead
							numSelected={selected.length}
							order={this.props.order}
							orderBy={this.props.orderBy}
							onSelectAllClick={this.handleSelectAllClick}
							onRequestSort={this.handleRequestSort}
							rowCount={data.length}
							rows={this.props.rows}
							onSort={this.props.onSort}
							showSelection={this.props.canSelect}
						/>
						<TableBody>

							{this.props.loading && (
									<TableRow style={{ height: 50 * (rowsPerPage || 5) }}>
										<TableCell colSpan={this.props.rows.length} align="center" style={{ position: 'relative'}} >

									<div style={{position: 'absolute', top: '50%', left: '50%', marginTop: '-20px', marginLeft: '-20px'}} >
											<CircularProgress className={classes.progress} />
									</div>
										</TableCell>
									</TableRow>
							)}

							{!this.props.loading && data
								.map(n => {
									const isSelected = this.isSelected(n.id);
									return (
										<TableRow
											role="checkbox"
											aria-checked={isSelected}
											tabIndex={-1}
											key={n.id}
											selected={isSelected}
										>
											{this.props.canSelect && <TableCell padding="checkbox">
												<Checkbox checked={isSelected} />
											</TableCell>}
											{this.props.rows.map((row, index) => {

												return (
													<TableCell
														key={row.id}
														align={row.numeric ? 'right' : 'left'}
														component="th"
														scope="row"
														padding={this.props.canSelect && index === 0 ? "none" : "default"}
													>
														{n[row.id]}
													</TableCell>
												);
											})}
										</TableRow>
									);
								})}
							{!this.props.loading && emptyRows > 0 && (
								<TableRow style={{ height: 48 * emptyRows }}>
									<TableCell colSpan={this.props.rows.length} />
								</TableRow>
							)}
						
						</TableBody>
					</Table>
				</div>}
				{this.props.onChangePage ?
				<TablePagination
					component="div"
					onChangePage={this.props.onChangePage}
					onChangeRowsPerPage={this.props.onChangeRowsPerPage}
					rowsPerPageOptions={this.props.rowsPerPageOptions}
					count={this.props.rowsCount}
					rowsPerPage={this.props.rowsPerPage}
					page={this.props.pageNumber}
					backIconButtonProps={{
						'aria-label': 'Previous Page',
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page',
					}}
				/>
				:
				<div style={{
					height: '24px',
				}}/>
				}
			</Paper>
		);
	}
}


//const myRows = [
//	{ id: 'name', sortable: true, numeric: false, label: 'Dessert (100g serving)' },
//	{ id: 'calories', sortable: true, numeric: true, label: 'Calories' },
//	{ id: 'fat', numeric: true, label: 'Fat (g)' },
//	{ id: 'carbs', numeric: true, label: 'Carbs (g)' },
//	{ id: 'protein', numeric: true, label: 'Protein (g)' },
//];
//
//let myData = [
//	createData('Cupcake', 305, 3.7, 67, 4.3),
//	createData('Donut', 452, 25.0, 51, 4.9),
//	createData('Eclair', 262, 16.0, 24, 6.0),
//	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//	createData('Gingerbread', 356, 16.0, 49, 3.9),
//	createData('Honeycomb', 408, 3.2, 87, 6.5),
//	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//	createData('Jelly Bean', 375, 0.0, 94, 0.0),
//	createData('KitKat', 518, 26.0, 65, 7.0),
//	createData('Lollipop', 392, 0.2, 98, 0.0),
//	createData('Marshmallow', 318, 0, 81, 2.0),
//	createData('Nougat', 360, 19.0, 9, 37.0),
//	createData('Oreo', 437, 18.0, 63, 4.0),
//]
//
//function fetchData(idToOrderBy, order, pageNumber, rowsPerPage) {
//	let tableData = [...myData];
//	if(idToOrderBy) {
//		tableData = stableSort(tableData, getSorting(order, idToOrderBy));
//	}
//
//	tableData = tableData.slice(pageNumber * rowsPerPage, pageNumber * rowsPerPage + rowsPerPage)
//
//	return new Promise(resolve => {
//		setTimeout(() => {
//			resolve(tableData);
//		}, 500);
//	});
//
//}
//
//function MTable(props) {
//
//
//	let [ pageNumber , setPageNumber ] = useState(0);
//	let [ rowsPerPage, setRowsPerPage ] = useState(10);
//
//	let [ order, setOrder ] = useState('asc');
//	let [ idToOrderBy, setIdToOrderBy ] = useState('');
//
//	let [ loading, setLoading ] = useState(true);
//
//	let [ tableData, setTableData ] = useState([]);
//
//	let handleChangePage = (event, page) => {
//		setPageNumber(page);
//	};
//
//	let handleChangeRowsPerPage = event => {
//		setRowsPerPage(event.target.value);
//	};
//	
//	React.useEffect(() => {
//		setLoading(true);
//		fetchData(idToOrderBy, order, pageNumber, rowsPerPage).then(data => {
//			setTableData(data);
//			setLoading(false);
//		});
//	}, [idToOrderBy, order, pageNumber, rowsPerPage]);
//
//	return (
//		<EnhancedTable {...props} 
//			onRowClick={(id) => log.i('clicked on row', id)}
//			canSelect={false}
//			title="My Table"
//			rows={myRows}
//			data={tableData}
//			orderBy={idToOrderBy}
//			order={order}
//			onSort={(id, order) => e => {
//				setOrder(order);
//				setIdToOrderBy(id);
//			}}
//			onChangePage={handleChangePage}
//			onChangeRowsPerPage={handleChangeRowsPerPage}
//			rowsPerPageOptions={[5,10,25,50]}
//			rowsCount={myData.length}
//			rowsPerPage={rowsPerPage}
//			pageNumber={pageNumber}
//			loading={loading}
//		/>
//	);
//}
//
//function MTableHeader(props) {
//}
//
//function MTableHeaderOption(props) {
//}
//
//function MTableEntry(props) {
//}



EnhancedTable.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);
