import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import InsertChartIcon from '@material-ui/icons/BurstMode';

import Progress from 'components/ProgressSpinner';

import { Link } from 'react-router-dom';

import { Divider, Button, Card, CardContent, CardActions, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
		position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.light,
    marginRight: theme.spacing(0.5)
  }
}));

const TotalProfit = props => {
  const { className, ...rest } = props;

	const {
		loading,
		total = '-',
		latest,
		thisMonth,
		diff
	} = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
			elevation={4}
    >
			{loading && <Progress />}
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
            >
              CAPTURAS REALIZADAS
            </Typography>
            <Typography
              color="inherit"
              variant="h3"
            >
              {total.toLocaleString()}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <InsertChartIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            {thisMonth > 0 ? thisMonth : 'Nenhuma'}
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          >
            {thisMonth > 1 ? 'adicionadas' : 'adicionada'} neste mês
          </Typography>
        </div>
      </CardContent>
			<Divider />
			<CardActions style={{ justifyContent: 'flex-end', padding: 0}}>
    	  <Button color="inherit" component={Link} to="/dash/captures" style={{padding: '8px'}}>
  	     	Capturas
					<ArrowRightIcon />
	      </Button>
			</CardActions>
    </Card>
  );
};

TotalProfit.propTypes = {
  className: PropTypes.string
};

export default TotalProfit;
