import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';


import Progress from 'components/ProgressSpinner';

import { useTheme } from '@material-ui/styles';

import { Select, MenuItem } from '@material-ui/core';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { useUser } from 'UserProvider';
//import { data, options } from './chart';

import {
  getBranchCapturesByDate,
	getCapturesByYear,
} from 'modules/Analytics';

const getColor = (step, numOfSteps) => {
    // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
    // Adam Cole, 2011-Sept-14
    // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
    var r, g, b;
    var h = step / numOfSteps;
    var i = ~~(h * 6);
    var f = h * 6 - i;
    var q = 1 - f;
    switch(i % 6){
        case 0: r = 1; g = f; b = 0; break;
        case 1: r = q; g = 1; b = 0; break;
        case 2: r = 0; g = 1; b = f; break;
        case 3: r = 0; g = q; b = 1; break;
        case 4: r = f; g = 0; b = 1; break;
        case 5: r = 1; g = 0; b = q; break;
    }
    var c = "#" + ("00" + (~ ~(r * 255)).toString(16)).slice(-2) + ("00" + (~ ~(g * 255)).toString(16)).slice(-2) + ("00" + (~ ~(b * 255)).toString(16)).slice(-2);
    return (c);
}

const monthMap = {
	1: 'Janeiro',
	2: 'Fevereiro',
	3: 'Março',
	4: 'Abril',
	5: 'Maio',
	6: 'Junho',
	7: 'Julho',
	8: 'Agosto',
	9: 'Setembro',
	10: 'Outubro',
	11: 'Novembro',
	12: 'Dezembro',
}

const today = new Date();
const todaysYear = today.getFullYear();
const todaysMonth = today.getMonth() + 1;

const minYear = 2019;

const useStyles = makeStyles((theme) => ({
  root: {
		height: '100%',
	},
  chartContainer: {
		height: 429,
    position: 'relative',
		paddingTop: theme.spacing(2),
  },
	content: {
		flexGrow: 1,
		padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    position: 'relative',
		height: 'calc(100% - 64px)',
	},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestSales = props => {
	console.log('WTFUCKCKCKCK', props);
  const { className, ...rest } = props;

  const classes = useStyles();

const { palette } = useTheme();
console.log(palette);

	const { branches } = useUser();

	const [ loading, setLoading ] = useState(true);

//	const [ branches, setBranches ] = useState([]);
	const [ branchCaptures, setBranchCaptures ] = useState(branches[0]);

	const [ year, setYear ] = useState(todaysYear);
	const [ month, setMonth ] = useState(todaysMonth);

	const [ groupedCaptures, setGroupedCaptures ] = useState({});
	
	const [ monthlyData, setMonthlyData ] = useState([]);

	React.useEffect(() => {
		setLoading(true);
		getCapturesByYear({ year }).then(data => {
			console.log('LLLL!', data)
			let branches = new Set();
			data.forEach(d => branches.add(d.branchid));
			branches = Array.from(branches);
			console.log('LLLL!', branches);
			const datasetss = branches.map((branch, index) => {
				const entries = [];
				for(let month = 1; (year !== todaysYear && month <= 12) || (year === todaysYear && month <= todaysMonth) ; month++) {
					const { count = 0 } = data.find(d => d.branchid === branch && d.month === month) || {};
					console.log(+count, 'on month', month, 'for branch', branch);
					entries.push(+count);	
				}
				return {
					label: branch,
					backgroundColor: getColor(index, branches.length),
					data: entries,
				};
			});
			console.log('LLLLLL', datasetss);
			setDatasets(datasetss);
			setLoading(false);
		});
	}, [year]);

/*
	React.useEffect(() => {
		branches.forEach(branch => {
			getBranchCapturesByDate(branch, year, month).then(data => {
				console.log('LLLLGot branches response', data, 'for branch', branch);
				setBranchCaptures(branchCaptures => {
					branchCaptures[branch] = data;
					return {...branchCaptures};
				});
				setLoading(false);

				setGroupedCaptures(grouped => {
					Object.keys(data.histogram).forEach(date => {
						const dateData = grouped[date] || {};
						dateData[branch] = data.histogram[date];
						grouped[date] = dateData;
					});
					return {...grouped};
				});
			})
		});
	}, [branches, year, month]);

*/
	let maxMonth = 12;

	if(todaysYear === year) {
		maxMonth = todaysMonth;
	}

	const yearsArray = [...new Array(1 + todaysYear - minYear)];
	const monthsArray = [...new Array(maxMonth)];

/*
	const labels = new Set();
	const datasets = branches.map((b, index) => {
		let captures = branchCaptures[b] || {} ;

		let { total = 0, histogram = {} } = captures;

		let data = Object.keys(histogram).map(captureDate => {


			labels.add(captureDate);
			return histogram[captureDate]
		});

		return {
			label: b,
			backgroundColor: getColor(index, branches.length),
			data,
		};
	})

	console.log('OMGISISTSGOOD', labels, 'is the label and datasets are', datasets);
*/
	const [ datasets, setDatasets ] = useState([]);

const data = {
	labels: Object.values(monthMap).filter((_, index) => (year !== todaysYear) || (year === todaysYear && index < todaysMonth)),//Array.from(labels),
	datasets,
}

const adata = {
  labels: ['Maio', 'Junho', 'Agosto', 'Setembro', 'Outubro'],
  datasets: [
    {
      label: 'Mirante',
      backgroundColor: palette.primary.main,
      data: [18, 5, 19, 27, 29, 19, 20]
    },
    {
      label: 'Vila Velha',
      backgroundColor: palette.neutral,
      data: [11, 20, 12, 29, 30, 25, 13]
    }
  ]
};

const actualData = {
	labels: [ data.labels[month-1] ],
	datasets: data.datasets.map(ds => ({ ...ds, data: [ ds.data[month-1] ] })),
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: '#FFF',//palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    ]
  }
};



  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
			elevation={4}
    >
      <CardHeader
        action={
				<>
				<Select
					style={{marginTop: '8px', marginLeft: '8px', marginRight: '8px'}}
					disabled={monthsArray.length <= 1}
          value={month}
          onChange={e => setMonth(e.target.value)}
          inputProps={{
            name: 'month',
            id: 'month-filter',
          }}
        >
					{monthsArray.map((_,index) => (<MenuItem key={index} value={1 + index}>{monthMap[index + 1]}</MenuItem>) )}
        </Select>

				<Select
					style={{marginTop: '8px', marginLeft: '8px', marginRight: '8px'}}
					disabled={yearsArray.length <= 1}
          value={year}
          onChange={e => setYear(e.target.value)}
          inputProps={{
            name: 'year',
            id: 'year-filter',
          }}
        >
					{yearsArray.map((_,index) => (<MenuItem key={index} value={minYear + index}>{minYear + index}</MenuItem>) )}
        </Select>

				</>

        }
        title="Capturas realizadas"
      />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.chartContainer}>

	
				{loading && <Progress />}

          <Bar
            data={actualData}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string
};

export default LatestSales;
