import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute(props) {
	const {
		authenticated,
		defaultUnsecuredPath,
		defaultExternalUnsecuredPath,
		...otherProps
	} = props;

	if(!authenticated) {
		if(!!defaultExternalUnsecuredPath) {
			window.location = defaultExternalUnsecuredPath;
			return null;
		} else {
			return <Redirect to={defaultUnsecuredPath} />
		}
	} else {
		return <Route {...otherProps} />
	}

}

export default PrivateRoute;
