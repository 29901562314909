import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useUser } from 'UserProvider';


import UploadStepper from './UploadStepper';

import MainPage from './Master';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  hiddenInput: {
    display: 'none',
  },
	dialog: {
	},
	dialogContent: {
		display: 'flex',
		minWidth: '600px',
		justifyContent: 'center',
		padding: theme.spacing(2),
	}
}));

export default function RoutesPage(props) {

	const user = useUser();

//	const [ loading, setLoading ] = useState(true);
	const [ branches, setBranches ] = useState(user.branches);
	const [ selectedBranch, setSelectedBranch ] = useState(user.branches[0]);
//	const branchId = user.email.split('@')[1].split('\.')[0]

  const [open, setOpen] = React.useState(false);
	const [ canCancel, letCancel ] = useState(true);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
		if(canCancel) {
    	setOpen(false);
		}
  }

	function handleComplete() {
		setOpen(false);
		letCancel(true);
	}

  const classes = useStyles();

  return (
    <div>
			<MainPage
				onClick={handleClickOpen}
				branches={branches}
				selectedBranch={selectedBranch}
				setSelectedBranch={setSelectedBranch}
			/>
      <Dialog
				className={classes.dialog}
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title">
        <div className={classes.dialogContent}>
					<UploadStepper
						branch={selectedBranch}
						user={user}
						letCancel={letCancel}
						onComplete={handleComplete}
					/>
        </div>
      </Dialog>
    </div>
	);

}
