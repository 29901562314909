const DEBUG = 1;
const INFO = 2;
const WARN = 3;
const ERROR = 4;
/*
	disabled: false,
	disabled: true,
*/

const configs = {
	disabled: false,
	logLevel: INFO,
}

export default (tag, disabled = configs.disabled, logLevel = configs.logLevel) => ({
	d: (...msg) => !disabled && logLevel <= DEBUG && console.debug(`[${tag}]`, ...msg),
	i: (...msg) => !disabled && logLevel <= INFO && console.log(`[${tag}]`, ...msg),
	w: (...msg) => !disabled && logLevel <= WARN && console.warn(`[${tag}]`, ...msg),
	e: (...msg) => !disabled && logLevel <= ERROR && console.error(`[${tag}]`, ...msg),
})
