import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import Logger from './Logger';
const log = Logger('Database');

let db;
let userDb;

let initialize = (configs) => {
	return new Promise((resolve, reject) => {
		let defaultApp = firebase.apps.find(a => a.name === '[DEFAULT]');
		if(defaultApp) {
			log.i('Removing previous app');
			return firebase.app().delete().then(() => {
				log.i('Deleted current app. Initializing with new configuration.');
				db = firebase.initializeApp(configs);
				resolve(db);
			});
		} else {
			log.i('Initializing with new configuration.');
			db = firebase.initializeApp(configs);
			userDb = firebase.initializeApp(configs, '[users]');
			resolve(db);
		}
	});
}

export {
	db,
	userDb,
	firebase,
	initialize,
}
