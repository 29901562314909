import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Progress from 'components/ProgressSpinner';
import ThumbPreview from './ThumbPreview';

import moment from 'moment';

const useStyles = makeStyles({
	tableRoot: {
	},
  table: {
		height: '400px',	
		//height: '429px',	
    width: '100%',
    overflow: 'auto',
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const prerows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const rows = [
	...prerows,	...prerows,
	...prerows,	...prerows,
];

export default function SimpleTable(props) {
  const classes = useStyles();

	const {
		loading,
		captures = [],
	} = props;

	console.log('NOW WITH CAPTURES', captures);

  return (
      <Table className={classes.table} aria-label="simple table">
				{loading ?
				<Progress />
				:
        <>
				<TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell align="right">Leitura Automatica</TableCell>
            <TableCell align="right">Leitura GSS</TableCell>
            <TableCell align="right">Diferença</TableCell>
            <TableCell align="right">Distância</TableCell>
            <TableCell align="right">Grupo/Rota</TableCell>
            <TableCell align="left">Preview</TableCell>
            <TableCell align="right">Ocorrência</TableCell>
            <TableCell align="right">Enquadramento</TableCell>
            <TableCell align="right">Fatura emitida</TableCell>
            <TableCell align="right">Tipo de entrega</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...captures].map(row => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
								{moment(+row.capturetimestamp).format('HH:mm DD/MM/YYYY')}
              </TableCell>
              <TableCell align="right">
								{row.value}
							</TableCell>
              <TableCell align="right">{row.gssvalue}</TableCell>
              <TableCell align="right">{`${(row.gssvalue - row.value)}m³ (${(100*(row.gssvalue - row.value)/(row.value)).toFixed(2)}%)`}</TableCell>
              <TableCell align="right">{row.distance.toFixed(2)}km</TableCell>
              <TableCell align="right">{`${row.groupid}/${row.routeid}`}</TableCell>
              <TableCell align="right">
                <ThumbPreview
									imageId={row.id}
									imageUrl={row.thumbnailurl}
								/>
							</TableCell>
              <TableCell align="right">{row.gssocorrencia || '-'}</TableCell>
              <TableCell align="right">{row.gssenquadramento || '-'}</TableCell>
              <TableCell align="right">{row.gssfaturaemitida || '-'}</TableCell>
              <TableCell align="right">{row.gsstipoentrega || '-'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
				</>}
      </Table>
  );
}
