import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

import CircularProgress from '@material-ui/core/CircularProgress';

import { lighten } from '@material-ui/core/styles/colorManipulator';

import Logger from '../../../modules/Logger';
const log = Logger('CardsPanel');

//class EnhancedTableHead extends React.Component {
//	createSortHandler = property => event => {
//		this.props.onRequestSort(event, property);
//	};
//
//	render() {
//		const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;
//
//		const {
//			rows,
//			showSelection = false, 
//		} = this.props;
//
//		return (
//			<TableHead>
//				<TableRow>
//					{rows.map(
//						(row, index) => (
//							<TableCell
//								key={row.id}
//								align={row.numeric ? 'right' : 'left'}
//								padding={showSelection && index === 0 ? 'none' : 'default'}
//								sortDirection={orderBy === row.id ? order : false}
//							>
//
//								{!row.sortable 
//								?
//									row.label
//									
//							:
//								(<Tooltip
//									title="Sort"
//									placement={row.numeric ? 'bottom-end' : 'bottom-start'}
//									enterDelay={300}
//								>
//									<TableSortLabel
//										active={orderBy === row.id}
//										direction={order}
//										onClick={this.props.onSort(row.id, order === 'desc' ? 'asc' : 'desc')}
//									>
//										{row.label}
//									</TableSortLabel>
//								</Tooltip>)
//								}
//							</TableCell>
//						),
//						this,
//					)}
//				</TableRow>
//			</TableHead>
//		);
//	}
//}
//
//EnhancedTableHead.propTypes = {
//	numSelected: PropTypes.number.isRequired,
//	onRequestSort: PropTypes.func.isRequired,
//	onSelectAllClick: PropTypes.func.isRequired,
//	order: PropTypes.string.isRequired,
//	orderBy: PropTypes.string.isRequired,
//	rowCount: PropTypes.number.isRequired,
//};

const toolbarStyles = theme => ({
	root: {
		paddingRight: theme.spacing.unit,
	},
	highlight:
		theme.palette.type === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
				}
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
				},
	spacer: {
		flex: '1 1 100%',
	},
	actions: {
		color: theme.palette.text.secondary,
		margin: '16px',
	},
	title: {
		flex: '0 0 auto',
	},
});

let EnhancedTableToolbar = props => {
	const {
		classes,
		title,
		actions: Actions,
		titleExtra : TitleExtra,
	} = props;
	

	return (
		<Toolbar
			className={classes.root}
		>
			<div className={classes.title}>
				<Typography variant="h6" id="tableTitle">
					{props.title || 'Title props'}
				</Typography>
			</div>

				{TitleExtra && <div style={{marginBottom: '8px', marginLeft: '8px'}}>
					{TitleExtra}
				</div>}


			<div className={classes.spacer} />
			<div className={classes.actions}>
				{Actions && <Actions />}
			</div>
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
	root: {
	},
	cardsHolder: {
		padding: '8px',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	table: {
	},
	tableWrapper: {
	},
});

class EnhancedTable extends React.Component {

	render() {
		const { classes, children, title, titleExtra } = this.props;
		const { loading, data = [], order, orderBy, selected = [], rowsPerPage, page } = this.props;

//		return (
//			<Paper className={classes.root} elevation={10}>
//				<EnhancedTableToolbar numSelected={selected.length} title={title} actions={this.props.actions} />
//				<div className={classes.tableWrapper}>
//					<Table className={classes.table} aria-labelledby="tableTitle">
//						<TableBody className={classes.cardsHolder}>
//							{children}	
//						</TableBody>
//					</Table>
//				</div>
//				<div style={{height: '64px'}} />
//			</Paper>
//		);

		return (
			<Paper className={classes.root} elevation={10}>
				<EnhancedTableToolbar numSelected={selected.length} title={title} titleExtra={titleExtra} actions={this.props.actions} />
				<div className={classes.tableWrapper}>
					<Table className={classes.table} aria-labelledby="tableTitle">
						<TableBody className={classes.cardsHolder}>
							{children}	
						</TableBody>
					</Table>
				</div>
				{this.props.disabled ? 
					<div style={{height:'24px'}} />
				:
				<TablePagination
					component="div"
					onChangePage={this.props.onChangePage}
					onChangeRowsPerPage={this.props.onChangeRowsPerPage}
					rowsPerPageOptions={this.props.rowsPerPageOptions}
					count={this.props.rowsCount}
					rowsPerPage={this.props.rowsPerPage}
					page={this.props.pageNumber}
					labelRowsPerPage="Capturas por página"
					labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
					backIconButtonProps={{
						'aria-label': 'Página anterior',
					}}
					nextIconButtonProps={{
						'aria-label': 'Próxima página',
					}}
				/>
				}
			</Paper>
		);
	}
}


//const myRows = [
//	{ id: 'name', sortable: true, numeric: false, label: 'Dessert (100g serving)' },
//	{ id: 'calories', sortable: true, numeric: true, label: 'Calories' },
//	{ id: 'fat', numeric: true, label: 'Fat (g)' },
//	{ id: 'carbs', numeric: true, label: 'Carbs (g)' },
//	{ id: 'protein', numeric: true, label: 'Protein (g)' },
//];
//
//let myData = [
//	createData('Cupcake', 305, 3.7, 67, 4.3),
//	createData('Donut', 452, 25.0, 51, 4.9),
//	createData('Eclair', 262, 16.0, 24, 6.0),
//	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//	createData('Gingerbread', 356, 16.0, 49, 3.9),
//	createData('Honeycomb', 408, 3.2, 87, 6.5),
//	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//	createData('Jelly Bean', 375, 0.0, 94, 0.0),
//	createData('KitKat', 518, 26.0, 65, 7.0),
//	createData('Lollipop', 392, 0.2, 98, 0.0),
//	createData('Marshmallow', 318, 0, 81, 2.0),
//	createData('Nougat', 360, 19.0, 9, 37.0),
//	createData('Oreo', 437, 18.0, 63, 4.0),
//]
//
//function fetchData(idToOrderBy, order, pageNumber, rowsPerPage) {
//	let tableData = [...myData];
//	if(idToOrderBy) {
//		tableData = stableSort(tableData, getSorting(order, idToOrderBy));
//	}
//
//	tableData = tableData.slice(pageNumber * rowsPerPage, pageNumber * rowsPerPage + rowsPerPage)
//
//	return new Promise(resolve => {
//		setTimeout(() => {
//			resolve(tableData);
//		}, 500);
//	});
//
//}
//
//function MTable(props) {
//
//
//	let [ pageNumber , setPageNumber ] = useState(0);
//	let [ rowsPerPage, setRowsPerPage ] = useState(10);
//
//	let [ order, setOrder ] = useState('asc');
//	let [ idToOrderBy, setIdToOrderBy ] = useState('');
//
//	let [ loading, setLoading ] = useState(true);
//
//	let [ tableData, setTableData ] = useState([]);
//
//	let handleChangePage = (event, page) => {
//		setPageNumber(page);
//	};
//
//	let handleChangeRowsPerPage = event => {
//		setRowsPerPage(event.target.value);
//	};
//	
//	React.useEffect(() => {
//		setLoading(true);
//		fetchData(idToOrderBy, order, pageNumber, rowsPerPage).then(data => {
//			setTableData(data);
//			setLoading(false);
//		});
//	}, [idToOrderBy, order, pageNumber, rowsPerPage]);
//
//	return (
//		<EnhancedTable {...props} 
//			onRowClick={(id) => log.i('clicked on row', id)}
//			canSelect={false}
//			title="My Table"
//			rows={myRows}
//			data={tableData}
//			orderBy={idToOrderBy}
//			order={order}
//			onSort={(id, order) => e => {
//				setOrder(order);
//				setIdToOrderBy(id);
//			}}
//			onChangePage={handleChangePage}
//			onChangeRowsPerPage={handleChangeRowsPerPage}
//			rowsPerPageOptions={[5,10,25,50]}
//			rowsCount={myData.length}
//			rowsPerPage={rowsPerPage}
//			pageNumber={pageNumber}
//			loading={loading}
//		/>
//	);
//}
//
//function MTableHeader(props) {
//}
//
//function MTableHeaderOption(props) {
//}
//
//function MTableEntry(props) {
//}



EnhancedTable.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);
