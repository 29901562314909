import React from 'react';

import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import GridMaterial from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ImagePanel from './ImagePanel';

import { withStyles } from '@material-ui/core/styles'

//import { getDataById, fetchLogData } from './dataservice';

import Logger from '../../../modules/Logger';
let log = Logger('CaptureDetails');

const styles = theme => ({
	pageroot: {
		margin: '16px',
	},
	tabItemRoot: {
	},
});

function Details(props) {
	
	const {
		classes,
		match,
		get,
	} = props;
	log.i('HISTORY', props);

	const itemId = match.params.id;

	let [ data, setData ] = React.useState();
	let [ loading, setLoading ] = React.useState(true);

	React.useEffect(() => {
		get(itemId, (data) => {
			console.log('Got capture', itemId, 'details:', data);
			setData(data);
		})
	//		.then(setData)
	//		.catch(e => {});
	}, [itemId]);

	return <ImagePanel
		title={`Captura ${itemId.substr(0,6)}`}
		data={data}
		goBack={() => props.history.goBack()}
	/>

}
export default withStyles(styles)(Details);
