import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PublicRoute(props) {
	const {
		authenticated,
		defaultSecuredPath,
		defaultExternalSecuredPath,
		...otherProps
	} = props;

	console.log('authenticated on public?', authenticated);

	if(authenticated) {
		if(!!defaultExternalSecuredPath) {
			window.location = defaultExternalSecuredPath;
			return null;
		} else {
			return <Redirect to={defaultSecuredPath} />
		}
	} else {
		return <Route {...otherProps} />
	}

}

export default PublicRoute;
