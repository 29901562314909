import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import CircularProgress from '@material-ui/core/CircularProgress';

import Collapse from '@material-ui/core/Collapse';

import { lighten } from '@material-ui/core/styles/colorManipulator';

import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ptLocale from 'date-fns/locale/pt';

import Logger from 'modules/Logger';
const log = Logger('CardsPanel');

const toolbarStyles = theme => ({
	root: {
		paddingRight: theme.spacing.unit,
	},
	highlight:
		theme.palette.type === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
				}
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
				},
	spacer: {
		flex: '1 1 100%',
	},
	actions: {
		color: theme.palette.text.secondary,
		margin: '16px',
	},
	title: {
		flex: '0 0 auto',
	},
  container: {
    display: 'flex',
    flexWrap: 'wrap',
		justifyContent: 'center',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
		minWidth: '40%',

  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

	const MaDateTimePicker = props => ( 
      <DateTimePicker
				className={props.classes.textField}
				label={props.label}
				disableFuture
				ampm={false}
				value={props.value}
				onChange={props.onChange}
				autoOk
        format="dd/MM/yyyy HH:mm"
				cancelLabel="Cancelar"
				okLabel="Confirmar"
				clearLabel="Limpar"
				todayLabel="Hoje"
				clearable
			/>)

let EnhancedTableToolbar = props => {
	const {
		onMinDateChange,
		onMaxDateChange,
		onFilterSubmit,
		formValues,
		classes,
		title,
		actions: Actions,
	} = props;
	
	const filters = [
		{ value: 'meterId', label: 'Nº Hidrômetro' },
		{ value: 'installationId', label: 'Número da ligação' },
	];

	const handleSubmit = e => {
		e.preventDefault();
		log.i('Got a form submit');
		onFilterSubmit();
	}

	return (
	<>
		<Toolbar
			className={classes.root}
		>
			<div className={classes.title}>
				<Typography variant="h6" id="tableTitle">
					{props.title || 'Title props'}
				</Typography>
			</div>

			<div className={classes.spacer} />

			<div className={classes.actions}>
				{Actions && <Actions />}
			</div>
		</Toolbar>
			
	</>

	);
};

EnhancedTableToolbar.propTypes = {
	classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
	root: {
	},
	cardsHolder: {
		padding: '8px',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	table: {
	},
	tableWrapper: {
		overflowX: 'auto',
	},
});

class EnhancedTable extends React.Component {

	render() {
		const { classes, children, title } = this.props;
		const { loading, data = [], order, orderBy, selected = [], rowsPerPage, page } = this.props;

		return (
			<Paper className={classes.root} elevation={10}>
				<EnhancedTableToolbar numSelected={selected.length} {...this.props}/>
							{children}	
				
			</Paper>
		);
	}
}


EnhancedTable.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);
