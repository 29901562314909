import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Pagination from './Pagination';
import { getSkipReasonText, getCaptureStatusMessage } from './Parser';
import { Link } from 'react-router-dom';

import { parse } from './Parser';

let log = { i: () => {} };

const useStyles = makeStyles({
  card: {
    width: 300,
	},
	loading: {
		position: 'relative',
		height: '304px',
  },
	crop: {
		height: '150px',
		width: '100%',
		overflow: 'hidden',
	},
	fill: {
		width: '400px',
		height: 'auto',
		marginTop: '-100px',
		marginLeft: '-50px',
	},
	show: {
		display: 'show',
	},
	hide: {
		display: 'none',
	},
});

//export default function ImgMediaCard(props) {
export default React.memo((props) => {
  const classes = useStyles();

	let [ loaded, setLoaded ] = React.useState(false);

	let {
		loading,
		captureData,
		onDetailsClick,
		} = props;
	
	if(loading) {

	  return (
	    <Card elevation={10} className={`${classes.card} ${classes.loading}`}>
					{<div style={{position:'relative', top: '80px', height:'150px', width:'100%', backgroundColor:'#dddddd'}}>
						<CircularProgress color="primary" style={{position:'absolute', top:'75px', left:'50%', marginTop:'-20px', marginLeft:'-20px'}} size={40}/>
					</div>}
	    </Card>
	  );
	}

	let timestampToDate = (ts) => {
		var date = new Date(ts);
		// Hours part from the timestamp
		var hours = "0" + date.getHours();
		// Minutes part from the timestamp
		var minutes = "0" + date.getMinutes();
		// Seconds part from the timestamp
		var seconds = "0" + date.getSeconds();
		
		var year = date.getFullYear();
		var month = "0" + (date.getMonth() + 1);
		var day = "0" + date.getDate();

		// Will display time in 10:30:23 format
		var formattedTime = day.substr(-2) + '/' + month.substr(-2) + '/' + year + " " + hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
		return formattedTime;
	}

	let { id, status: extractionStatus, thumbnailUrl, timestamp, uploadTimestamp, routeItem, value } = captureData;
	let { branchId : routeName, routeId, groupId, sequenceId, installationId, meterId } = captureData;

	let dateString = timestampToDate(timestamp); //'subheader';
	let meterInfo = `Hidrômetro ${meterId ? meterId : 'não informado'}`; //'text one';
	let groupRouteSequence = `Grupo ${groupId}, Rota ${routeId}, Seq. ${sequenceId}`; //'text two';
	let routeInfo = `${routeName} - Grupo ${groupId}` 
	let installationInfo = `Matrícula ${installationId ? installationId : 'não informada'}`; //'Title';

	let automatedValueInfo = uploadTimestamp ? 'Enfileirado para processamento' : 'Aguardando upload';

	let statusMessage = parse(extractionStatus, value);
//	let automatedValueInfo = uploadTimestamp ? 'Enfileirado para processamento' : 'Aguardando upload';



	let data = captureData;
	let parsedStatus = '';
	let details = '';
	if(!!data) {
		let { status, value } = data;
		if(status.startsWith('skipped')) {
			parsedStatus = 'Endereço ignorado';
			let skipCode = status.split('/')[1]
			details = getSkipReasonText(skipCode);
		} else if(status.startsWith('success')) {
			parsedStatus = 'Processamento concluído';
			details = 'Leitura: ' + value;
		} else if(status.startsWith('failed')) {
			parsedStatus = 'Processamento concluído';
			details = getCaptureStatusMessage(status);
		} else {
			parsedStatus = 'Pendente';
			details = getCaptureStatusMessage(status);
		}
	}	





	//let imageTitle = routeInfo;
	let imageTitle = dateString;
	let subheader = groupRouteSequence;
	let textOne = parsedStatus; //statusMessage; //reasonToSkip ? `Ignorado: ${reasonToSkip}` : automatedValueInfo;
	let textTwo = details; //groupRouteSequence;

	let imageUrl = thumbnailUrl;//'http://rdironworks.com/wp-content/uploads/2017/12/dummy-200x200.png';

	log.i('CAPTURE DATA', captureData);

	let handleLoaded = () => {
		log.i('Loaded image');
			setLoaded(true);
	}

  return (
    <Card className={classes.card} elevation={10}>
      <CardHeader
				style={{paddingBottom: '8px'}}
        title={imageTitle}
        subheader={subheader}
      />

      <CardContent style={{paddingTop: 0}}>
        <Typography variant="body2" color="textSecondary" component="p">
          {meterInfo}
				</Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {installationInfo}
				</Typography>
      </CardContent>


      <CardActionArea>
					{imageUrl ? (
			<Link	to={`/captures/${id}`}>
				<div
					className={`${classes.crop} ${loaded ? classes.show : classes.hide}`}
				>
      	  <img
						className={classes.fill}
      	    component="img"
						draggable="false"
      	    alt="Capture thumbnail"
						height="150"
      	    src={imageUrl}
						onLoad={handleLoaded}
      	  />
				</div>
				{!loaded && <div style={{height:'150px', width:'100%'}}>
					<CircularProgress color="primary" style={{position:'absolute', top:'75px', left:'50%', marginTop:'-20px', marginLeft:'-20px'}} size={40}/>
				</div>}
			</Link>
					) : (
					<div style={{height:'150px', width:'100%', backgroundColor:'#dddddd', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<h2>Aguardando upload</h2>
					</div>
					)}
      </CardActionArea>

      <CardContent style={{padding: '16px'}}>
        <Typography variant="body2" color="textSecondary" component="p">
          {textOne}
				</Typography>
        <Typography variant="caption" color="textSecondary" component="p">
          {textTwo}
				</Typography>
      </CardContent>
    </Card>
  );
});
