import React from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import UserIcon from '@material-ui/icons/Person';
import LogoutIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

let log = { i: () => {} };


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
	},
	popper: {
    zIndex: theme.zIndex.modal + 1,
  },
  paper: {
		backgroundColor: theme.palette.primary.light,
  },
	inlineIcon: {
		marginRight: '8px',
	},
	userInfo: {
		paddingLeft: '16px',
		paddingRight: '16px',
	},
	menuContainer: {
		marginTop: '16px',
	},
	dividerContainer: {
		minWidth: '200px',
		display: 'flex',
		justifyContent: 'center',
	},
	divider: {
		width: '80%',
	},
  details: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

function MenuListComposition(props) {
  const classes = useStyles();

	const {
		children,
	} = props;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

	let handleLogout = (e) => {
		log.i('Logging out');
		handleClose(e);
	}

  return (
    <div className={classes.root}>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          ref={anchorRef}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
        	<UserIcon />
        </IconButton>
    <Popper className={classes.popper} open={open} anchorEl={anchorRef.current} placement="bottom-end" transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper elevation={10} className={classes.menuContainer}>
            <ClickAwayListener onClickAway={handleClose}>
							<div>
								{children}
							</div>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
    </div>
  );
}

export default MenuListComposition;
