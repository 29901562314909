import React from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import UserIcon from '@material-ui/icons/Person';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { makeStyles } from '@material-ui/core/styles';

import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import { useUser } from 'UserProvider';
import { logout } from 'modules/Auth';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
		backgroundColor: theme.palette.primary.light,
  },
	inlineIcon: {
		marginRight: '8px',
	},
	userInfo: {
		paddingLeft: '16px',
		paddingRight: '16px',
	},
	menuContainer: {
	},
	dividerContainer: {
		minWidth: '200px',
		display: 'flex',
		justifyContent: 'center',
	},
	divider: {
		width: '80%',
	},
  details: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  inlineCenter: {
    display: 'inline',
  },
}));


	const appName = process.env.REACT_APP_NAME || 'cloud';
	const appVersion = process.env.REACT_APP_VERSION || '0.0.0';


function MenuContent(props) {
	const classes = useStyles();
	const {
		userData,
		logout,
	} = props;

	const user = useUser();

	let [ imageLoaded, setImageLoaded ] = React.useState(false);
	let [ loggingOut, setLoggingOut ] = React.useState(false);

	let handleLogout = () => {
		setLoggingOut(true);
		//setTimeout(() => setUser(), 500);
		setTimeout(() => logout(), 500);
	}

	return (
		<>
			<List className={classes.details}>
			  <ListItem alignItems="flex-start">
			    <ListItemAvatar>
			      <Avatar alt="profile" src={user.photoURL} imgProps={{onLoad: () => setImageLoaded(true)}}>
						 {!imageLoaded && <CircularProgress color="primary"/>}
						</Avatar>
			    </ListItemAvatar>
			    <ListItemText
			      primary={
			          <Typography
			            component="span"
			            variant="h6"
			            className={classes.inline}
			            color="textPrimary"
			          >
			            {user.username}
			          </Typography>
						}
			      secondary={
			        <React.Fragment>
			          <Typography
			            component="span"
			            variant="body2"
			            className={classes.inline}
			            color="textPrimary"
			          >
			            {user.email}
			          </Typography>
			        </React.Fragment>
			      }
			    />
			  </ListItem>
			</List>
   		<div className={classes.dividerContainer}>
				<Divider className={classes.divider} />
			</div>
      <MenuList disablePadding>
        <MenuItem onClick={handleLogout}>
					{loggingOut ?
						<CircularProgress size={24} color="primary" className={classes.inlineIcon}/>
					:
						<LogoutIcon className={classes.inlineIcon}/>
					}
					Sair
					</MenuItem>
      </MenuList>


   		<div className={classes.dividerContainer}>
				<Divider className={classes.divider} />
			</div>

   		<div className={classes.dividerContainer}>
			          <Typography
			            component="span"
									variant="caption"
			            className={classes.inlineCenter}
			            color="textSecondary"
			          >
			            {`${appName} v${appVersion}`}
			          </Typography>
			</div>

		</>
	);
}

export default MenuContent;
