import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Button, Card, CardContent, CardActions, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/Apartment';

import { Link } from 'react-router-dom';

import Progress from 'components/ProgressSpinner';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
		position: 'relative',
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(0.5)
  }
}));

const TotalUsers = props => {
  const { className, ...rest } = props;

	const {
		loading,
		total = '-',
		latest,
	} = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
			elevation={4}
    >
			{loading && <Progress />}
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              UNIDADES ATIVAS
            </Typography>
            <Typography variant="h3">
							{total}
						</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PeopleIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>

						{!!latest
						 ?
						(<>
      		    <Typography
      		      className={classes.differenceValue}
      		      variant="body2"
      		    >
      		      {latest}
      		    </Typography>
      		    <Typography
      		      className={classes.caption}
      		      variant="caption"
      		    >
      		      é a mais recente.
      		    </Typography>
						</>)
							:	
						(<>
      		    <Typography
      		      className={classes.differenceValue}
      		      variant="body2"
      		    >
      		      Sem informações
      		    </Typography>
      		    <Typography
      		      className={classes.caption}
      		      variant="caption"
      		    >
      		      da mais recente.
      		    </Typography>
						</>)
						}

        </div>
      </CardContent>
			<Divider />
			<CardActions style={{ justifyContent: 'flex-end', padding: 0}}>
    	  <Button component={Link} to="/dash/branches" style={{padding: '8px'}}>
  	     	Detalhes
					<ArrowRightIcon />
	      </Button>
			</CardActions>
    </Card>
  );
};

TotalUsers.propTypes = {
  className: PropTypes.string
};

export default TotalUsers;
