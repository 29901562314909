import React from 'react';
import ReactDOM from 'react-dom';
//import App from './LoginApp';
import App from './App';
//import App from './pages/Main/Routes';
//import App from './pages/Main/Routes/List';
import * as serviceWorker from './serviceWorker';

var DEBUG = true;
DEBUG = false;

if(!DEBUG){
    if(!window.console) window.console = {};
    var methods = ["log", "debug", "warn", "info"];
    for(var i=0;i<methods.length;i++){
        console[methods[i]] = function(){};
    }
}

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
