import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import NoPicturesIcon from '@material-ui/icons/LocationOff';

import { makeStyles } from '@material-ui/core/styles';

import Progress from 'components/ProgressSpinner';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';

import { Select, MenuItem } from '@material-ui/core';

import {
  getExistingBranches,
  getBranchCapturesByDate,
	listDailyRoutes,
	getRequestCaptures,
} from 'modules/Analytics';

import moment from 'moment';

const monthMap = {
	1: 'Janeiro',
	2: 'Fevereiro',
	3: 'Março',
	4: 'Abril',
	5: 'Maio',
	6: 'Junho',
	7: 'Julho',
	8: 'Agosto',
	9: 'Setembro',
	10: 'Outubro',
	11: 'Novembro',
	12: 'Dezembro',
}

const colorMap = {
	'mirante': '#222222',
	'vila-velha': '#CC0000',
	'serra': '#CCFF33',
	'test': '#00FFFF',
}


const styles = makeStyles(theme => ({
  root: {},
	contentRoot: {
		padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
	},
	routeContainer: {
		position: 'relative',
		height: '400px',
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column-reverse'
		}
	},
	noPictureIcon: {
		width: 100,
		height: 100,
		marginBottom: '8px',
		color: theme.palette.text.secondary,
	},
	noPictureText: {
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));


const today = new Date();
const todaysYear = today.getFullYear();
const todaysMonth = today.getMonth() + 1;
const todaysDay = today.getDate();

const minYear = 2019;

	const mockMarkers = [
  	{ lat: -22.89, lng: -47.05 },
  	{ lat: -22.90, lng: -47.05 },
  	{ lat: -22.90, lng: -47.06 },
  	{ lat: -22.89, lng: -47.06 },
	]


export default function RoutesMap(props) {

	const classes = styles();


  const {
		className,
		branch,
		icon: Icon = NoPicturesIcon,
		text = '',
		...rest
	} = props;

	const [ branchCaptures, setBranchCaptures ] = useState({});

	const [ year, setYear ] = useState(todaysYear);
	const [ month, setMonth ] = useState(todaysMonth);
	const [ day, setDay ] = useState(todaysDay);

	const [ groupedCaptures, setGroupedCaptures ] = useState({});

	const [ loading, setLoading ] = useState(true);
	const [ loadingMarkers, setLoadingMarkers ] = useState(false);


	const [ selectedRoute, setSelectedRoute ] = useState();
	const [ markers, setMarkers ] = useState();

	const [ routes, setRoutes ] = useState([]);

	React.useEffect(() => {

			if(!selectedRoute) {
				console.log('No route yet. skipping loading markers');
				return;
			}

			setMarkers([]);
			setLoadingMarkers(true);

			getRequestCaptures(selectedRoute.requestid).then(data => {
				console.log('Got request captures response', data, 'for branch', branch);
				setMarkers(data.map( ({ latitude : lat, longitude : lng }) => ({ lat, lng })));
				setLoadingMarkers(false);
			});
			/*
			getBranchCapturesByDate(branch, year, month).then(data => {
				setBranchCaptures(data);
				setMarkers(mockMarkers);
			})
			*/
	}, [selectedRoute]);


	React.useEffect(() => {

			if(!branch) {
				console.log('No branch selected yet. Waiting');
				return;
			}

			setMarkers();
			setSelectedRoute();
			setLoading(true);
			listDailyRoutes({branchId: branch, year, month, day}).then(data => {
				console.log('Got routes response', data, 'for branch', branch);
				setRoutes(data);
//				setBranchCaptures(data);
				setLoading(false);
			})
	}, [branch, year, month, day]);



	let maxMonth = 12;
	let maxDay = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()

	if(year === todaysYear) {
		maxMonth = todaysMonth;
		if(month === todaysMonth) {
			maxDay = todaysDay
		}
	}

	const yearsArray = [...new Array(1 + todaysYear - minYear)];
	const monthsArray = [...new Array(maxMonth)];
	const daysArray = [...new Array(maxDay)];

	return (
				<div
					style={{
						// height hack from https://stackoverflow.com/questions/18665171/how-to-make-the-main-content-div-fill-height-of-screen-with-css
						width: '100%',
						position: 'absolute',
						top: 0, /* Header Height */
						bottom: 0,
					}}
				>
				
						<div style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							position:'relative',
							height: '100%',
							}}>
								<Icon className={classes.noPictureIcon} />
	
			    	    <Typography variant="body1" className={classes.noPictureText} component="p">
			    	      {text}
								</Typography>
							</div>

				</div>
  );
}
